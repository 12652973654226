/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function StarLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6682 2.90944C12.5402 2.65819 12.282 2.5 12 2.5C11.718 2.5 11.4598 2.65819 11.3318 2.90944L8.57333 8.32178L2.39488 9.19642C2.10953 9.23681 1.87256 9.43714 1.78525 9.71178C1.69794 9.98643 1.77572 10.2868 1.98535 10.4846L6.44091 14.6876L5.3905 20.6192C5.34089 20.8993 5.45403 21.1834 5.68262 21.3527C5.91121 21.522 6.21592 21.5474 6.4694 21.4183L12 18.6014L17.5306 21.4183C17.7841 21.5474 18.0888 21.522 18.3174 21.3527C18.546 21.1834 18.6591 20.8993 18.6095 20.6192L17.5591 14.6876L22.0146 10.4846C22.2243 10.2868 22.3021 9.98643 22.2148 9.71178C22.1274 9.43714 21.8905 9.23681 21.6051 9.19642L15.4267 8.32178L12.6682 2.90944Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
