/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {ThemeProvider} from 'styled-components';

import {useGetTheme} from './../hooks';
import {useSetBrowserTheme} from '../hooks/useSetBrowserTheme';

export function CurrentTheme({children}: React.PropsWithChildren<{}>): JSX.Element {
  const themeConfig = useGetTheme();
  useSetBrowserTheme();
  return <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>;
}
