/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Link, To} from 'react-router-dom';
import styled, {css} from 'styled-components';

import LoadingSpinner from 'components/shared/svgs/LoadingSpinner';
import {WithComponentPropsWithoutRef} from 'types';

/** Enum of button size options */
export enum ButtonSize {
  MINI = 'mini',
  STANDARD = 'standard',
  LARGE = 'large',
}
/** Enum of button variation options */
export enum ButtonVariation {
  PRIMARY = 'primary',
  PRIMARY_ERROR = 'primary_error',
  PRIMARY_SUCCESS = 'primary_success',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

/** Base button, which each of the variations build from */
const BaseButton = styled.button<ButtonCustomProps>`
  ${({theme, size}) => css`
    align-items: center;
    appearance: none;
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-weight: 500;
    gap: 6px;
    justify-content: center;
    white-space: nowrap;
    max-width: 350px;
    outline: none;

    border-radius: ${theme.button.borderRadius};

    /* properties which vary with size */
    font-size: ${size === ButtonSize.LARGE ? '18px' : size === ButtonSize.MINI ? '14px' : '16px'};
    height: ${size === ButtonSize.LARGE ? '52px' : size === ButtonSize.MINI ? '32px' : '44px'};
    line-height: ${size === ButtonSize.LARGE ? '27px' : size === ButtonSize.MINI ? '21px' : '24px'};
    padding: ${size === ButtonSize.LARGE
      ? '0 32px'
      : size === ButtonSize.MINI
      ? '0 20px'
      : '0 24px'};

    &:focus-visible,
    &[data-focus-visible='true'] {
      outline: 2px solid ${theme.color.primaryA400};
      outline-offset: 1px;
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      cursor: not-allowed;
    }
  `}
`;

const PrimaryButton = styled(BaseButton)`
  ${({theme}) => css`
    background: ${theme.button.primary.background};
    border: ${theme.button.primary.border};
    color: ${theme.button.primary.color};

    &:focus,
    &:visited {
      color: ${theme.button.primary.color};
    }

    &:hover {
      background: ${theme.button.primary.hover.background};
      border: ${theme.button.primary.hover.border};
      color: ${theme.button.primary.hover.color};
    }

    &:active,
    &:link:active {
      background: ${theme.button.primary.active.background};
      border: ${theme.button.primary.active.border};
      color: ${theme.button.primary.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      background: ${theme.button.primary.disabled.background};
      border: ${theme.button.primary.disabled.border};
      color: ${theme.button.primary.disabled.color};
    }
  `}
`;

const PrimaryErrorButton = styled(BaseButton)`
  ${({theme}) => css`
    background: ${theme.button.primaryError.background};
    border: ${theme.button.primaryError.border};
    color: ${theme.button.primaryError.color};

    &:focus,
    &:visited {
      color: ${theme.button.primaryError.color};
    }

    &:hover {
      background: ${theme.button.primaryError.hover.background};
      border: ${theme.button.primaryError.hover.border};
      color: ${theme.button.primaryError.hover.color};
    }

    &:active,
    &:link:active {
      background: ${theme.button.primaryError.active.background};
      border: ${theme.button.primaryError.active.border};
      color: ${theme.button.primaryError.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      background: ${theme.button.primaryError.disabled.background};
      border: ${theme.button.primaryError.disabled.border};
      color: ${theme.button.primaryError.disabled.color};
    }
  `}
`;

const PrimarySuccessButton = styled(BaseButton)`
  ${({theme}) => css`
    background: ${theme.button.primarySuccess.background};
    border: ${theme.button.primarySuccess.border};
    color: ${theme.button.primarySuccess.color};

    &:focus,
    &:visited {
      color: ${theme.button.primarySuccess.color};
    }

    &:hover {
      background: ${theme.button.primarySuccess.hover.background};
      border: ${theme.button.primarySuccess.hover.border};
      color: ${theme.button.primarySuccess.hover.color};
    }

    &:active,
    &:link:active {
      background: ${theme.button.primarySuccess.active.background};
      border: ${theme.button.primarySuccess.active.border};
      color: ${theme.button.primarySuccess.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      background: ${theme.button.primarySuccess.disabled.background};
      border: ${theme.button.primarySuccess.disabled.border};
      color: ${theme.button.primarySuccess.disabled.color};
    }
  `}
`;

const SecondaryButton = styled(BaseButton)`
  ${({theme}) => css`
    background: ${theme.button.secondary.background};
    border: ${theme.button.secondary.border};
    color: ${theme.button.secondary.color};

    &:focus,
    &:visited {
      color: ${theme.button.secondary.color};
    }

    &:hover {
      background: ${theme.button.secondary.hover.background};
      border: ${theme.button.secondary.hover.border};
      color: ${theme.button.secondary.hover.color};
    }

    &:active,
    &:link:active {
      background: ${theme.button.secondary.active.background};
      border: ${theme.button.secondary.active.border};
      color: ${theme.button.secondary.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      background: ${theme.button.secondary.disabled.background};
      border: ${theme.button.secondary.disabled.border};
      color: ${theme.button.secondary.disabled.color};
    }
  `}
`;

const TertiaryButton = styled(BaseButton)`
  ${({theme}) => css`
    display: inline-flex;

    background: ${theme.button.tertiary.background};
    border: ${theme.button.tertiary.border};
    color: ${theme.button.tertiary.color};

    &:focus,
    &:visited {
      color: ${theme.button.tertiary.color};
    }

    &:hover {
      background: ${theme.button.tertiary.hover.background};
      border: ${theme.button.tertiary.hover.border};
      color: ${theme.button.tertiary.hover.color};
    }

    &:active,
    &:link:active {
      background: ${theme.button.tertiary.active.background};
      border: ${theme.button.tertiary.active.border};
      color: ${theme.button.tertiary.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      background: ${theme.button.tertiary.disabled.background};
      border: ${theme.button.tertiary.disabled.border};
      color: ${theme.button.tertiary.disabled.color};
    }
  `}
`;

/** Map of button variation keys to components */
const VARIATIONS: Record<ButtonVariation, typeof BaseButton> = {
  [ButtonVariation.PRIMARY]: PrimaryButton,
  [ButtonVariation.PRIMARY_ERROR]: PrimaryErrorButton,
  [ButtonVariation.PRIMARY_SUCCESS]: PrimarySuccessButton,
  [ButtonVariation.SECONDARY]: SecondaryButton,
  [ButtonVariation.TERTIARY]: TertiaryButton,
};
/** Custom props passed into the Button component */
interface ButtonCustomProps {
  loading?: boolean;
  disabled?: boolean;
  variation?: ButtonVariation;
  size?: ButtonSize;
  href?: string;
  to?: To;
  target?: string;
  state?: any;
}

/**
 * Button component, with ref
 */
export const Button = React.forwardRef<
  typeof styled.button,
  WithComponentPropsWithoutRef<'a' | typeof Link, ButtonCustomProps>
>((props, ref) => {
  const {children, loading, variation, size, ...restProps} = props;

  const buttonVariation = variation || ButtonVariation.SECONDARY;
  const buttonSize = size || ButtonSize.STANDARD;
  const RenderButton = VARIATIONS[buttonVariation];
  const asAnchor = !!props.href;
  const asRouterLink = !!props.to;

  return (
    <RenderButton
      as={asAnchor ? 'a' : asRouterLink ? Link : undefined}
      size={buttonSize}
      {...{ref}}
      {...restProps}
    >
      {buttonVariation !== ButtonVariation.TERTIARY && loading ? (
        <LoadingSpinner size="small" verticalMargin="0" id="GenericButton" />
      ) : (
        children
      )}
    </RenderButton>
  );
});
