/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import moment from 'moment';
import {get} from 'lodash';

import {useGlobalState} from 'store';

export default function useShouldUpdate() {
  const globalState = useGlobalState();
  return useCallback(
    // tolerableDiff is in seconds, default is 5 minutes
    (update, tolerableDiff = 300) => {
      const lastUpdate = get(globalState.updates, update);
      // Always update if there's no current update record
      if (!lastUpdate) return true;
      if (moment().diff(moment(lastUpdate), 'seconds') > tolerableDiff) return true;
      return false;
    },
    [globalState.updates],
  );
}
