/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

import {drop, merge, omit, find, unionBy, isEqual} from 'lodash';
import {WithChildren} from 'types';
import {GlobalStoreState, GlobalStoreConfig, GlobalStoreDispatch} from './globalStore.types';
import {ActionType, GlobalStoreAction} from './action.types';

// exporting for use in tests
export const initialValue: GlobalStoreState = {
  // is an announcement pop up visible? the app should adjust content height
  billboardAnnouncementDisplay: {isVisible: false},
  // should brand bar adjust height and style?
  brandBarDisplay: {compact: false},
  appVersionOutdated: null,
  connectionConfigs: null,
  verizonConnectionConfigs: null,
  // @todo Rename this key to 'devices' once we migrate fully to connection-devices
  connectionDevices: null,
  connections: null,
  announcements: {},
  deviceConfigs: null,
  deviceMessages: {},
  energyEvents: null,
  eventChecklist: null,
  experiments: null,
  flashMessages: null,
  features: {
    /** last time the feature set was updated */
    updated: null,
    /** set of feature keys which are enabled for the user  */
    featureKeys: [],
  },
  // Object: an energy event which is currently happening.
  // {id: [f_ohm_hour_user_id], start_dttm: [ISO-8601-datetime], end_dttm: [ISO-8601-datetime]}
  historicalEventsCount: null,
  inProgressEvent: null,
  multifactorVerificationOptions: null,
  nextBestAction: {signup: null, dashboard: null},
  offers: {},
  // Object: an energy event which will begin soon (Cf. utils.isPendingEvent)
  // {id: [f_ohm_hour_user_id], start_dttm: [ISO-8601-datetime], end_dttm: [ISO-8601-datetime]}
  orders: {},
  pendingEvent: null,
  // Boolean: whether or not a realtime event is currently happening,
  // i.e. whether or not to show the banner
  realtimeEventInProgress: null,
  redirect: null,
  referrals: null,
  rewards: null,
  rhrList: null,
  signup: {
    step: null,
    fbConversionData: null,
    utilityOptions: [
      {
        value: '999',
        displayLabel: "(I don't see my utility)",
        displayName: '',
        acronym: 'utility',
        primary_utility_acronym: '',
        primary_utility_short_name: '',
      },
    ],
    fullUtilityList: [
      {
        order: 0,
        displayName: '',
        value: '999',
        displayLabel: "(I don't see my utility)",
        acronym: 'utility',
      },
    ],
    utilityOutage: null,
  },
  showExitIntent: false,
  currentTheme: null,
  upcomingEvents: null,
  // {request: Date, ...} where `request` is a string to keep track of when that data was last
  // updated. This is used to determine when we last fetched specific pieces of data. This separate
  // top-level key is preferable to storing this data inline with each key because it won't pollute
  // those values.
  updates: {},
  user: {},
  userSettings: null,
  wallet: null,
  cashout: {
    eGifts: {
      availableEGifts: [],
      updated: null,
    },
  },
  loadingFlags: [],
  showAccountBackLink: null,
  friendBuy: {},
  fetchInstances: [],
  isFromWalletPage: false,
};

// Context
const State = React.createContext<GlobalStoreState>(initialValue);
State.displayName = 'GlobalState';
const Dispatch = React.createContext<GlobalStoreDispatch<ActionType>>(action => {});
Dispatch.displayName = 'GlobalDispatch';

// Reducer
export function reducer<T extends ActionType>(
  state: GlobalStoreState,
  action: GlobalStoreAction<T>,
): GlobalStoreState {
  switch (action.type) {
    case ActionType.SET_BILLBOARD_ANNOUNCEMENT_DISPLAY:
      return {...state, billboardAnnouncementDisplay: action.payload};
    case ActionType.SET_BRAND_BAR_DISPLAY:
      return {...state, brandBarDisplay: action.payload};
    // # User:
    case ActionType.SET_USER:
      return {...state, user: action.payload};
    case ActionType.SET_USER_REFERRAL_SHARE:
      return {
        ...state,
        user: {...state.user, ...action.payload},
        updates: {...state.updates, referralShare: new Date()},
      };
    case ActionType.SET_USER_REFERRAL_OFFERS:
      return {
        ...state,
        user: {...state.user, referralOffers: action.payload},
        updates: {...state.updates, referralOffers: new Date()},
      };
    case ActionType.SET_USER_ATTRIBUTES:
      const newUser2 = merge({}, state.user || {}, {attributes: action.payload});
      return {...state, user: newUser2};
    case ActionType.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        user: {...state.user, shippingAddress: action.payload.shippingAddress},
      };
    case ActionType.SET_ANNOUNCEMENT:
      return {
        ...state,
        announcements: {...(state.announcements || {}), ...action.payload},
      };
    case ActionType.SET_EXPERIMENTS:
      return {
        ...state,
        experiments: {...(state.experiments || {}), ...action.payload},
        updates: {...state.updates, experiments: new Date()},
      };
    case ActionType.CLEAR_EXPERIMENTS:
      return {
        ...state,
        experiments: null,
        updates: {...state.updates, experiments: null},
      };
    case ActionType.SET_REFERRALS:
      return {
        ...state,
        referrals: action.payload,
        updates: {...state.updates, referrals: new Date()},
      };
    case ActionType.SET_USER_SETTINGS:
      const newSettings = merge({}, state.userSettings || {}, action.payload);
      return {
        ...state,
        userSettings: newSettings,
        updates: {...state.updates, userSettings: new Date()},
      };
    case ActionType.SET_EVENT_SUBSCRIPTION:
      const updatedEventSubscriptions = merge({}, state.userSettings || {}, {
        event_subscriptions: action.payload,
      });
      return {
        ...state,
        userSettings: updatedEventSubscriptions,
        updates: {...state.updates, userSettings: new Date()},
      };
    case ActionType.SET_USER_SETTINGS_SUBSCRIPTION:
      const newSettings2 = merge({}, state.userSettings || {}, {
        general_subscriptions: action.payload,
      });
      return {
        ...state,
        userSettings: newSettings2,
        updates: {...state.updates, userSettings: new Date()},
      };
    case ActionType.SET_USER_SETTINGS_PHONE_NUMBERS:
      return {
        ...state,
        userSettings: {...(state.userSettings || {}), phone_numbers: action.payload},
        updates: {...state.updates, userSettings: new Date()},
      };
    case ActionType.SET_PHONE_VERIFICATION_CODE:
      return {
        ...state,
        updates: {...state.updates, verificationCode: new Date()},
      };
    case ActionType.UPDATE_USER_UTILITY_INFO:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          utility_info: {
            ...state.userSettings.utility_info,
            acronym: action.payload.acronym,
            short_name: action.payload.short_name,
            utility_id: action.payload.utility_id,
            utility_name: action.payload.utility_name,
          },
        },
      };
    case ActionType.SET_USER_DEVICE_OFFER:
      const newOffers = merge({}, state.offers || {}, {userDeviceOffer: action.payload});
      return {
        ...state,
        offers: newOffers,
        updates: {...state.updates, offers: new Date()},
      };
    case ActionType.CLEAR_USER_DEVICE_OFFER:
      return {
        ...state,
        offers: {},
        updates: {...state.updates, offers: null},
      };
    case ActionType.SET_USER_ORDER_STATUS:
      return {...state, orders: {...state.orders, userOrderStatus: action.payload}};
    // # Signup
    case ActionType.SET_USER_SIGNUP_CAPTCHA_REQUIRED:
      return {...state, user: {...state.user, signupCaptchaRequired: action.payload}};
    case ActionType.SET_USER_GIFT_CODES:
      return {...state, user: {...state.user, giftCodes: action.payload}};
    case ActionType.SET_SIGNUP_STEP:
      return {...state, signup: {...state.signup, step: action.payload}};
    case ActionType.SET_SIGNUP_UTILITY_OPTIONS:
      return {...state, signup: {...state.signup, utilityOptions: action.payload}};
    case ActionType.SET_FULL_UTILITY_LIST:
      return {...state, signup: {...state.signup, fullUtilityList: action.payload}};
    case ActionType.SET_UTILITY_OUTAGE:
      return {...state, signup: {...state.signup, utilityOutage: action.payload}};
    case ActionType.SET_SIGNUP_FB_CONVERSION_DATA:
      return {...state, signup: {...state.signup, fbConversionData: action.payload}};
    // # Login/Logout:
    case ActionType.SET_USER_LOGIN_CAPTCHA_REQUIRED:
      return {...state, user: {...state.user, loginCaptchaRequired: action.payload}};
    case ActionType.SET_MULTIFACTOR_VERIFICATION_OPTIONS:
      return {...state, multifactorVerificationOptions: action.payload};
    case ActionType.SET_REDIRECT:
      return {...state, redirect: action.payload};
    case ActionType.SET_RHR_LIST:
      return {...state, rhrList: action.payload};
    case ActionType.LOGOUT:
      return Object.assign({}, initialValue);
    // # Devices/connections:
    case ActionType.SET_CONNECTION_DEVICES:
      // devices might be missing for the connection so prune all of this connection's
      // devices if id is set and then add the new ones
      const prunedConnectionDevices = action.connectionId
        ? state.connectionDevices &&
          omit(
            state.connectionDevices,
            Object.values(state.connectionDevices)
              .filter((device: any) => device.connectionId === Number(action.connectionId))
              .map((device: any) => device.deviceId),
          )
        : state.connectionDevices || {};
      const mergedConnectionDevices = merge({}, prunedConnectionDevices, action.payload);
      return {...state, connectionDevices: mergedConnectionDevices};
    case ActionType.OMIT_CONNECTION_DEVICES:
      const newConnectionDevices = omit(state.connectionDevices || {}, action.payload);
      return {...state, connectionDevices: newConnectionDevices};
    case ActionType.SET_CONNECTIONS:
      return {...state, connections: action.payload};
    // # Configs:
    case ActionType.SET_CONNECTION_CONFIGS:
      return {...state, connectionConfigs: action.payload};
    case ActionType.SET_VERIZON_CONNECTION_CONFIGS:
      return {...state, verizonConnectionConfigs: action.payload};
    case ActionType.SET_DEVICE_CONFIGS:
      return {...state, deviceConfigs: action.payload};
    // # Messages:
    case ActionType.SET_DEVICE_MESSAGES:
      const mergedDeviceMessages = merge({}, state.deviceMessages || {}, action.payload);
      return {...state, deviceMessages: mergedDeviceMessages};
    case ActionType.OMIT_DEVICE_MESSAGES:
      const newDeviceMessages = omit(state.deviceMessages || {}, action.payload);
      return {...state, deviceMessages: newDeviceMessages};
    // # Events:
    case ActionType.SET_UPCOMING_EVENTS:
      return {
        ...state,
        upcomingEvents: action.payload,
        updates: {...state.updates, upcomingEvents: new Date()},
      };
    case ActionType.SET_PENDING_EVENT:
      return {...state, pendingEvent: action.payload};
    case ActionType.SET_HISTORICAL_EVENTS_COUNT:
      return {...state, historicalEventsCount: action.payload};
    case ActionType.SET_ENERGY_EVENTS:
      return {
        ...state,
        energyEvents: action.payload,
        updates: {...state.updates, energyEvents: new Date()},
      };
    case ActionType.ADD_ENERGY_EVENT:
      return {...state, energyEvents: [action.payload, ...(state.energyEvents ?? [])]};
    case ActionType.REFRESH_ENERGY_EVENT:
      const mergedEnergyEvents = unionBy(
        state.energyEvents || [],
        action.payload as any,
        'f_ohm_hour_user_id',
      );
      return {...state, energyEvents: mergedEnergyEvents};
    case ActionType.UPDATE_ENERGY_EVENT_BASELINE_RECORDS:
      const energyEvents = state.energyEvents || [];
      const targetEnergyEvent = energyEvents.find(
        (event: any) => event.f_ohm_hour_user_id === action.eventId,
      );
      // if we don't find the event, don't do anything
      if (!targetEnergyEvent) return state;
      // and if the event's baseline records is equal to the payload then don't do anything
      if (isEqual(targetEnergyEvent.baseline_records, action.payload)) return state;
      // otherwise, update the event's baseline records
      const updatedEnergyEvents = unionBy(
        [{...targetEnergyEvent, baseline_records: action.payload}],
        energyEvents,
        'f_ohm_hour_user_id',
      );
      return {...state, energyEvents: updatedEnergyEvents};
    case ActionType.SET_IN_PROGRESS_EVENT:
      return {...state, inProgressEvent: action.payload};
    case ActionType.SET_EVENT_CHECKLIST:
      return {...state, eventChecklist: action.payload};
    case ActionType.SET_EVENT_CHECKLIST_ITEM:
      const {ohcId, checked} = action.payload;
      const {eventChecklist} = state;
      find(eventChecklist, i => i.ohc_id === ohcId).checked = checked;
      return {...state, eventChecklist: eventChecklist};
    case ActionType.SET_NEXT_BEST_ACTION:
      return {...state, nextBestAction: action.payload};
    case ActionType.CLEAR_NEXT_BEST_ACTION:
      return {...state, nextBestAction: {signup: null, dashboard: null}};
    // # Wallet/Reward marketplace:
    case ActionType.SET_WALLET:
      return {...state, wallet: action.payload, updates: {...state.updates, wallet: new Date()}};
    case ActionType.SET_WALLET_CURRENT_POINTS:
      const newWallet = merge({}, state.wallet || {}, action.payload);
      return {...state, wallet: newWallet};
    case ActionType.SET_REWARDS:
      return {...state, rewards: action.payload, updates: {...state.updates, rewards: new Date()}};
    // # UI:
    case ActionType.ADD_FLASH_MESSAGE:
      return {...state, flashMessages: [...(state.flashMessages || []), action.payload]};
    case ActionType.DROP_FLASH_MESSAGE:
      return {...state, flashMessages: drop(state.flashMessages)};
    case ActionType.SHOW_EXIT_INTENT_MODAL:
      return {...state, showExitIntent: true};
    case ActionType.SET_CURRENT_THEME:
      return {...state, currentTheme: action.payload};
    // # Reload based on deploy
    case ActionType.SET_APP_VERSION_OUTDATED:
      return {
        ...state,
        appVersionOutdated: action.payload,
      };
    case ActionType.SET_FEATURES:
      return merge({}, state, {
        features: {
          updated: new Date(),
          featureKeys: action.payload,
        },
      });
    case ActionType.SET_LOADING:
      // add the payload (a string) to the array of loading flags, using a set to deduplicate
      return {
        ...state,
        loadingFlags: Array.from(new Set([...state.loadingFlags, action.payload])),
      };
    case ActionType.CLEAR_LOADING:
      return {
        ...state,
        loadingFlags: state.loadingFlags.filter(s => s !== action.payload),
      };
    case ActionType.SET_VACATION:
      return {...state, userSettings: {...state.userSettings, vacationEndDttm: action.payload}};

    case ActionType.SET_AVAILABLE_EGIFTS:
      return merge({}, state, {
        cashout: {
          eGifts: {
            updated: new Date(),
            availableEGifts: action.payload,
          },
        },
      });

    case ActionType.SET_SHOW_ACCOUNT_BACK_LINK:
      return {
        ...state,
        showAccountBackLink: action.payload,
      };

    case ActionType.SET_FRIEND_BUY_AUTH_DATA:
      return {
        ...state,
        friendBuy: {...state.friendBuy, auth: action.payload, authUpdated: new Date()},
      };

    case ActionType.SET_FETCH_INSTANCE:
      const hasExistingFetchInstance = state.fetchInstances
        .map(i => i.id)
        .includes(action.payload.id);
      return {
        ...state,
        fetchInstances: hasExistingFetchInstance
          ? state.fetchInstances.map(i => (i.id === action.payload.id ? action.payload : i))
          : [...state.fetchInstances, action.payload],
      };
    case ActionType.COMPLETE_FETCH_INSTANCE:
      return state.fetchInstances.map(i => i.id).includes(action.payload)
        ? {
            ...state,
            fetchInstances: state.fetchInstances.map(i =>
              i.id === action.payload ? {...i, completed: new Date()} : i,
            ),
          }
        : state;
    case ActionType.REMOVE_FETCH_INSTANCES:
      const fetchInstancesToRemove = state.fetchInstances.filter(i =>
        action.payload.includes(i.id),
      );
      // always abort fetches that may be in progress when removing them
      fetchInstancesToRemove.forEach(i => i.abortController.abort());
      return {
        ...state,
        fetchInstances: state.fetchInstances.filter(i => !action.payload.includes(i.id)),
      };
    case ActionType.IS_FROM_WALLET_PAGE:
      return {
        ...state,
        isFromWalletPage: action.payload,
      };

    // # Unexpected action:
    default:
      // Assert all possible action types are accounted for
      assertNever(action);
      // eslint-disable-next-line no-console
      console.error(`Unexpected action: ${action['type']}`);
      return state;
  }
}

// Helper to assert the value is definately never
function assertNever(x: never): void {
  throw new Error('Unexpected object: ' + x);
}

// Provider
function Provider({children}: WithChildren): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialValue);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
}

export const GlobalStore: GlobalStoreConfig<ActionType> = {
  State,
  Dispatch,
  Provider,
};
