/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const EventsFill: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9146 5.05531C16.9401 4.71096 16.7272 4.39371 16.3988 4.28683C16.0705 4.17995 15.7116 4.31106 15.5295 4.60442L9.05245 15.0376C8.65958 15.6704 9.1147 16.4886 9.85957 16.4886H14.5258L14.0854 22.4447C14.0599 22.789 14.2728 23.1063 14.6012 23.2132C14.9295 23.32 15.2884 23.1889 15.4705 22.8956L21.9475 12.4624C22.3404 11.8296 21.8853 11.0114 21.1404 11.0114H16.4742L16.9146 5.05531Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25856 23.4065L9.90682 21.3542C9.95841 21.3354 10.0155 21.3618 10.0347 21.4132L10.8894 23.7035C10.963 23.9009 11.0514 24.0925 11.1537 24.2766L11.254 24.4572C12.4417 26.595 15.2434 27.1925 17.1998 25.7251C17.3995 25.5754 17.5849 25.4074 17.7535 25.2234L20.9549 21.7297C20.9822 21.6999 21.0248 21.6895 21.0628 21.7033L25.7417 23.4065C25.8295 23.4384 25.8294 23.5625 25.7417 23.5944L15.0342 27.488C15.0121 27.4961 14.9879 27.4961 14.9658 27.488L4.25854 23.5945C4.17076 23.5626 4.17078 23.4384 4.25856 23.4065Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
