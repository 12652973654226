/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {ENV} from './globals';

axios.defaults.baseURL = `${ENV.backendBaseUrl}/`;
axios.defaults.crossDomain = true;
// Note that this means we have to explicitly add {withCredentials: false} if we _don't_ want to
// send credentials, e.g. for third-party requests made from the frontend. These should be pretty
// rare (we don't have any currently), but it's good to keep in mind.
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrf_token';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// Function that will be called to refresh authorization
function refreshAuthLogic(failedRequest) {
  return failedRequest.response &&
    failedRequest.response.data &&
    failedRequest.response.data.status === -303
    ? axios
        .get(`${ENV.backendBaseUrl}/api/v2/reset_cookies`)
        .then(tokenRefreshResponse => Promise.resolve())
    : Promise.reject(failedRequest);
}
createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes: [400]});

axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Capture 503 responses and redirect to maintenance page
    // NB this would fully interrupt an in-progress session
    // if a user action results in a 503
    if (
      error.response &&
      error.response.status === 503 &&
      // Skip this on /login, 503 is returned when too many login attempts are made
      !window.location.pathname.startsWith('/maintenance') &&
      !window.location.pathname.startsWith('/login')
    ) {
      return (window.location.pathname = '/maintenance');
    }

    // Capture 401 responses and redirect to the logout page
    if (
      error.response &&
      error.response.status === 401 &&
      // Skip this on the /login and password reset pages!
      !window.location.pathname.startsWith('/login') &&
      !window.location.pathname.startsWith('/forgot-password') &&
      // Skip this for MFA responses.
      !('verificationOptions' in error.response.data)
    ) {
      return (window.location.pathname = '/logout');
    }

    // Capture 303 responses and redirect as appropriate
    if (error.response && error.response.status === 303 && error.response.data.redirect)
      return (window.location = error.response.data.redirect);

    return Promise.reject(error);
  },
);
