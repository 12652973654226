// returns true if the browser is mobile, otherwise return false
export function isMobileBrowser(navigator) {
  const {maxTouchPoints, userAgent, userAgentData} = navigator;

  const userAgentDataMobile = !!userAgentData && userAgentData.mobile;
  const hasMaxTouchPoints = maxTouchPoints > 0;
  const userAgentSniffTest =
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);

  return userAgentDataMobile || hasMaxTouchPoints || userAgentSniffTest;
}
