/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import ProfilePictureDisplay from 'components/shared/ProfilePictureDisplay';
import {ComponentProps} from '../../../types';

const StyledLink = styled(Link)`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
`;

const StyledProfilePictureDisplay = styled(ProfilePictureDisplay)`
  ${({theme}) => css`
    border: 1.5px solid ${theme.brandbar.color};
    display: flex;
    margin: 0;
  `}
`;

export const ProfileSettingsLink: React.FC<ComponentProps<
  typeof StyledProfilePictureDisplay
>> = props => {
  return (
    <StyledLink to="/settings">
      <StyledProfilePictureDisplay size={30} {...props} />
    </StyledLink>
  );
};
