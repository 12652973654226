/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function LinkLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.0067C10.4294 13.5847 10.9773 14.0629 11.6065 14.409C12.2357 14.755 12.9315 14.9608 13.6466 15.0124C14.3618 15.0639 15.0795 14.9601 15.7513 14.7078C16.4231 14.4555 17.0331 14.0608 17.54 13.5503L20.54 10.5303C21.4507 9.58089 21.9547 8.30949 21.9433 6.98969C21.9319 5.66999 21.4061 4.40749 20.4791 3.47429C19.552 2.54099 18.2979 2.01169 16.987 2.00019C15.676 1.98871 14.413 2.49609 13.47 3.41299L11.75 5.13439"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 10.9933C13.5707 10.4153 13.0228 9.93713 12.3936 9.59113C11.7644 9.24503 11.0687 9.03923 10.3535 8.98773C9.63843 8.93613 8.92063 9.04003 8.24883 9.29223C7.57713 9.54453 6.96703 9.93923 6.46023 10.4497L3.46023 13.4698C2.54943 14.4191 2.04543 15.6906 2.05683 17.0103C2.06823 18.3301 2.59403 19.5925 3.52113 20.5258C4.44813 21.459 5.70223 21.9884 7.01323 21.9998C8.32413 22.0113 9.58713 21.504 10.5302 20.5871L12.2402 18.8656"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
