/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Transfer({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM18.2538 34.3751H32.2486C33.4223 34.3751 34.3736 33.4236 34.3736 32.2501C34.3736 31.0766 33.4223 30.1251 32.2486 30.1251H18.2538L18.8763 29.5026C19.7062 28.6728 19.7062 27.3273 18.8763 26.4975C18.0465 25.6675 16.7009 25.6675 15.8711 26.4975L11.6216 30.7469C11.0045 31.3638 10.8114 32.3261 11.2038 33.1563C11.226 33.2032 11.2453 33.2441 11.2664 33.2841C11.4592 33.6492 11.803 33.9345 15.8711 38.0026C16.286 38.4176 16.8298 38.6251 17.3736 38.6251C19.2496 38.6251 20.2189 36.3401 18.8763 34.9976L18.2538 34.3751ZM33.7513 23.1276L38.0005 18.8784C38.7669 18.1127 38.8373 16.8755 38.1422 16.0285C38.1336 16.0181 38.1286 16.0114 38.1235 16.0048C38.0702 15.9361 38.0051 15.8765 33.7511 11.6225C32.9213 10.7925 31.5758 10.7925 30.746 11.6225C29.916 12.4523 29.916 13.7978 30.746 14.6276L31.3685 15.2501H17.3736C16.2001 15.2501 15.2486 16.2016 15.2486 17.3751C15.2486 18.5486 16.2001 19.5001 17.3736 19.5001H31.3686L30.7461 20.1226C29.4033 21.4651 30.3726 23.7501 32.2486 23.7501C32.7925 23.7501 33.3362 23.5426 33.7513 23.1276Z"
        fill="currentColor"
      />
    </svg>
  );
}
