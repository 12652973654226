/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import * as Theme from 'theme';
import {rgbShade, rgbTint, formatRgba, contrastColor, unformatRgba} from './color.helpers';

/**
 * Get all of the color overlays for a particular color
 * @param name color name
 * @param {[r:number,g:number,b:number]} rgbArr array of RGB values
 * @param overlayFactors overlay factors object
 * @returns object with a css-ready key/value for each overlay factor
 */
function getColorOverlays<K extends keyof Theme.BaseColors | keyof Theme.BaseOverlayColors>(
  name: K,
  rgbArr: number[],
  overlayFactors: Theme.OverlayFactors,
): Theme.CPOverlayColors<K> {
  const [r, g, b] = rgbArr;
  const factorsArr = Object.entries(overlayFactors);
  return factorsArr.reduce((prev, [id, value]): Theme.CPOverlayColors<K> => {
    // add a descriptive key to the object and a css-formatted RGBA value
    return {...prev, [name + id]: formatRgba([r, g, b, value])};
  }, {} as Theme.CPOverlayColors<K>);
}

/**
 * Get all of the scales for a particular color
 * @param name color name
 * @param {[r:number,g:number,b:number]} rgbArr array of RGB values
 * @param luminosityFactors luminosity factors object
 * @returns object with a css-ready key/value for each luminosity factor
 */
function getColorScale<K extends keyof Theme.BaseColors>(
  name: K,
  rgbArr: number[],
  luminosityFactors: Theme.LuminosityFactors,
): Theme.CPLuminosityColors<K> {
  const factorsArr = Object.entries(luminosityFactors);
  return factorsArr.reduce((prev, [id, value]): Theme.CPLuminosityColors<K> => {
    if (value < 0) {
      const result = rgbShade(rgbArr, Math.abs(value));
      // add a descriptive key to the object and a css-formatted RGBA value
      return {...prev, [name + id]: formatRgba(result)};
    } else if (value > 0) {
      const result = rgbTint(rgbArr, Math.abs(value));
      // add a descriptive key to the object and a css-formatted RGBA value
      return {...prev, [name + id]: formatRgba(result)};
    } else {
      // add a descriptive key to the object and a css-formatted RGBA value
      return {...prev, [name + id]: formatRgba(rgbArr)};
    }
  }, {} as Theme.CPLuminosityColors<K>);
}

/**
 * Given a color, creates a color palette object with the base color and
 * luminosity and overlay variations.
 * @param name color name
 * @param color color object
 * @param colorPaletteConfig color palette config object
 * @returns object with a base color, and subobjects for shades, tints, and overlays
 */
function generateColorPalette<K extends keyof Theme.BaseColors>(
  name: K,
  color: string,
  colorPaletteConfig: Theme.ColorPaletteConfig,
): Theme.BaseColorPalette<K> {
  const {luminosityFactors, overlayFactors, contrastColors} = colorPaletteConfig;
  const rgbArr = unformatRgba(color);
  const base = {
    [name]: formatRgba(rgbArr),
  } as Theme.CPBaseColors<K>;
  const contrast = {
    [name + 'Contrast']: contrastColor(rgbArr, contrastColors?.light, contrastColors?.dark),
  } as Theme.CPContrastColors<K>;
  const colorScale = getColorScale(name, rgbArr, luminosityFactors);
  const overlays = getColorOverlays(name, rgbArr, overlayFactors);
  return {
    ...base,
    ...contrast,
    ...colorScale,
    ...overlays,
  };
}

/**
 * Given a color palette config, creates a color object with the base colors and
 * luminosity and overlay variations for each.
 * @param colorPaletteConfig color palette config object
 * @returns object with a base color, and subobjects for shades, tints, and overlays
 */
export function generateColorPalettes(
  colorPaletteConfig: Theme.ColorPaletteConfig,
): Omit<Theme.Color, keyof Theme.SingleColors> {
  const {baseColors, baseOverlayColors, overlayFactors} = colorPaletteConfig;
  const generatedBasePalettes = Object.entries(baseColors).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...generateColorPalette(key as keyof Theme.BaseColors, value, colorPaletteConfig),
    }),
    {} as Theme.BaseColorPalette,
  );
  const generatedOverlayPalettes = Object.entries(baseOverlayColors).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: value,
      ...getColorOverlays(
        key as keyof Theme.BaseOverlayColors,
        unformatRgba(value),
        overlayFactors,
      ),
    }),
    {} as Theme.BaseOverlayColorPalette,
  );
  return {
    ...generatedBasePalettes,
    ...generatedOverlayPalettes,
  };
}
