/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect, useRef} from 'react';
import {get} from 'lodash';
import {useLocation} from 'react-router-dom';
import {useGlobalState} from 'store';
import {useTriggeredSurvey} from './useTriggeredSurvey';
import SurveyAnnouncement, {SURVEY_USER_ATTRIBUTE_PREFIX} from './SurveyAnnouncement';

/**
 * All possible trigger keys for showing a triggerable survey
 */
export const SURVEY_TRIGGERS = {
  TRIGGER_SURVEY_ON_NEXT_LOCATION: 'TRIGGER_SURVEY_ON_NEXT_LOCATION',
  TRIGGER_SURVEY_IMMEDIATELY: 'TRIGGER_SURVEY_IMMEDIATELY',
};

/**
 * Component to show all surveys for all possible triggers
 */
export function TriggeredSurveyAnnouncement() {
  return (
    <>
      <TriggeredSurveyOnNextLocation />
      <TriggeredSurveyImmediately />
    </>
  );
}

/**
 * Component to conditionally show a survey on the next location change,
 * if survey data could be found.
 */
function TriggeredSurveyOnNextLocation() {
  const location = useLocation();
  const [surveyData] = useTriggeredSurvey(SURVEY_TRIGGERS.TRIGGER_SURVEY_ON_NEXT_LOCATION);

  const updatedSurveyData = useRef();

  useEffect(() => {
    return () => {
      // on location change, check for survey data
      if (surveyData) updatedSurveyData.current = surveyData;
    };
  }, [location, surveyData]);

  return updatedSurveyData.current ? (
    <ShowTriggeredSurvey surveyData={updatedSurveyData.current} />
  ) : null;
}

/**
 * Component to conditionally show a survey right away,
 * if survey data could be found.
 */
function TriggeredSurveyImmediately() {
  const [surveyData] = useTriggeredSurvey(SURVEY_TRIGGERS.TRIGGER_SURVEY_IMMEDIATELY);

  const updatedSurveyData = useRef();

  useEffect(() => {
    // on change, updated
    updatedSurveyData.current = surveyData;
  }, [surveyData]);

  return updatedSurveyData.current ? (
    <ShowTriggeredSurvey surveyData={updatedSurveyData.current} />
  ) : null;
}

/**
 * Component which wraps Survey Announcement specifically to handle cleanup of trigger data
 * @param {*} props.surveyData survey data retreived from storage
 */
function ShowTriggeredSurvey({surveyData}) {
  const globalState = useGlobalState();
  const [, setSurveyData] = useTriggeredSurvey(surveyData ? surveyData.triggerKey : undefined);

  // if there's no reward data in session storage, there's no survey trigger
  if (!surveyData) {
    // bail
    return null;
  }

  // get user attribute data for this survey
  const surveyUserAttribute = SURVEY_USER_ATTRIBUTE_PREFIX + surveyData.surveyKey;
  const surveyUserAttributeValue = get(globalState.user.attributes, surveyUserAttribute);

  // if an attribute val is greater than 1, user has already completed the flow
  // clean up the trigger data
  if (surveyUserAttributeValue && parseInt(surveyUserAttributeValue) > 1) {
    // user has previously been through and completed the flow
    // cleanup and remove key from storage
    setSurveyData(undefined);
    // bail
    return null;
  }
  return (
    <SurveyAnnouncement
      survey={surveyData.surveyKey}
      additionalSurveyFields={surveyData.additionalSurveyFields}
    />
  );
}
