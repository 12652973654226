/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */

import {ENV} from 'config/globals';
import {useEffect, useCallback, useMemo, useRef} from 'react';
import api from 'api';
import axios from 'axios';
import {debounce, each, get, pickBy} from 'lodash';
import * as FullStory from '@fullstory/browser';

import {useFlashError, useFlashInfo, useFlashSuccess} from 'hooks/useFlash';
import {useExperimentExposed} from 'hooks/useExperiment';
import useMountEffect from 'hooks/useMountEffect';
import useShouldUpdate from 'hooks/useShouldUpdate';
import useQuery from 'hooks/useQuery';
import {useGlobalDispatch, useGlobalState, ActionType} from 'store';
import {
  captureException,
  trackStructuredEvent,
  getCurrentMainBundleName,
  logoutKustomer,
} from 'utils';
import {useGetUserIsAuthenticated} from './selectors';

// useEffect hooks (these should probably be separate files):

export function useFetchEnergyEvents() {
  const globalDispatch = useGlobalDispatch();
  const flashError = useFlashError();
  const shouldUpdate = useShouldUpdate();
  const lock = useRef(false);
  useEffect(() => {
    async function fetchEnergyEvents() {
      if (!!lock.current) return;
      // Don't refetch data if we received data in the last 30 seconds
      if (!shouldUpdate('energyEvents', 30)) return;
      lock.current = true;
      try {
        const response = await axios.get('/api/v2/events');
        globalDispatch({
          type: ActionType.SET_ENERGY_EVENTS,
          payload: response.data,
        });
      } catch (error) {
        flashError({
          capture: error,
          source: 'useFetchEnergyEvents',
        });
      } finally {
        lock.current = false;
      }
    }
    fetchEnergyEvents();
  }, [flashError, globalDispatch, shouldUpdate]);
}

export function useRefreshSettings() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(async () => {
    try {
      const response = await axios.get('/api/v2/settings');
      globalDispatch({
        type: ActionType.SET_USER_SETTINGS,
        payload: response.data,
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useFetchSettings(fetchNow) {
  const globalState = useGlobalState();
  const shouldUpdate = useShouldUpdate();
  const refreshSettings = useRefreshSettings();

  useMountEffect(() => {
    async function fetchUserSettings(fetchNow = false) {
      // don't attempt to fetch settings if user is not logged in
      if (!globalState.user.id) return;
      // Don't refetch data if we received data in the last 30 seconds
      if (!shouldUpdate('userSettings', 30) && !fetchNow) return;
      return refreshSettings();
    }
    fetchUserSettings(fetchNow);
  });
}

export function useFetchReferrals() {
  const globalDispatch = useGlobalDispatch();
  const userIsAuthenticated = useGetUserIsAuthenticated();
  const shouldUpdate = useShouldUpdate();
  const lock = useRef(false);

  return useCallback(
    (lastUpdatePeriod = 30) => {
      const asyncFn = async () => {
        if (!!lock.current) return;
        // don't attempt to fetch referrals if user is not logged in
        if (!userIsAuthenticated) return;

        // Don't refetch data if we received data in the lastUpdatePeriod (default = 30) seconds
        if (!shouldUpdate('referrals', lastUpdatePeriod)) return;
        lock.current = true;
        try {
          const response = await axios.get('/api/v2/referrals');
          globalDispatch({
            type: ActionType.SET_REFERRALS,
            payload: response.data,
          });
        } catch (error) {
        } finally {
          lock.current = false;
        }
      };
      asyncFn();
    },
    [globalDispatch, shouldUpdate, userIsAuthenticated],
  );
}

// useCallback hooks (these should probably be separate files):

export function useSetBillboardAnnouncementDisplay() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    data => {
      globalDispatch({
        type: ActionType.SET_BILLBOARD_ANNOUNCEMENT_DISPLAY,
        payload: data,
      });
    },
    [globalDispatch],
  );
}

export function useFetchWallet() {
  const globalDispatch = useGlobalDispatch();
  const shouldUpdate = useShouldUpdate();

  return useCallback(
    async (fetchNow = false) => {
      // Don't refetch data if we received data in the last 5 seconds
      if (!shouldUpdate('wallet', 5) && !fetchNow) return;
      try {
        const response = await api.Wallet.getWallet();
        globalDispatch({
          type: ActionType.SET_WALLET,
          payload: response.data,
        });
      } catch (error) {}
    },
    [globalDispatch, shouldUpdate],
  );
}

export function useFetchUpcomingEvents() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    trackStructuredEvent({
      category: 'event_polling_upcoming_events',
      action: 'fetch',
    });
    try {
      const response = await axios.get('/api/v2/upcoming_events');
      globalDispatch({
        type: ActionType.SET_UPCOMING_EVENTS,
        payload: response.data,
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useFetchEventBaselineRecords() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    async fOhmHourUserId => {
      try {
        const response = await axios.get(`/api/v2/events/${fOhmHourUserId}/baseline_records`);
        globalDispatch({
          type: ActionType.UPDATE_ENERGY_EVENT_BASELINE_RECORDS,
          payload: response.data,
          eventId: fOhmHourUserId,
        });
      } catch (error) {}
    },
    [globalDispatch],
  );
}

export function useFetchDeviceMessages() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    try {
      // NB this endpoint is generic; currently returns only
      // device messages, but may return other messages in the future
      const response = await axios.get('/api/v2/messages');
      const deviceMessages = pickBy(response.data, m =>
        ['MESSAGE_DEVICE_ADDED', 'MESSAGE_DEVICE_DEACTIVATED'].includes(m.messageType),
      );
      globalDispatch({
        type: ActionType.SET_DEVICE_MESSAGES,
        payload: deviceMessages,
      });
    } catch (error) {
      // log the error, but don't display anything
      captureException(error);
    }
  }, [globalDispatch]);
}

export function useFetchHistoricalEventsCount() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    try {
      const response = await axios.get('/api/v2/historical_events_count');
      globalDispatch({
        type: ActionType.SET_HISTORICAL_EVENTS_COUNT,
        payload: response.data.HistoricalEventsCount ?? null,
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useRefreshWallet() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    const response = await api.Wallet.getWallet();
    globalDispatch({
      type: ActionType.SET_WALLET,
      payload: response.data,
    });
  }, [globalDispatch]);
}

async function apiLogout() {
  try {
    await axios.get('/api/v2/logout');
  } catch (error) {}
  FullStory.anonymize();
  logoutKustomer();
}

export function useLogout() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    await apiLogout();
    globalDispatch({
      type: ActionType.LOGOUT,
    });
  }, [globalDispatch]);
}

export function useLogoutForHardLogin() {
  return useCallback(apiLogout, []);
}

export function useSelectUtility() {
  const globalState = useGlobalState();
  const globalDispatch = useGlobalDispatch();

  return useCallback(
    async (utilityId, selectedOther, isSpecialUtility = false, specialUtilityInfo = {}) => {
      const formData = new FormData();

      // On signup screens there is an option to choose "I don't see my utility" which opens
      // a second dropdown with more choices, utility id would be "999" (other) and we'd provide
      // secondary id
      formData.append('utility', selectedOther ? '999' : utilityId);
      formData.append('utility_free_text', utilityId);
      formData.append('zip_code', get(globalState.userSettings, 'utility_info.zip_code', null));
      if (isSpecialUtility) {
        Object.keys(specialUtilityInfo).forEach(field => {
          formData.append(field, specialUtilityInfo[field]);
        });
      }

      const response = await api.Signup.selectUtility(formData);

      globalDispatch({
        type: ActionType.SET_USER_SETTINGS,
        payload: response.data.settings,
      });
    },
    [globalState, globalDispatch],
  );
}

export function useSetUserAttributes() {
  const globalDispatch = useGlobalDispatch();
  // `lookups` is an object like {P4P_FEEDBACK_SURVEY_ANNOUNCE: 1}
  return useCallback(
    async lookups => {
      try {
        const response = await axios.post('/api/v2/rel_user', lookups);
        globalDispatch({
          type: ActionType.SET_USER_ATTRIBUTES,
          payload: response.data,
        });
      } catch (error) {}
    },
    [globalDispatch],
  );
}

// fetch current announcement for user
export function useFetchAnnouncement() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(
    async placement => {
      try {
        const response = await axios.get(`/api/v2/announcement/${placement}`);
        globalDispatch({
          type: ActionType.SET_ANNOUNCEMENT,
          payload: {[placement]: response.data},
        });
      } catch (error) {}
    },
    [globalDispatch],
  );
}

// Fetch all (connection) devices
export function useFetchConnectionDevices() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    async function fetchConnectionDevices() {
      try {
        const response = await axios.get('/api/v2/device/list');
        globalDispatch({
          type: ActionType.SET_CONNECTION_DEVICES,
          payload: response.data,
        });
      } catch (error) {}
    }
    fetchConnectionDevices();
  }, [globalDispatch]);
}

// Fetch device states for a specific connection
// Returns `true` if the request resolved as expected, returns `false` if not
export function useFetchConnectionDeviceState() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    async connectionId => {
      try {
        const response = await axios.get('/api/v2/device/state/' + connectionId);
        globalDispatch({
          type: ActionType.SET_CONNECTION_DEVICES,
          payload: response.data,
          connectionId: connectionId,
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    [globalDispatch],
  );
}

// Fetch connections and then the device states for each connection
export function useFetchConnections() {
  const globalDispatch = useGlobalDispatch();
  const fetchConnectionDeviceState = useFetchConnectionDeviceState();
  return useCallback(async () => {
    try {
      const response = await axios.get('/api/v2/connection/list');
      globalDispatch({
        type: ActionType.SET_CONNECTIONS,
        payload: response.data,
      });
      const stateFetches = Object.keys(response.data).map(fetchConnectionDeviceState);
      Promise.all(stateFetches);
    } catch (error) {
      captureException(error);
    }
  }, [fetchConnectionDeviceState, globalDispatch]);
}

export function useRefreshUserStatus() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    try {
      const response = await axios.get('/api/v2/user_status');
      globalDispatch({
        type: ActionType.SET_USER,
        payload: response.data,
      });

      // Update user id in Google Tag Manager
      window.gtmActive && window.dataLayer.push({user_id: response.data.id});
    } catch (error) {}
  }, [globalDispatch]);
}

export function useFetchUserReferralShare() {
  const globalDispatch = useGlobalDispatch();
  const shouldUpdate = useShouldUpdate();
  useMountEffect(() => {
    async function fetchReferralShare() {
      if (!shouldUpdate('referralShare', 30)) return;
      try {
        const response = await axios.get('/api/v2/referral_share');
        globalDispatch({
          type: ActionType.SET_USER_REFERRAL_SHARE,
          payload: response.data,
        });
      } catch (error) {}
    }
    fetchReferralShare();
  });
}

export function useRefreshUserReferralShare() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(async () => {
    try {
      const response = await axios.get('/api/v2/referral_share');
      globalDispatch({
        type: ActionType.SET_USER_REFERRAL_SHARE,
        payload: response.data,
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useFetchUserReferralOffers() {
  const globalDispatch = useGlobalDispatch();
  const shouldUpdate = useShouldUpdate();
  const experimentExposed = useExperimentExposed();
  const query = useQuery();
  useMountEffect(() => {
    async function fetchReferralShare() {
      if (!experimentExposed('referral_offers_full') || !shouldUpdate('referralOffers', 30)) return;
      try {
        // Pass in `dt` query param to preview what rewards page will look like at a point in time.
        const response = await api.Referral.getReferralOffers(query.get('dt'));
        globalDispatch({
          type: ActionType.SET_USER_REFERRAL_OFFERS,
          payload: response.data,
        });
      } catch (error) {}
    }
    fetchReferralShare();
  });
}

export function useRefreshUserReferralOffers() {
  const globalDispatch = useGlobalDispatch();
  const query = useQuery();
  return useCallback(async () => {
    try {
      // Pass in `dt` query param to preview what rewards page will look like at a point in time.
      const response = await api.Referral.getReferralOffers(query.get('dt'));
      globalDispatch({
        type: ActionType.SET_USER_REFERRAL_OFFERS,
        payload: response.data,
      });
    } catch (error) {}
  }, [globalDispatch, query]);
}

export function useFetchExperiments() {
  const globalDispatch = useGlobalDispatch();
  const userIsAuthenticated = useGetUserIsAuthenticated();
  const shouldUpdate = useShouldUpdate();
  return useCallback(
    async (fetchNow = false) => {
      // don't attempt to fetch experiments if user is not logged in
      if (!userIsAuthenticated) return;

      if (!shouldUpdate('experiments', 30) && !fetchNow) return;
      try {
        const response = await axios.get('/api/v2/experiments', {
          params: {
            // If we add specific experiments to this list, only those ones will be retrieved
            experiments: [],
          },
        });
        globalDispatch({
          type: ActionType.SET_EXPERIMENTS,
          payload: response.data,
        });
      } catch (error) {
        // If the endpoint errors out, raise a handled exception for alerting purposes
        // but don't show an infinite loading state. We do want experiments data, but we
        // don't want to bonk the whole SPA if it errors out for some reason.
        globalDispatch({
          type: ActionType.SET_EXPERIMENTS,
          payload: {},
        });
        // log the error, but don't display anything
        captureException(error);
      }
    },
    [globalDispatch, shouldUpdate, userIsAuthenticated],
  );
}

export function useFetchTrackKeyExperiments() {
  // works for users who are not logged in
  const globalDispatch = useGlobalDispatch();
  const shouldUpdate = useShouldUpdate();
  return useCallback(
    async (fetchNow = false) => {
      if (!shouldUpdate('track_key_experiments', 30) && !fetchNow) return;
      try {
        const response = await axios.get('/api/v2/track_key_experiments', {
          params: {
            // If we add specific experiments to this list, only those ones will be retrieved
            experiments: [],
          },
        });
        globalDispatch({
          type: ActionType.SET_EXPERIMENTS,
          payload: response.data,
        });
      } catch (error) {
        // log the error, but don't display anything
        captureException(error);
      }
    },
    [globalDispatch, shouldUpdate],
  );
}

export function useAddPhone() {
  const globalDispatch = useGlobalDispatch();
  const flashSuccess = useFlashSuccess();
  const flashError = useFlashError();

  return useCallback(
    async number => {
      const formData = new FormData();
      formData.append('phone_1', number);
      try {
        const response = await axios.post('/settings/phones', formData);

        globalDispatch({
          type: ActionType.SET_USER_SETTINGS_PHONE_NUMBERS,
          payload: response.data.phone_numbers,
        });

        flashSuccess('Phone number added.');
      } catch (error) {
        flashError({
          capture: error,
          source: 'useAddPhone',
        });
      }
    },
    [globalDispatch, flashSuccess, flashError],
  );
}

export function useEditPhone() {
  const globalState = useGlobalState();
  const globalDispatch = useGlobalDispatch();
  const flashSuccess = useFlashSuccess();

  return useCallback(
    async (number, newNumber) => {
      const addNewForm = new FormData();
      addNewForm.append('phone_1', newNumber);

      const deleteForm = new FormData();
      deleteForm.append('delete_phone', number);

      // Attempt to add new phone first, does not delete if there are errors
      // Errors handled in form
      const response = await axios.post('/settings/phones', addNewForm);
      await axios.post('settings/phones/delete', deleteForm);

      // Instead of removing the phone number from the frontend state just replace the number.
      let phoneNumbers = [...globalState.userSettings.phone_numbers];
      const phoneIndex = phoneNumbers.findIndex(phone => phone.number === number);

      // Use the phone number from the backend so that it is formatted for display properly.
      if (response.data?.phone_numbers && response.data.phone_numbers.length >= 2) {
        phoneNumbers[phoneIndex] = response.data.phone_numbers[1];
      }

      globalDispatch({
        type: ActionType.SET_USER_SETTINGS_PHONE_NUMBERS,
        payload: phoneNumbers,
      });

      flashSuccess('Phone number changed.');
    },
    [globalDispatch, flashSuccess, globalState.userSettings],
  );
}

export function useVerifyPhone() {
  const globalDispatch = useGlobalDispatch();
  const flashError = useFlashError();
  const flashInfo = useFlashInfo();
  const flashSuccess = useFlashSuccess();

  return useCallback(
    async (phoneToVerify, verifyCode) => {
      const flashCategories = {
        error: flashError,
        info: flashInfo,
        success: flashSuccess,
      };

      const formData = new FormData();
      formData.append('phone_1', phoneToVerify);
      formData.append('verify_1', verifyCode);
      const response = await axios.post('settings/phones/verify', formData);

      globalDispatch({
        type: ActionType.SET_USER_SETTINGS_PHONE_NUMBERS,
        payload: response.data.phone_numbers,
      });

      // Verifying only one phone, but the backend sends back an array of messages,
      // even we only want to display one message
      each(response.data.messages, (messages, category) =>
        messages.forEach(message => {
          if (category === 'error') {
            flashCategories[category]({message: message, source: 'useVerifyPhone'});
          } else flashCategories[category](message);
        }),
      );
    },
    [flashError, flashInfo, flashSuccess, globalDispatch],
  );
}

export function useFetchRewards() {
  const globalDispatch = useGlobalDispatch();
  const shouldUpdate = useShouldUpdate();
  const query = useQuery();
  return useCallback(
    async (fetchNow = false) => {
      // Don't refetch data if we received data in the last 30 seconds
      if (!shouldUpdate('rewards', 30) && !fetchNow) return;
      try {
        // Pass in `dt` query param to preview what rewards page will look like at a point in time.
        const params = query.get('dt') ? {params: {dt: query.get('dt')}} : null;
        const response = await api.RewardMarketplace.getAllRewards(params);
        globalDispatch({
          type: ActionType.SET_REWARDS,
          payload: response.data,
        });
      } catch (error) {}
    },
    [globalDispatch, query, shouldUpdate],
  );
}

// Get the name of the *latest* main JS bundle.
export function useFetchLatestBundleName() {
  const globalDispatch = useGlobalDispatch();

  return useMemo(
    () =>
      // debounce this to prevent repeated calls as users
      // click quickly around the app; we won't run this
      // check again until someone has been on the same
      // page for at least the debounce wait period
      debounce(
        async () => {
          // This is not relevant for development or test environments
          if (ENV.nodeEnv !== 'production') return;

          const currentBundleName = getCurrentMainBundleName();
          try {
            const response = await axios.get(`/static/js/hash.txt?dt=${new Date().valueOf()}`, {
              baseURL: window.location.origin,
              responseType: 'text',
            });
            // trim to ensure whitespace, control characters,
            // etc. from txt response are eliminated:
            const newHash = response.data.trim();
            // does this look like the main bundle chunk?
            const newHashIsMainChunk = newHash.match(/^main\..*\.chunk\.js$/);
            const appVersionOutdated = newHashIsMainChunk
              ? currentBundleName.trim() !== newHash
              : false;
            globalDispatch({
              type: ActionType.SET_APP_VERSION_OUTDATED,
              payload: appVersionOutdated,
            });
          } catch (error) {
            captureException(error);
          }
        },
        // debounce wait of 3 minutes
        1000 * 60 * 3,
        // execute debounced call immediately so new value is available
        // in the store immediately after user action which called this
        {leading: true, trailing: false},
      ),
    [globalDispatch],
  );
}

export function useFetchNextBestActionDashboard() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(async () => {
    try {
      const response = await api.NextBestAction.getNextBestActionDashboard();
      globalDispatch({
        type: ActionType.SET_NEXT_BEST_ACTION,
        payload: {dashboard: response.data},
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useFetchNextBestActionSignupFlow() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(async () => {
    try {
      const response = await api.NextBestAction.getNextBestActionSignup();
      globalDispatch({
        type: ActionType.SET_NEXT_BEST_ACTION,
        payload: {signup: response.data},
      });
    } catch (error) {}
  }, [globalDispatch]);
}

export function useSetGSCurrentTheme() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(
    async themeId => {
      try {
        globalDispatch({
          type: ActionType.SET_CURRENT_THEME,
          payload: themeId,
        });
      } catch (error) {}
    },
    [globalDispatch],
  );
}
