/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {useGetFetchState} from './useFetchState.hook';

/**
 * A callback to get whether or not a fetch is loading by the given fetch id
 */
export function useGetIsFetchLoading() {
  const getFetchState = useGetFetchState();
  return React.useCallback((fetchId?: string) => getFetchState(fetchId).isLoading, [getFetchState]);
}

/**
 * Whether or not a fetch is loading by the given fetch id
 */
export function useIsFetchLoading(fetchId: string) {
  const isFetchLoading = useGetIsFetchLoading();
  return React.useMemo(() => isFetchLoading(fetchId), [fetchId, isFetchLoading]);
}
