/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import styled, {css} from 'styled-components';

export const LiteProgressBarBrandBarHeaderWrapper = styled.div`
  ${({theme}) => css`
    background: ${theme.content.background};
    text-align: center;
    align-items: center;
    height: 48px;
    margin: 0 auto;
    max-width: 800px;
    padding: 12px 12px 0px 12px;
    display: block;
  `}
`;

export const FloatingContainerProgressBarHeaderWrapper = styled.div`
  background: none;
  margin-top: -2px;
  margin-bottom: 40px;

  @media (min-width: 576px) {
    margin-top: -18px;
    margin-bottom: 55px;
  }
`;

export const FloatingContainerProgressBarWrapper = styled.div`
  margin: 17px -25px 5px -25px;

  @media (min-width: 576px) {
    margin: 17px -56px 5px -56px;
  }
`;

export const FullWidthProgressBarWrapper = styled.div`
  margin: 0 -12px;
`;

export const ProgressBarTrack = styled.div`
  ${({theme}) => css`
    background: ${theme.color.primaryContrast};
    height: 4px;
    position: relative;

    :after {
      background: ${theme.color.primaryA100};
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `}
`;

export const ProgressBarFill = styled.div<{$width: number}>`
  ${({theme, $width}) => css`
    background: ${theme.color.primaryContrast};
    border-radius: ${$width < 100 ? '0 9999px 9999px 0' : '0'};
    height: 100%;
    position: relative;
    transition: width 250ms;
    width: ${$width}%;

    :after {
      background: ${theme.color.primaryA900};
      border-radius: ${$width < 100 ? '0 9999px 9999px 0' : '0'};
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `}
`;

/**
 * progress percent between 0 and 1
 */
export type Percent = number;
export interface ProgressBarProps {
  fillPercent: Percent;
  frontWeighted?: boolean;
  minFill?: Percent;
}

export const ProgressBar: React.FC<ProgressBarProps & React.HTMLAttributes<HTMLDivElement>> = ({
  fillPercent,
  frontWeighted = true,
  minFill = 0,
  ...props
}) => {
  // ensure percent is always a number between 0 and 1
  const safePercent = fillPercent <= 1 ? Math.max(0, fillPercent) : Math.min(1, fillPercent / 100);

  // exponential function is (a^x - 1) / (a - 1)
  // where x = pct of progress
  const a = 0.075; // base of exponential function
  const frontWeightedFillPercent = (Math.pow(a, safePercent) - 1) / (a - 1);

  const rawPercent =
    safePercent === 0 || safePercent === 1
      ? safePercent
      : frontWeighted
      ? frontWeightedFillPercent
      : safePercent;

  const renderPercent = rawPercent * (1 - minFill) + minFill;

  return (
    <ProgressBarTrack role="progressbar" aria-valuenow={Math.floor(renderPercent)} {...props}>
      <ProgressBarFill $width={renderPercent * 100} />
    </ProgressBarTrack>
  );
};

export const FullWidthProgressBar = styled(ProgressBar)`
  height: 4px;
  max-height: 4px;
  display: block;
`;
