/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

/**
 * Handles click events on links. Defers following the link until after
 * callback is executed, if link destination is external URL
 * @param  {document#event:click} event click event
 * @param {function} callback function to await before following link
 */
export async function awaitCallbackOnExternalLinkClick(
  event: React.MouseEvent<HTMLElement>,
  callback: () => any,
) {
  if (event.currentTarget) {
    const linkTarget = event.currentTarget.getAttribute('href');
    const isExternalLink = !!linkTarget && linkTarget.startsWith('http');

    if (isExternalLink) {
      event.preventDefault();
      await callback();
      window.location.href = linkTarget;
      return;
    }
  }
  callback();
}
