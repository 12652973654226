/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const NRG_TEST_ENV: EnvConfig = {
  ...(require('./nrg.development.env').NRG_DEVELOPMENT_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.TEST,
  name: 'Nrg Test',
  backendBaseUrl: `http://${window.location.host.replace(':3000', ':6543')}`,
};
