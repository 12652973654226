/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */

/**
 * Shared API types
 */

/** Flashed message levels */
export enum FlashedMessageLevel {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
/** Flashed messages */
export type FlashedMessages = Record<FlashedMessageLevel, string[]>;
