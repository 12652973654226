/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace User {
  /**********
   * Interactions
   **********/

  /**
   * Get signup step data
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function ackHardwareDR<TResponse = AckHardwareDRStatusResponse, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/user_ack_hardware_dr`, config);
  }

  /**********
   * Types
   **********/
  /**
   * All possible user tiers allowed in the front end.
   * This dictionary determines which tiers are considered valid in displayable in the front end
   *
   * ON-7310: Bronze should only appear for Origin Smart meter upgrade users
   */
  export enum Tier {
    CARBON = 'Carbon',
    BRONZE = 'Bronze',
    SILVER = 'Silver',
    GOLD = 'Gold',
    PLATINUM = 'Platinum',
    DIAMOND = 'Diamond',
  }

  /**
   *
   */
  export interface TierData {
    name: Tier;
    hurdle: number;
    multiplier: number;
  }

  /**
   *
   */
  export interface OverallPerformance {
    longestStreak: number;
    qualifyingEvents: number;
    totalKwhReduced: number;
  }

  /**
   *
   */
  export interface AckHardwareDRStatusResponse {
    status: number;
    msg: string;
  }
}
