/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function ExCircle({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.747 9.41506 20.7188 6.93684 18.891 5.10901C17.0632 3.28118 14.5849 2.25299 12 2.25ZM15.5303 14.4697C15.6 14.5393 15.6554 14.622 15.6932 14.713C15.731 14.804 15.7504 14.9016 15.7505 15.0002C15.7505 15.0987 15.7312 15.1963 15.6935 15.2874C15.6558 15.3784 15.6005 15.4612 15.5308 15.5308C15.4612 15.6005 15.3784 15.6558 15.2874 15.6935C15.1963 15.7312 15.0987 15.7505 15.0002 15.7505C14.9016 15.7504 14.8041 15.731 14.713 15.6932C14.622 15.6554 14.5393 15.6 14.4697 15.5303L12 13.0605L9.53028 15.5303C9.38959 15.6707 9.19892 15.7495 9.00017 15.7494C8.80142 15.7492 8.61083 15.6702 8.47029 15.5297C8.32976 15.3892 8.25075 15.1986 8.25065 14.9998C8.25054 14.8011 8.32934 14.6104 8.46973 14.4697L10.9395 12L8.46973 9.53027C8.32934 9.38958 8.25054 9.19892 8.25065 9.00016C8.25075 8.80141 8.32976 8.61083 8.47029 8.47029C8.61083 8.32975 8.80142 8.25075 9.00017 8.25064C9.19892 8.25054 9.38959 8.32934 9.53028 8.46973L12 10.9395L14.4697 8.46973C14.6104 8.32934 14.8011 8.25054 14.9998 8.25064C15.1986 8.25075 15.3892 8.32975 15.5297 8.47029C15.6703 8.61083 15.7493 8.80141 15.7494 9.00016C15.7495 9.19892 15.6707 9.38958 15.5303 9.53027L13.0606 12L15.5303 14.4697Z"
        fill="currentColor"
      />
    </svg>
  );
}
