/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Information({className}) {
  return (
    <svg
      {...{className}}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M7.5 15C11.6456 15 15 11.6453 15 7.5C15 3.3544 11.6453 -2.93277e-07 7.5 -6.55671e-07C3.35441 -1.01809e-06 1.01806e-06 3.3547 6.55671e-07 7.5C2.93251e-07 11.6456 3.3547 15 7.5 15ZM7.5 1.17187C10.9978 1.17187 13.8281 4.00239 13.8281 7.5C13.8281 10.9978 10.9976 13.8281 7.5 13.8281C4.00216 13.8281 1.17188 10.9976 1.17188 7.5C1.17188 4.00216 4.00239 1.17187 7.5 1.17187Z"
          fill="currentColor"
        />
        <path
          d="M7.5 11.2244C7.82361 11.2244 8.08594 10.962 8.08594 10.6384L8.08594 6.86519C8.08594 6.54158 7.82361 6.27926 7.5 6.27926C7.17639 6.27926 6.91406 6.54158 6.91406 6.86519L6.91406 10.6384C6.91406 10.962 7.17639 11.2244 7.5 11.2244Z"
          fill="currentColor"
        />
        <path
          d="M7.5 3.97974C7.06313 3.97974 6.70898 4.33389 6.70898 4.77075C6.70898 5.20762 7.06313 5.56177 7.5 5.56177C7.93687 5.56177 8.29102 5.20762 8.29102 4.77075C8.29102 4.33389 7.93687 3.97974 7.5 3.97974Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M15 15L0 15L1.31134e-06 -1.31134e-06L15 0L15 15Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
