/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import Carat from 'components/shared/svgs/Carat';
import {EventIconWrapper, EventIconTitle} from 'components/Events/sharedStyled';
import {WithComponentProps} from 'types';
import {useFeature} from 'hooks/useFeature';
import api from 'api';

type StyledListItemProps = Pick<ListItemProps, 'reversed'> & {$showCardStyle: boolean} & Pick<
    Parameters<typeof Link>[0],
    'to'
  >;

const StyledListItem = styled.div<StyledListItemProps>`
  ${({theme, reversed, $showCardStyle, to}) => css`
    align-items: center;
    background-color: ${reversed ? theme.color.primary : theme.content.background};
    color: ${reversed ? theme.color.primaryContrast : 'inherit'};
    cursor: ${!!to ? 'pointer' : 'auto'};
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 12px 24px;
    min-height: 72px;
    text-decoration: none;
    user-select: none;

    ${() => {
      if ($showCardStyle) {
        return css`
          border-radius: ${theme.standard.borderRadiusLg};
          box-shadow: ${theme.standard.boxShadowLg};
          margin: 4px 16px;
        `;
      } else {
        return css`
          border-bottom: 2px solid ${theme.color.greyA100};
        `;
      }
    }};

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: ${reversed ? theme.color.primaryContrast : 'inherit'};
    }

    &:last-child {
      border: none;
    }
  `}
`;

const CaratWrapper = styled.span`
  align-self: center;
  height: 19px;
  margin-left: 15px;
`;

const CaratBackWrapper = styled(CaratWrapper)`
  margin: 0;
  padding-left: 20px;
  transform: rotate(180deg);
`;

const TitleAndSubtitle = styled.span`
  flex: 1;
  min-width: 0;
  padding-left: 12px;
  text-align: left;
`;

const Title = styled.div<Pick<ListItemProps, 'reversed'>>`
  ${({reversed}) => css`
    font-size: 13px;
    font-weight: ${reversed ? '900' : '700'};
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Subtitle = styled.div<Pick<ListItemProps, 'reversed'>>`
  ${({theme, reversed}) => css`
    font-size: 11px;
    color: ${reversed ? theme.color.primaryContrast : 'inherit'};
    font-weight: ${reversed ? '500' : '300'};
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export interface ListItemProps {
  eventTitle?: string;
  reversed?: boolean;
  title?: JSX.Element | null | string;
  subtitle?: string;
  icon?: JSX.Element | null;
  showLinkArrow?: boolean;
  goBack?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  bablicExclude?: boolean;
}

export default function ListItem({
  eventTitle,
  reversed,
  title,
  subtitle,
  icon,
  onClick,
  to,
  showLinkArrow = true,
  goBack,
  children,
  className,
  bablicExclude = false,
}: WithComponentProps<typeof StyledListItem | typeof Link, ListItemProps>) {
  const showCardStyle = useFeature(api.Features.Tree.Presentational.ListItem.CardStyleDesign);

  const caratForward = (
    <CaratWrapper>
      <Carat />
    </CaratWrapper>
  );

  const caratBack = (
    <CaratBackWrapper>
      <Carat />
    </CaratBackWrapper>
  );

  return (
    <StyledListItem
      {...{to, reversed, className, onClick, $showCardStyle: showCardStyle}}
      as={!!to ? Link : undefined}
      role={`listitem ${!!to ? 'link' : ''}`}
      data-bablic-exclude={bablicExclude}
    >
      {goBack ? caratBack : null}
      {icon || eventTitle ? (
        <EventIconWrapper>
          {icon}
          <EventIconTitle>{eventTitle}</EventIconTitle>
        </EventIconWrapper>
      ) : null}
      {title || subtitle ? (
        <TitleAndSubtitle>
          <Title {...{reversed}}>{title}</Title>
          {subtitle ? <Subtitle {...{reversed}}>{subtitle}</Subtitle> : null}
        </TitleAndSubtitle>
      ) : null}
      {children}
      {!goBack && !!to ? showLinkArrow && caratForward : null}
    </StyledListItem>
  );
}
