/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Gear({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3519 9.39183L19.2568 9.35792L18.8852 8.47099L18.9255 8.37986C20.0511 5.82702 19.9735 5.75087 19.7868 5.56579L18.2854 4.10107C18.224 4.04017 18.1162 3.99734 18.0233 3.99734C17.9388 3.99734 17.6829 3.99734 15.4654 5.00169L15.3765 5.0416L14.4616 4.67318L14.4249 4.5821C13.3821 2 13.2753 2 13.0158 2H10.8954C10.6369 2 10.5191 2 9.55214 4.58659L9.51574 4.68075L8.60561 5.05177L8.51858 5.01559C7.01763 4.3828 6.14125 4.06192 5.91279 4.06192C5.82009 4.06192 5.71192 4.10351 5.65027 4.16424L4.14742 5.63318C3.95678 5.82123 3.8777 5.89998 5.06226 8.39954L5.1058 8.49225L4.73365 9.3782L4.64257 9.41341C2 10.4346 2 10.5342 2 10.8002V12.878C2 13.1447 2 13.2555 4.64781 14.2044L4.74246 14.2378L5.11467 15.1212L5.0747 15.2114C3.9491 17.7655 4.02049 17.8346 4.21211 18.0252L5.71128 19.4919C5.77412 19.5523 5.88289 19.5955 5.97564 19.5955C6.05958 19.5955 6.31486 19.5955 8.53384 18.5916L8.6227 18.55L9.53818 18.9203L9.57377 19.0118C10.6172 21.5931 10.7244 21.5931 10.9841 21.5931H13.1051C13.3714 21.5931 13.4819 21.5931 14.4496 19.0048L14.4857 18.9105L15.3973 18.542L15.484 18.5774C16.9837 19.2119 17.8597 19.5322 18.0867 19.5322C18.1786 19.5322 18.2877 19.4919 18.3504 19.4298L19.8557 17.9577C20.045 17.7686 20.1239 17.6914 18.9373 15.1942L18.8931 15.1004L19.2646 14.2183L19.3533 14.1835C22.0001 13.1569 22.0001 13.0569 22.0001 12.7906V10.714C22.0001 10.4473 22.0001 10.3372 19.3519 9.39183ZM12 15.1664C10.105 15.1664 8.56337 13.6542 8.56337 11.7969C8.56337 9.93955 10.105 8.4294 12 8.4294C13.8942 8.4294 15.4356 9.93987 15.4356 11.7969C15.4356 13.6539 13.8943 15.1664 12 15.1664Z"
        fill="currentColor"
      />
    </svg>
  );
}
