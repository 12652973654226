import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';
import {ENV} from '../config/globals';

export namespace Offers {
  // TODO: type this fully, for now stubbing this out
  /**
   * Get the user's current promo state from the server
   * For some reason, this is under offers.userDeviceOffer in the store
   * @param config Optional request config
   * @returns Promise of UserPromoState
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUserPromoState<TResponse = UserPromoState, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/offers`, config);
  }

  /**
   * Get the promo state for the given promo id.
   * This endpoint is ADMIN ONLY and to be used only for Promo QA
   * @param promoId The id of the promo state data to get
   * @param config Optional request config
   * @returns Promise of UserPromoState
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUserPromoStateByPromoId<TResponse = UserPromoState, TConfigData = never>(
    promoId: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/offers/${promoId}`, config);
  }

  /**
   * Request to reserve a promo option - will only work if eligible and not already reserved
   * @param promoName promo_name
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function reservePromoOption<TResponse = ReservePromoOptionData, TConfigData = never>(
    promoName: string,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/device_offer/reserve/${promoName}`, undefined, config);
  }

  /**
   * Set a selected promo option choice
   * @param promoName promo_name
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function setPromoOfferOption<
    TResponse = Partial<Pick<UserPromoState, 'selected_pro_id'>>,
    TConfigData = ['pro_id', string][]
  >(
    promoName: string,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/device_offer/set_option_choice/${promoName}`, data, config);
  }

  /**
   * Set the offer terms agreement acceptance state
   * @param agreementName the name of the agreement
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function setPromoOfferTermsAgreement<
    TResponse = never,
    TConfigData = ['accepted', 1 | 0][]
  >(
    agreementName: string,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/device_offer/agreement/${agreementName}`, data, config);
  }

  /**********
   * Types
   **********/

  /* eslint-disable camelcase */
  export interface UserPromoState {
    advertised_promo: string | null;
    nest_rhr_terms_accepted: boolean;
    offer_content_version: string;
    promo_options: PromoOption[];
    promotion_name: string | null;
    promotion_status: DevicePromotionStatus | null;
    requires_payment_info: boolean;
    requires_shipping_address: boolean;
    selected_pro_id: number | null;
    reserved_pro_id: number | null;
    reserved_date: string | null;
    is_byod: boolean;
    byod_connection_config_id: number | null;
    includes_clawback: boolean;
    selected_device_rewards: PromoReward[];
    selected_non_device_rewards: PromoReward[];
    selected_rewards_by_type: {
      [k: string]: number;
    };
    enabled?: boolean;
  }

  export const enum DevicePromotionStatus {
    UNCLAIMED = 'unclaimed',
    RESERVED = 'reserved',
    PURCHASED = 'purchased',
    CONNECTED = 'connected',
  }

  export interface PromoOption {
    pro_id: number;
    promo_id: number;
    user_facing_name: string | null;
    option_num: number;
    /** is this option the default for this promo? */
    default_option: boolean;
    option_visible: boolean;
    signup_header: string | null;
    signup_intro: string | null;
    signup_image_url: string | null;
    signup_cta_label: string | null;
    signup_fine_print: string | null;
    signup_testimonials_name: string | null;
    signup_footer_title: string | null;
    signup_footer_connect_label: string | null;
    signup_footer_skip_label: string | null;
    dashboard_header: string | null;
    dashboard_body: string | null;
    dashboard_cta_url: string | null;
    dashboard_cta_label: string | null;
    dashboard_fine_print: string | null;
    terms_and_conditions_mini: string | null;
    terms_and_conditions_url: string | null;
    signup_banner_text: string | null;
    rewards: PromoReward[];
    devices_total_value: number;
    option_total_value: number;
    option_total_price: number;
  }

  /**  */
  export interface ReservePromoOptionData {
    success: boolean;
  }

  export type PromoReward = {
    cash_reward_amount?: number | null;
    clawback_amount?: number | null;
    clawback_deadline_days?: number | null;
    device_reward_variant_id?: number | null;
    devices_value?: number | null;
    gift_card_reward_dollars?: number | null;
    reward_total_value: number;
    reward_total_price: number;
    has_clawback?: boolean | null;
    promo_reward_id?: number | null;
    requires_shipping_address?: boolean | null;
    reward_quantity?: number | null;
    reward_type?: string | null;
    spa_device_type?: string | null;
    user_facing_name?: string | null;
  } & Record<string | number, unknown>;

  export enum ShopifyDeviceVariant {
    NestThermostatSnow = 'NestThermostatSnow',
  }

  export const ShopifyDeviceVariantIdMap: Record<ShopifyDeviceVariant, number> = {
    [ShopifyDeviceVariant.NestThermostatSnow]: 32567979278398,
  };

  export const ClaimOfferRedirectUri = `${ENV.backendBaseUrl}/track/redirect/claim_promo_offer`;
}
