/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {FloatingPageContainer, PageContainer, SlimPaddedContentContainer} from '../containers';
import {Outlet} from 'react-router-dom';
import FlashMessage from '../FlashMessage';
import styled from 'styled-components';

export type FloatingPageLayoutProps = React.HTMLAttributes<HTMLDivElement> & {
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
};

export const FloatingPageLayout: React.FC<FloatingPageLayoutProps> = ({
  topContent,
  bottomContent,
  children,
  ...props
}) => {
  return (
    <PageContainer>
      <FloatingPageContainer {...props}>
        {topContent}
        <SlimPaddedContentContainer>
          <Outlet />
          {children}
        </SlimPaddedContentContainer>
        {bottomContent}
      </FloatingPageContainer>
      <StyledFlashMessage />
    </PageContainer>
  );
};

const StyledFlashMessage = styled(FlashMessage)`
  position: fixed;
`;
