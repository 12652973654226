/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {TopBrandBar, TopBrandBarProps} from '../navigation';
import {BrandLogo} from '../BrandLogo';
import {useGlobalState} from '../../../store';

export type AppTopBrandBarProps = Omit<TopBrandBarProps, 'defaultContent'>;

export const AppTopBrandBar = React.forwardRef<HTMLElement, AppTopBrandBarProps>(
  ({children, progressBar, leftContent, rightContent, lowerContent, isCompact, ...props}, ref) => {
    const globalState = useGlobalState();

    return (
      <TopBrandBar
        ref={ref}
        defaultContent={globalState.brandBarDisplay.mainContent ?? <BrandLogo />}
        progressBar={globalState.brandBarDisplay.progressBar ?? progressBar}
        leftContent={globalState.brandBarDisplay.leftContent ?? leftContent}
        rightContent={globalState.brandBarDisplay.rightContent ?? rightContent}
        lowerContent={globalState.brandBarDisplay.lowerContent ?? lowerContent}
        isCompact={globalState.brandBarDisplay.compact ?? isCompact}
        {...props}
      >
        {children}
      </TopBrandBar>
    );
  },
);
