/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace Vacation {
  /**
   * Creates or extends a vacation
   * @param endDttm End date of the vacation or indefinite
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function start<TResponse = VacationResponse, TConfigData = VacationConfig>(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post('/api/v2/vacation/start', data, config);
  }

  /**
   * Ends the user's current vacation
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function stop<TResponse = VacationResponse, TRequestConfig = never>(
    config?: TRequestConfig,
  ): Promise<AxiosResponse<Expand<TResponse>, TRequestConfig>> {
    return await axios.post('/api/v2/vacation/stop', config);
  }

  export type VacationEndDttm = string;
  export interface VacationConfig {
    endDttm: VacationEndDttm;
  }

  export interface VacationResponse {
    message: string;
  }
}
