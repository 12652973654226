/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useEffect, useRef} from 'react';

// Adapted from Dan Abramov's post regarding setInterval & React hooks:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function useInterval(callback, delay, expiry = 0) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const startTime = new Date().getTime();
      let id = setInterval(() => {
        const currentTime = new Date().getTime();
        if (expiry !== 0 && currentTime - startTime > expiry) {
          clearInterval(id);
          return;
        }
        tick();
      }, delay);
      return () => clearInterval(id);
    }
  }, [delay, expiry]);
}
