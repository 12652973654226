/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {useGlobalState} from '../../store';
import {DateTime} from 'luxon';
import {FetchState} from './interaction.types';

/**
 * Returns a callback to get the "Fetch State" by a given fetch id.
 * Includes stateful data about the particular set of fetch instances associated with the fetch id
 * such as last-initiated, last-updated, loading state, a reference to the individual instances, etc.
 */
export function useGetFetchState() {
  const globalState = useGlobalState();

  const getFetchState: (fetchId?: string) => FetchState = React.useCallback(
    fetchId => {
      const instances = globalState.fetchInstances.filter(i => i.fetchId === fetchId);

      return {
        fetchId,
        lastInitiated: instances
          .map(i => i.initiated)
          .sort((_a, _b) => {
            const a = _a ? DateTime.fromJSDate(_a) : 0;
            const b = _b ? DateTime.fromJSDate(_b) : 0;
            return a < b ? 1 : a > b ? -1 : 0;
          })?.[0],
        lastUpdated: instances
          .filter(i => !!i.completed && !i.abortController.signal.aborted)
          .map(i => i.completed)
          .sort((_a, _b) => {
            const a = _a ? DateTime.fromJSDate(_a) : 0;
            const b = _b ? DateTime.fromJSDate(_b) : 0;
            return a < b ? 1 : a > b ? -1 : 0;
          })?.[0],
        isLoading: instances.some(i => !i.abortController.signal.aborted && !i.completed),
        abortAll: () => {
          instances.filter(i => !i.completed).forEach(i => i.abortController.abort());
        },
        instances,
      };
    },
    [globalState.fetchInstances],
  );

  return getFetchState;
}

/**
 * Returns the "Fetch State" by a given fetch id.
 * Includes stateful data about the particular set of fetch instances associated with the fetch id
 * such as last-initiated, last-updated, loading state, a reference to the individual instances, etc.
 */
export function useFetchState(fetchId?: string) {
  const getFetchState = useGetFetchState();
  return React.useMemo(() => getFetchState(fetchId), [fetchId, getFetchState]);
}
