/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Tools({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.35498 40.355C4.30148 41.3507 2.64073 41.3052 1.64323 40.2517C0.68598 39.2402 0.68598 37.6565 1.64323 36.645L13.1425 25.1422L16.8542 28.8557L5.35498 40.355Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2892 23.2854L18.7092 30.7107"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.8425 7.17676L15.001 26.999"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.1237 7.62821C37.5462 8.40171 36.449 8.56096 35.6737 7.98346C35.6072 7.93271 35.5425 7.87846 35.4847 7.81896L34.0637 6.39971C33.3795 5.71721 33.3777 4.60946 34.0602 3.92521C34.1337 3.85171 34.2142 3.78346 34.3 3.72396L38.178 1.03071C38.5262 0.789211 38.997 0.831211 39.2962 1.13046L40.8712 2.70546C41.1792 3.01346 41.2125 3.50171 40.95 3.84996L38.1237 7.62821Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2755 16.7752L14.2555 10.7552C15.8462 7.23244 14.28 3.08494 10.7555 1.49419C9.85075 1.08644 8.869 0.874695 7.875 0.874695C7.392 0.874695 6.909 0.923695 6.4365 1.02169C5.964 1.12144 5.66125 1.58519 5.75925 2.05769C5.79425 2.22394 5.8765 2.37619 5.99725 2.49694L8.4945 4.99419C8.65725 5.15869 8.75 5.38094 8.75 5.61194V7.87469C8.75 8.35769 8.358 8.74969 7.875 8.74969H5.61225C5.38125 8.74969 5.159 8.65694 4.9945 8.49419L2.49725 5.99694C2.156 5.65569 1.60125 5.65569 1.26 5.99694C1.13925 6.11769 1.057 6.26994 1.022 6.43619C0.924 6.90869 0.875 7.39169 0.875 7.87469C0.875 11.7404 4.00925 14.8747 7.875 14.8747C8.869 14.8747 9.85075 14.6629 10.7555 14.2552L16.7755 20.2752"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2245 21.7245L31.2445 27.7445C34.7672 26.1537 38.9147 27.72 40.5055 31.2445C40.915 32.1492 41.125 33.1327 41.125 34.125C41.125 34.608 41.076 35.091 40.978 35.5635C40.8782 36.036 40.4145 36.3387 39.942 36.2407C39.7757 36.2057 39.6235 36.1235 39.5027 36.0027L37.0055 33.5055C36.841 33.3427 36.6187 33.25 36.3877 33.25H34.125C33.642 33.25 33.25 33.642 33.25 34.125V36.3877C33.25 36.6187 33.3427 36.841 33.5055 37.0055L36.0027 39.5027C36.344 39.844 36.344 40.3987 36.0027 40.74C35.882 40.8607 35.7297 40.943 35.5635 40.978C35.091 41.076 34.608 41.125 34.125 41.125C30.2592 41.125 27.125 37.9907 27.125 34.125C27.125 33.131 27.3367 32.1492 27.7445 31.2445L21.7245 25.2245"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="42" height="42" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
