/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function MailLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0005 5H20.0005C21.1008 5 22 5.7875 22 6.75V17.25C22 18.2125 21.1008 19 20.0005 19H4.0005C2.90023 19 2 18.2125 2 17.25V6.75C2 5.7875 2.90023 5 4.0005 5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6141 6.35687L12.6427 11.7395L13.4144 13.0258L22.3859 7.64313L21.6141 6.35687ZM11.3564 11.7395L2.38589 6.35689L1.61411 7.64311L10.5846 13.0257L11.3564 11.7395ZM12.6427 11.7395C12.2468 11.977 11.7523 11.977 11.3564 11.7395L10.5846 13.0257C11.4555 13.5482 12.5435 13.5483 13.4144 13.0258L12.6427 11.7395Z"
        fill="currentColor"
      />
    </svg>
  );
}
