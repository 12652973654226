/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const ReferFill: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2498 8.75006C25.2498 10.409 24.2399 11.832 22.8014 12.438C23.9444 12.719 24.9998 13.3077 25.8459 14.1539C27.0649 15.3729 27.7498 17.0262 27.7498 18.7501C27.7498 19.1643 27.414 19.5001 26.9998 19.5001C26.9788 19.5001 26.9581 19.4992 26.9376 19.4975C26.9169 19.4992 26.8959 19.5001 26.8748 19.5001H26.2498C26.0509 19.5001 25.8601 19.4211 25.7195 19.2804L22.4872 16.0482C21.8038 15.3648 20.6958 15.3648 20.0124 16.0482L16.7801 19.2804C16.6395 19.4211 16.4487 19.5001 16.2498 19.5001H15.6248C15.6036 19.5001 15.5827 19.4992 15.562 19.4975C15.5415 19.4992 15.5207 19.5001 15.4998 19.5001C15.0855 19.5001 14.7498 19.1643 14.7498 18.7501C14.7498 17.0262 15.4346 15.3729 16.6536 14.1539C17.4997 13.3077 18.5551 12.719 19.6981 12.438C18.2596 11.832 17.2498 10.409 17.2498 8.75006C17.2498 6.54092 19.0406 4.75006 21.2498 4.75006C23.4589 4.75006 25.2498 6.54092 25.2498 8.75006Z"
        fill="currentColor"
      />
      <path
        d="M10.3014 18.688C11.7399 18.082 12.7498 16.659 12.7498 15.0001C12.7498 12.7909 10.9589 11.0001 8.74976 11.0001C6.54062 11.0001 4.74976 12.7909 4.74976 15.0001C4.74976 16.659 5.7596 18.082 7.19809 18.688C6.05508 18.969 4.99969 19.5577 4.15356 20.4039C2.93458 21.6229 2.24976 23.2762 2.24976 25.0001C2.24976 25.3979 2.40779 25.7794 2.6891 26.0607C2.9704 26.342 3.35193 26.5001 3.74976 26.5001L13.7498 26.5001L13.7736 26.4999H16.5831C19.5747 26.4999 21.9998 24.0748 21.9998 21.0832V19.3105L23.2195 20.5302C23.5124 20.8231 23.9872 20.8231 24.2801 20.5302C24.573 20.2373 24.573 19.7624 24.2801 19.4695L21.7801 16.9695C21.7082 16.8976 21.6253 16.8434 21.5369 16.8068C21.4485 16.7701 21.3515 16.7499 21.2498 16.7499C21.1481 16.7499 21.0511 16.7701 20.9627 16.8068C20.8743 16.8434 20.7914 16.8976 20.7195 16.9695L18.2195 19.4695C17.9266 19.7624 17.9266 20.2373 18.2195 20.5302C18.5124 20.8231 18.9872 20.8231 19.2801 20.5302L20.4998 19.3105V21.0832C20.4998 23.2463 18.7463 24.9999 16.5831 24.9999H15.2498C15.2497 23.276 14.5649 21.6228 13.346 20.4039C12.4998 19.5577 11.4444 18.969 10.3014 18.688Z"
        fill="currentColor"
      />
    </svg>
  );
};
