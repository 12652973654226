/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Heart({className}) {
  return (
    <svg
      {...{className}}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1622 2.76815C21.6019 2.20759 20.9367 1.76292 20.2045 1.45954C19.4723 1.15615 18.6875 1 17.895 1C17.1024 1 16.3176 1.15615 15.5854 1.45954C14.8532 1.76292 14.188 2.20759 13.6277 2.76815L12.4649 3.93095L11.3021 2.76815C10.1703 1.6364 8.63536 1.00059 7.03482 1.00059C5.43429 1.00059 3.89931 1.6364 2.76756 2.76815C1.63581 3.89989 1 5.43487 1 7.03541C1 8.63594 1.63581 10.1709 2.76756 11.3027L3.93036 12.4655L12.4649 21L20.9994 12.4655L22.1622 11.3027C22.7228 10.7424 23.1674 10.0771 23.4708 9.34495C23.7742 8.61276 23.9304 7.82796 23.9304 7.03541C23.9304 6.24285 23.7742 5.45806 23.4708 4.72587C23.1674 3.99368 22.7228 3.32844 22.1622 2.76815Z"
        fill="currentColor"
      />
    </svg>
  );
}
