/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Unsupported({className}) {
  return (
    <svg
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7.1" stroke="currentColor" strokeWidth="1.8" />
      <path
        stroke="currentColor"
        strokeWidth="1.684"
        d="M12.385 3.543L3.122 12.806M3.122 3.194L13.227 13.299"
      />
    </svg>
  );
}
