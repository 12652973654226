/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const ReferLine: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 20L21.25 17.5L23.75 20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.75"
        cy="15"
        r="3.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="21.25"
        cy="8.75"
        r="3.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 25.75C14.1642 25.75 14.5 25.4142 14.5 25C14.5 23.475 13.8942 22.0125 12.8159 20.9341C11.7375 19.8558 10.275 19.25 8.75 19.25C7.22501 19.25 5.76247 19.8558 4.68414 20.9341C3.6058 22.0125 3 23.475 3 25C3 25.1989 3.07902 25.3897 3.21967 25.5303C3.36032 25.671 3.55109 25.75 3.75 25.75L8.75 25.75H13.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 18.75C27 17.225 26.3942 15.7625 25.3159 14.6841C24.2375 13.6058 22.775 13 21.25 13C19.725 13 18.2625 13.6058 17.1841 14.6841C16.1058 15.7625 15.5 17.225 15.5 18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 17.5V21.0833C21.25 23.6607 19.1607 25.75 16.5833 25.75V25.75H12.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
