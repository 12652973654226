/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */

import React from 'react';

export default function StopwatchOutline({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20.4717C16.4183 20.4717 20 16.89 20 12.4717C20 8.0534 16.4183 4.47168 12 4.47168C7.58172 4.47168 4 8.0534 4 12.4717C4 16.89 7.58172 20.4717 12 20.4717Z"
        fill="currentColor"
        fillOpacity="0.05"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.4718L14.9091 8.59302"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.81836 2H14.182"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
