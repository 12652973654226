/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const DevicesFill: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8946 8.61391C16.9713 8.94159 16.6827 9.22675 16.3466 9.20909C16.2319 9.20306 16.1163 9.2 16 9.2H9.8C6.75426 9.2 4.19779 11.2948 3.4928 14.1223C3.38246 14.5648 2.79893 14.7355 2.56809 14.3422C1.88923 13.1854 1.5 11.8382 1.5 10.4C1.5 6.09219 4.99218 2.60001 9.3 2.60001C12.9933 2.60001 16.087 5.16687 16.8946 8.61391Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1 10.4C7.00721 10.4 4.5 12.9072 4.5 16C4.5 19.0928 7.0072 21.6 10.1 21.6H18.1C21.1928 21.6 23.7 19.0928 23.7 16C23.7 12.9072 21.1928 10.4 18.1 10.4H10.1ZM12.1 12.6H10.9V15.48H12.1V12.6ZM16.1 12.6H17.3V15.48H16.1V12.6ZM15.3 16.4H12.9V17.6C12.9 18.2627 13.4373 18.8 14.1 18.8C14.7628 18.8 15.3 18.2627 15.3 17.6V16.4Z"
        fill="currentColor"
      />
    </svg>
  );
};
