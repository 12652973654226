/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */

import {DeviceState} from 'store/connections';

export namespace Connections {
  /**********
   * Interactions
   **********/

  //

  /**********
   * Types
   **********/

  /**
   *
   */
  export interface DeviceData extends Record<string | number, any> {
    // TODO: Type this
    currentState: DeviceState;
    deviceId: number;
    deviceConfigId: number;
    connectionId: number;
    deviceHealth?: string;
    deviceHealthUrl?: string;
    deviceName?: string;
    deviceType?: DeviceType;
    displayName?: string;
    errorCount: number;
    errorMsg?: string;
    excludeFromAutoOhms: boolean;
    excludeFromOhmHours: boolean;
    firstOhmHour: boolean;
    orderId?: number;
    realtimePoints: number;
  }

  export interface DeviceConfigData extends Record<string | number, any> {
    canDispatch: boolean;
    canUserControlDispatch: boolean;
    connectionConfigId: number;
    deviceAliases: string;
    deviceCapabilities: string;
    deviceConfigId: number;
    deviceModel: string;
    deviceName: string;
    deviceType: DeviceType;
    realtimePoints: number;
    userControlDispatchDisableUIText: null | string;
  }

  /**
   *
   */
  export enum DeviceType {
    thermostat = 'thermostat',
    plug = 'plug',
    light = 'light',
    default = 'default',
    solar = 'solar',
    ev = 'ev',
    battery = 'battery',
    waterheater = 'waterheater',
    assistant = 'assistant',
    monitor = 'monitor',
    airpurifier = 'airpurifier',
    refrigerator = 'refrigerator',
    windowairconditioner = 'windowairconditioner',
    portableairconditioner = 'portableairconditioner',
    minisplitairconditioner = 'minisplitairconditioner',
    tv = 'tv',
  }

  /**
   *
   */
  export enum PreEventDeviceParticipationState {
    INCLUDED = 'INCLUDED',
    EXCLUDED = 'EXCLUDED',
    ISSUE = 'ISSUE',
  }

  /**
   *
   */
  export enum EventDeviceParticipationState {
    OPT_OUT = 'OPT_OUT',
    ALREADY_OFF = 'ALREADY_OFF',
    TURNED_ON_DURING = 'TURNED_ON_DURING',
    OTHER = 'OTHER',
  }

  /**
   * Advance OhmHour: Status of attempts to turn off a device
   */
  export enum EventDeviceAdvanceState {
    /** We successfully scheduled a turn off *and* on */
    'SCHEDULED' = 'SCHEDULED',
    /** We altered the device state to prep for the event in advance */
    'SHIFTED' = 'SHIFTED',
    /** We could not advance set this device and will continue trying */
    'FAILED' = 'FAILED',
    /** We could not advance set this device and will stop trying */
    'FAILED_GIVEN_UP' = 'FAILED_GIVEN_UP',
    /** We could not advance set this device and will continue trying */
    'NOT_CONNECTED' = 'NOT_CONNECTED',
    /** We could not advance set this device and will stop trying */
    'NOT_CONNECTED_GIVEN_UP' = 'NOT_CONNECTED_GIVEN_UP',
    /** We will not schedule some devices if they are off to start */
    'ALREADY_OFF' = 'ALREADY_OFF',
    /** No advance states */
    'NOT_IMPLEMENTED' = 'NOT_IMPLEMENTED',
    /** Advance is implemented, but we skipped it
     * (usually because advance was run during an existing event) */
    'NOT_TOGGLED' = 'NOT_TOGGLED',
    /** User opted out of ohmhour */
    'OPT_OUT' = 'OPT_OUT',
    /** Device offline and we will continue trying */
    'OFFLINE' = 'OFFLINE',
    /** Device offline and we will stop trying */
    'OFFLINE_GIVEN_UP' = 'OFFLINE_GIVEN_UP',
    /** Device was disconnected at the time of the OhmHour --
     * probably going away in favor of re-trying disconnected devices */
    'DISCONNECTED' = 'DISCONNECTED',
    /** */
    'AUTH_ERROR' = 'AUTH_ERROR',
    /** */
    'AUTH_ERROR_GIVEN_UP' = 'AUTH_ERROR_GIVEN_UP',
  }

  /** Turn off & Turn off history state is the same at the moment */
  export enum EventHistoryState {
    'SCHEDULED' = 'SCHEDULED',
    'DR_EVENT' = 'DR_EVENT',
    'OFF' = 'OFF',
    'DONE' = 'DONE',
    'NOT_TOGGLED' = 'NOT_TOGGLED',
    'ALREADY_ON' = 'ALREADY_ON',
    'ALREADY_OFF' = 'ALREADY_OFF',
    'FAILED' = 'FAILED',
    'SCRAPE_FAILED' = 'SCRAPE_FAILED',
    'FAILED_GIVEN_UP' = 'FAILED_GIVEN_UP',
    'SCRAPE_FAILED_GIVEN_UP' = 'SCRAPE_FAILED_GIVEN_UP',
    'NOT_CONNECTED' = 'NOT_CONNECTED',
    'NOT_CONNECTED_GIVEN_UP' = 'NOT_CONNECTED_GIVEN_UP',
    'OFFLINE' = 'NOT_CONNECTED_GIVEN_UP',
    'OFFLINE_GIVEN_UP' = 'OFFLINE_GIVEN_UP',
    'OPT_OUT' = 'OPT_OUT',
    'NOT_IMPLEMENTED' = 'NOT_IMPLEMENTED',
    'DISCONNECTED' = 'DISCONNECTED',
    'EXTENSION' = 'EXTENSION',
    'AUTH_ERROR' = 'EXTENSION',
    'AUTH_ERROR_GIVEN_UP' = 'AUTH_ERROR_GIVEN_UP',
  }

  export type EventHistoryTurnOnStates = EventHistoryState.ALREADY_ON | EventHistoryState.DONE;
  export type EventHistoryTurnOffStates =
    | EventHistoryState.SCHEDULED
    | EventHistoryState.ALREADY_OFF
    | EventHistoryState.DR_EVENT
    | EventHistoryState.OFF;
}
