/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React, {useCallback} from 'react';
import moment from 'moment';

import api from 'api';
import {ENV} from 'config/globals';
import {useFeature} from 'hooks/useFeature';
import {
  useGetCurrentOauthOutage,
  useGetUserUtilityNeverConnected,
  useGetUtilityConnectionIssue,
} from 'store/selectors';
import {isLongTermNoIntervalData} from 'utils';

export const UTILITY_STATUS_MESSAGES = {
  CONNECTION_PROMPT:
    'To get paid to save energy, you need to connect your utility to OhmConnect. ' +
    "It's safe, secure, and takes 2 minutes!",
  UTILITY_NOT_SUPPORTED:
    'Unfortunately, your utility does not support OhmConnect at this time. ' +
    'You cannot participate in OhmHours and AutoOhms.',
  INELIGIBLE:
    'Unfortunately, you are not eligible for OhmConnect at this time. ' +
    'You cannot participate in OhmHours or AutoOhms.',
  ATTESTATION_NEEDED:
    'Looks like you need to provide information about backup generators. ' +
    'Check your email for more information, or contact us for help.',
  DUPLICATE_ACCOUNT_PLACEHOLDER:
    //  the UI string for this scenario is available from the /api/v2/settings endpoint
    // so use an obviously placeholdery string here which the UI can special case against
    // to pull the correct display string
    'DUPLICATE_OHM_ACCOUNT',
  PENDING_CONNECTION:
    "We're working on connecting your utility to OhmConnect and final approval is pending. " +
    'You are eligible to receive OhmHours and/or AutoOhms.',
  CONFLICTING_DRP:
    "Looks like you're enrolled in a different energy saving Demand Response " +
    "program. You need to disenroll from it before you're able to join OhmConnect.",
  MAYBE_MOVED:
    'Have you moved recently? If yes, click the “I moved” button below to reconnect. ' +
    "If you haven't moved recently, contact us for help.",
  CONNECT_UTILITY: 'Connect your utility account to unlock energy saving events and rewards!',
  ERROR:
    'Hm, looks like something went wrong. Try reconnecting your utility ' +
    'account by clicking the “I moved” button below.',
};

const UTILITY_STATUS_ACTIONS = {
  CONTACT: {
    label: 'Contact Us',
    href: ENV.askShortUrl,
    primary: false,
  },
  DUPLICATE: {
    label: 'Contact Us',
    href: ENV.askShortUrl,
    primary: true,
  },
  DISENROLL: {
    label: 'Contact Us to Disenroll',
    href: ENV.askShortUrl,
    primary: true,
  },
  DISENROLL_TO_EARN: {
    label: 'Disenroll to Earn Rewards',
    href:
      // eslint-disable-next-line max-len
      'https://www.ohmconnect.com/help/disenrolling-from-a-conflicting-demand-response-program-rk2_gdvUu',
    primary: true,
  },
  CONNECT_UTILITY: {
    label: 'Connect my utility',
    href: '/signup/utility?edit=true',
    primary: true,
  },
  CONNECT_UTILITY_NO_LOCATION: {
    label: 'Connect my utility',
    href: '/signup/zip_code?edit=true',
    primary: true,
  },
};

const UTILITY_STATUS_SECONDARY_ACTIONS = {
  GET_HELP: {
    label: 'Get Help',
    href: ENV.helpFormUrl,
  },
};

export const UTILITY_STATUS = {
  PENDING: 'Pending',
  CONNECTED: 'Connected',
  INELIGIBLE: 'Ineligible',
  ISSUE: 'Issue',
};

export function useGetUtilityStatusDisplay() {
  const alwaysShowHelpCTA = useFeature(api.Features.Tree.Presentational.Utility.AlwaysShowHelpCTA);

  return useCallback(
    (state, substate, currentMeterData, daysSinceLastEnrollState) => {
      // returns short status display and detailed explanation
      // @todo with user action, if applicable
      let connectionStatus;
      let message;
      let userAction;
      let secondaryUserAction = alwaysShowHelpCTA
        ? UTILITY_STATUS_SECONDARY_ACTIONS.GET_HELP
        : undefined;
      let iMovedCTALabel;

      switch (state) {
        // User in error State
        case 'pending':
        case 'issue_matching_records':
        case 'error':
          // User in AuthDone ConflictingDRP sub-state
          if (substate === 'ConflictingDRP') {
            connectionStatus = UTILITY_STATUS.CONNECTED;
            message = UTILITY_STATUS_MESSAGES.CONFLICTING_DRP;
            userAction = UTILITY_STATUS_ACTIONS.DISENROLL;
            break;
          } else if (substate === 'Connected') {
            connectionStatus = UTILITY_STATUS.PENDING;
            message = UTILITY_STATUS_MESSAGES.PENDING_CONNECTION;
            break;
          } else if (substate === 'issue_matching_records') {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.ERROR;
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
            break;
          } else if (substate === 'Duplicate') {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.DUPLICATE_ACCOUNT_PLACEHOLDER;
            userAction = UTILITY_STATUS_ACTIONS.DUPLICATE;
            break;
          } else if (substate === 'Rejected') {
            connectionStatus = UTILITY_STATUS.INELIGIBLE;
            message = UTILITY_STATUS_MESSAGES.INELIGIBLE;
            break;
          } else if (substate === 'ISOIneligible') {
            connectionStatus = UTILITY_STATUS.INELIGIBLE;
            message = UTILITY_STATUS_MESSAGES.INELIGIBLE;
            break;
          } else if (isLongTermNoIntervalData(substate, daysSinceLastEnrollState)) {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.MAYBE_MOVED;
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
            break;
          } else {
            connectionStatus = UTILITY_STATUS.CONNECTED;
            if (!currentMeterData) {
              message = UTILITY_STATUS_MESSAGES.ERROR;
              userAction = UTILITY_STATUS_ACTIONS.CONTACT;
            }
            break;
          }

        // User in MarketReady/MarketEnrolled state
        case 'MarketReady':
        case 'MarketEnrolled':
          connectionStatus = UTILITY_STATUS.CONNECTED;
          if (substate === 'ISOReady' || substate === 'ISOLocation') {
            connectionStatus = UTILITY_STATUS.PENDING;
            message = UTILITY_STATUS_MESSAGES.PENDING_CONNECTION;
          } else if (substate === 'LocEndDated') {
            message = UTILITY_STATUS_MESSAGES.ERROR;
          } else if (substate === 'LocDisputed') {
            message = UTILITY_STATUS_MESSAGES.CONFLICTING_DRP;
            userAction = UTILITY_STATUS_ACTIONS.DISENROLL;
          }
          break;

        // User in AuthDone/AuthApproved state
        case 'AuthDone':
        case 'AuthApproved':
          connectionStatus = UTILITY_STATUS.CONNECTED;
          message = UTILITY_STATUS_MESSAGES.PENDING_CONNECTION;

          if (substate === 'Connected') {
            connectionStatus = UTILITY_STATUS.PENDING;
          } else if (substate === 'MissingMappingData') {
            connectionStatus = UTILITY_STATUS.PENDING;
            message =
              UTILITY_STATUS_MESSAGES.ERROR +
              " If this hasn't resolved for 2 weeks, contact us for help.";
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
          } else if (substate === 'Duplicate') {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.DUPLICATE_ACCOUNT_PLACEHOLDER;
            userAction = UTILITY_STATUS_ACTIONS.DUPLICATE;
          } else if (substate === 'Rejected') {
            connectionStatus = UTILITY_STATUS.INELIGIBLE;
            message = UTILITY_STATUS_MESSAGES.INELIGIBLE;
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
          } else if (substate === 'ConflictingDRP') {
            connectionStatus = UTILITY_STATUS.CONNECTED;
            message = UTILITY_STATUS_MESSAGES.CONFLICTING_DRP;
            userAction = UTILITY_STATUS_ACTIONS.DISENROLL_TO_EARN;
            secondaryUserAction = UTILITY_STATUS_ACTIONS.CONTACT;
          } else if (substate === 'MappingDataVerified') {
            connectionStatus = UTILITY_STATUS.PENDING;
          } else if (isLongTermNoIntervalData(substate, daysSinceLastEnrollState)) {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.MAYBE_MOVED;
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
          } else if (substate === 'ISOIneligible') {
            connectionStatus = UTILITY_STATUS.INELIGIBLE;
            message = UTILITY_STATUS_MESSAGES.INELIGIBLE;
          } else if (substate === 'AttestationNeeded') {
            connectionStatus = UTILITY_STATUS.ISSUE;
            message = UTILITY_STATUS_MESSAGES.ATTESTATION_NEEDED;
            userAction = UTILITY_STATUS_ACTIONS.CONTACT;
          }
          break;

        // User in PreValid
        case 'PreValid':
          connectionStatus = UTILITY_STATUS.ISSUE;

          if (substate === 'UtilityNotSupported') {
            connectionStatus = UTILITY_STATUS.INELIGIBLE;
            message = UTILITY_STATUS_MESSAGES.UTILITY_NOT_SUPPORTED;
          } else if (substate === 'NoLocation') {
            message = UTILITY_STATUS_MESSAGES.CONNECT_UTILITY;
            userAction = UTILITY_STATUS_ACTIONS.CONNECT_UTILITY_NO_LOCATION;
          } else if (substate === 'LocationOK') {
            message = UTILITY_STATUS_MESSAGES.CONNECT_UTILITY;
            userAction = UTILITY_STATUS_ACTIONS.CONNECT_UTILITY;
            iMovedCTALabel = 'Update my zip code';
          } else {
            message = UTILITY_STATUS_MESSAGES.ERROR;
          }
          break;

        // User in AuthStart
        case 'AuthStart':
          connectionStatus = UTILITY_STATUS.ISSUE;

          if (['Started', 'Retry', 'Cancelled', 'Revoked', 'Disconnected'].includes(substate)) {
            message = UTILITY_STATUS_MESSAGES.CONNECT_UTILITY;
            userAction = UTILITY_STATUS_ACTIONS.CONNECT_UTILITY;
            iMovedCTALabel = 'Update my zip code';
            if (substate !== 'Started') {
              secondaryUserAction = UTILITY_STATUS_SECONDARY_ACTIONS.GET_HELP;
            }
          } else {
            message = UTILITY_STATUS_MESSAGES.ERROR;
          }
          break;

        // User in Deleted state
        default:
          connectionStatus = UTILITY_STATUS.ISSUE;
          message = UTILITY_STATUS_MESSAGES.CONNECTION_PROMPT;
          userAction = UTILITY_STATUS_ACTIONS.CONTACT;
          secondaryUserAction = null;
          iMovedCTALabel = null;
          break;
      } // switch

      return {connectionStatus, message, userAction, secondaryUserAction, iMovedCTALabel};
    },
    [alwaysShowHelpCTA],
  );
}

export function getOauthOutageMessage(userSettings, currentOauthOutage) {
  return (
    <>
      {userSettings.utility_info.short_name
        ? `${userSettings.utility_info.short_name}’s`
        : 'Your utility’s'}{' '}
      data sharing{' '}
      {currentOauthOutage.outageEndDttm
        ? `will be offline until ${moment(currentOauthOutage.outageEndDttm).format('MMMM D')}`
        : 'is offline'}
      . We’ll let you know when it’s back online so you can share your data and start earning
      rewards with OhmConnect!
    </>
  );
}

export function useShouldShowOauthOutageMessage() {
  const getCurrentOauthOutage = useGetCurrentOauthOutage();
  const getUserUtilityNeverConnected = useGetUserUtilityNeverConnected();
  const getUtilityConnectionIssue = useGetUtilityConnectionIssue();
  const currentOauthOutage = getCurrentOauthOutage();
  const utilityNeverConnected = getUserUtilityNeverConnected();
  const utilityConnectionIssue = getUtilityConnectionIssue();

  return useCallback(() => {
    return currentOauthOutage.inOutage && (utilityNeverConnected || utilityConnectionIssue);
  }, [currentOauthOutage, utilityNeverConnected, utilityConnectionIssue]);
}
