/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const VIVINT_NGROK_ENV: EnvConfig = {
  ...(require('./vivint.development.env').VIVINT_DEVELOPMENT_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.DEVELOPMENT,
  name: 'Vivint NGrok',
  backendBaseUrl: 'https://ohm-lg-dev.ohmconnect.com',
};
