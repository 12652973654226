/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';
import {Wallet} from './wallet.api';

export namespace RewardMarketplace {
  /**********
   * Interactions
   **********/

  /**
   * Get all marketplace rewards
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getAllRewards<TResponse = RewardsMap, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/rewards`, config);
  }
  /**
   * Get a single marketplace reward
   * @param id Id of reward
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getReward<TResponse = Reward, TConfigData = never>(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/reward/${id}`, config);
  }
  /**
   * Get the marketplace wheel daily cash winner ticker entry
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getWheelDailyCashTicker<TResponse = WheelTicker, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/reward/wheel/previous_daily_cash_winner`, config);
  }
  /**
   * Get the marketplace wheel ticker
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getWheelTicker<TResponse = WheelTicker, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/reward/wheel/ticker`, config);
  }
  /**
   * Redeem a marketplace reward
   * @param id Id of reward
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function redeem<
    TResponse = Redemption,
    TConfigData = {expected_price: number; quantity?: number}
  >(
    id: number,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/marketplace/redeem/${id}`, data, config);
  }
  /**
   * Get exported bonus data
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getBonusCSVExport<
    TResponse = unknown, // TODO, figure out this reponse type
    TConfigData = never
  >(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/bonus_export`, config);
  }
  /**
   * Get a wheel spin by id
   * @param id Id of wheel spin
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getWheelSpin<TResponse = WheelSpin, TConfigData = never>(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/marketplace/redeem/wheel/${id}`, config);
  }

  interface WheelSpinConfigData {
    expected_price: number;
  }

  /**
   * Redeem a wheel spin by id
   * @param wheelRewardId Id of wheel reward
   * @param data Data to pass to endpoint, see `WheelSpinConfigData`
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function redeemWheelSpin<TResponse = WheelSpin, TConfigData = WheelSpinConfigData>(
    wheelRewardId: number,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/marketplace/redeem/${wheelRewardId}`, data, config);
  }

  /**
   * Redeem a free wheel spin by id
   * @param id Id of free wheel spin
   * @param data Data to pass to endpoint, see `WheelSpinConfigData`
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function redeemFreeWheelSpin<
    TResponse = WheelSpin,
    TConfigData = WheelSpinConfigData
  >(
    freeSpin: Wallet.FreeSpinToSpend,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/marketplace/redeem/wheel/${freeSpin.rmw_spin_id}`,
      data,
      config,
    );
  }

  /**
   * Redeem a rmw_redeemable_wheel_spin
   * @param id Id of free wheel spin
   * @param data Data to pass to endpoint, see `WheelSpinConfigData`
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function redeemRedeemableWheelSpin<
    TResponse = WheelSpin,
    TConfigData = WheelSpinConfigData
  >(
    freeSpin: Wallet.FreeSpinToSpend,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/marketplace/redeem/wheel/redeemable/${freeSpin.rmw_redeemable_spin_id}`,
      data,
      config,
    );
  }

  /**
   * If a user declines the daily prize, set it expired so someone else can win
   * @param wheelRewardId Id of wheel reward
   * @param data Data to pass to endpoint, see `WheelSpinConfigData`
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function declineDailyPrizeSpin<
    TResponse = WheelSpin,
    TConfigData = WheelSpinConfigData
  >(
    wheelRewardId: number,
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/marketplace/redeem/wheel/give_up_prize/${wheelRewardId}`,
      data,
      config,
    );
  }

  /**********
   * Types
   **********/

  /**
   * Reward
   */
  export interface Reward {
    reward_id: number;
    reward_type: RewardType;
    denomination_type: DenominationType;
    reward_set_name: string | undefined;
    reward_title?: string;
    reward_title_context?: string;
    reward_title_plural?: string;
    reward_subtitle?: string;
    reward_base_price_watts: number;
    dollar_value: number;
    reward_image_url: string;
    reward_description?: string;
    reward_priority?: number;
    product_id: string;
    coupon_config_id: string;
    eligible_start_dttm: string;
    eligible_end_dttm: string;
    display_start_dttm: string;
    display_end_dttm: string;
    discounts: unknown[];
    final_price_watts: number;
    ineligibility_reasons: [];
    is_eligible: boolean;
    shopify_checkout_url?: string;
    tags: unknown[];
    is_nest_reward: boolean;
  }

  /**
   * Map of rewards
   */
  export interface RewardsMap {
    [reward_id: number]: Reward;
  }

  /**
   * Reward type options
   */
  export enum RewardType {
    CASH = 'CASH',
    DEVICE = 'DEVICE',
    DONATION = 'DONATION',
    GIFT_CARD = 'GIFT_CARD',
    PRIZE = 'PRIZE',
    SWAG = 'SWAG',
    WHEEL = 'WHEEL',
    COUPON = 'COUPON',
  }

  /**
   * Reward denomination types. Default: DENOMINATED
   */
  export enum DenominationType {
    DENOMINATED = 'DENOMINATED',
    CUSTOM = 'CUSTOM',
    BALANCE = 'BALANCE',
  }

  /**
   * Wheel ticker
   */
  export interface WheelTicker {
    // TODO
  }

  /**
   * Redemption
   */
  export type Redemption = {
    // TODO
  };

  /**
   * Wheel spin
   */
  export type WheelSpin = {
    // TODO
  };
}
