/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import styled, {css} from 'styled-components';

export const PageContainer = styled.div<{maxContentWidth?: string}>`
  ${({theme, maxContentWidth}) => css`
    display: flex;
    align-self: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    flex: 1;
    max-width: ${maxContentWidth};
    overflow: visible;
  `}
`;
