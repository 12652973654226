/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import useMountEffect from 'hooks/useMountEffect';

import Icon from 'components/shared/Icon';
import {MainLayout} from '../shared/layouts';
import {useSetBrandBarDisplay} from '../../store/brandBarDisplay';

const MaintenanceMessage = styled.section`
  margin: 0 auto;
  max-width: 380px;
  margin: 2rem auto 1rem;
  padding: 2rem;
  text-align: center;

  h1,
  p {
    margin: 16px auto;
  }
`;

export default function Maintenance() {
  const navigate = useNavigate();
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [loading, setLoading] = useState(true);

  useMountEffect(() => {
    async function fetchUserStatus() {
      try {
        await axios.get('/api/v2/user_status');
      } catch (error) {
        if (error.response && error.response.status === 503) {
          setMaintenanceMode(true);
        }
      }
      setLoading(false);
    }
    fetchUserStatus();
  });

  useSetBrandBarDisplay({
    leftContent: <></>,
  });

  useEffect(() => {
    if (!loading && !maintenanceMode) navigate('/', {replace: true});
  }, [navigate, loading, maintenanceMode]);

  return !loading ? (
    <MainLayout>
      <MaintenanceMessage>
        <Icon variation={Icon.variations.TOOLS} />
        <h1>OhmConnect is undergoing maintenance</h1>
        <p>We apologize for the inconvenience. We'll be back soon!</p>
      </MaintenanceMessage>
    </MainLayout>
  ) : null;
}
