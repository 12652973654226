/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import styled, {css} from 'styled-components';
import {ComponentProps} from '../../../types';
import {BackButton} from '../BackButton';
import {ButtonVariation} from '../Button';
import Icon from '../Icon';

const StyledBackButton = styled(BackButton)`
  ${({theme}) => css`
    padding: 0;
    margin: 0;
    border: none !important;
    flex: none;
    &,
    &:visited {
      color: currentColor;
      background: transparent;
    }
    &:hover,
    &:active,
    &:focus {
      color: ${theme.brandbar.active.color};
      background: transparent !important;
    }
  `}
`;

export const BackCarat = styled(() => <Icon variation={Icon.variations.CARAT_LEFT} />)`
  height: 20px;
  margin: 0;
  width: 20px;
`;

export const TopBrandBarBackButton: React.FC<ComponentProps<typeof BackButton>> = ({...props}) => {
  return (
    <StyledBackButton {...props} variation={ButtonVariation.PRIMARY_SUCCESS}>
      <BackCarat />
    </StyledBackButton>
  );
};
