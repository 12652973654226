/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */

import React from 'react';

export default function Announce() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.425 26.085C3.03333 27.5717 1.855 28.015 0 27.5183L4.41167 35.16C4.84 33.3367 5.77 32.3133 7.75333 31.8517L33.3333 25.6317L22.775 7.345L4.425 26.085V26.085ZM23.2917 30.5967L22.5133 34.1267C22.3567 34.7567 21.8617 35.25 21.2317 35.4117L16.3117 36.7017C15.7033 36.86 15.0567 36.6817 14.615 36.235L11.9917 33.3817L14.6667 32.7233L15.7433 34.0067C16.0367 34.3017 16.4633 34.42 16.8667 34.3167L19.53 33.6083C19.95 33.5017 20.2783 33.1733 20.3817 32.755L20.6517 31.245L23.2917 30.5967ZM37.8233 11.8183L33.35 14.5067L32.09 12.4033L36.5667 9.71667L37.8233 11.8183V11.8183ZM30.4967 9.88333L28.2717 8.85667L30.5083 4L32.7333 5.02833L30.4967 9.88333ZM39.8617 20.145L34.525 19.855L34.66 17.4067L40 17.6983L39.8617 20.145V20.145Z"
        fill="currentColor"
      />
    </svg>
  );
}
