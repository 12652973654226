/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function DataLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12H6L9 21L15 3L18 12H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
