/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Carat() {
  return (
    <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 17L9 9.5L2 2" stroke="#192145" strokeWidth="4" />
    </svg>
  );
}
