/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace Internal {
  /**
   * Mark W9 with status type
   * @param data UpdateW9StatusData - new status of the W9 & w9_response_id
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function markW9Status<
    TResponse = UpdateW9StatusResponse,
    TConfigData = UpdateW9StatusData
  >(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post('/api/internal/w9_response/mark', data, config);
  }

  // Aside from these 3 columns that is used specifically for display/known
  // the rest are populated from database columns for display
  export interface BaseW9ResponseData {
    w9_response_id: number;
    user_id: number;
    date_signed: string;
  }

  export type W9ResponseData = Record<string, string | number> & BaseW9ResponseData;

  export enum W9Status {
    VALID = 'VALID',
    PROCESSING = 'PROCESSING',
    INVALID = 'INVALID',
    DUPLICATE = 'DUPLICATE',
  }

  export interface UpdateW9StatusData {
    w9ResponseId: number;
    w9Status: W9Status;
  }

  export interface UpdateW9StatusResponse {
    status: number;
    msg: string;
    data: object;
  }
}
