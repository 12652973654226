/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function CaratRight({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7311 32.6823L20.2689 31.3177L26.6321 24.5L20.2689 17.6823L21.7311 16.3177L29.3679 24.5L21.7311 32.6823Z"
        fill="currentColor"
      />
    </svg>
  );
}
