/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const RewardsFill: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="15.5"
        width="18"
        height="10"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5"
        y="10.5"
        width="20"
        height="5"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.5C15 5.84315 16.3431 4.5 18 4.5V4.5C19.6569 4.5 21 5.84315 21 7.5V7.5C21 9.15685 19.6569 10.5 18 10.5H15V7.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7.5C9 5.84315 10.3431 4.5 12 4.5V4.5C13.6569 4.5 15 5.84315 15 7.5V10.5H12C10.3431 10.5 9 9.15685 9 7.5V7.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10.5V25.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M24 17.5H6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
