/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function SuccessCircle({className}: {className: string}) {
  return (
    <svg
      role="img"
      {...{className}}
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="33.5"
        cy="33.5"
        r="29.5"
        fill="white"
        stroke="currentColor"
        strokeWidth="6.12987"
      />
      <path
        d="M20.474 36.565L29.8552 45.2246C30.1727 45.5176 30.6694 45.4904 30.953 45.1645L56.1039 16.2598"
        stroke="currentColor"
        strokeWidth="6.12987"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
