/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect, useState} from 'react';
import {Portal} from 'ariakit';
import styled, {css} from 'styled-components';
import {Button, ButtonSize, ButtonVariation} from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import {WithComponentPropsWithoutRef} from 'types';
import {ActionA} from '../styled';

type StyledAnnouncementProps = PopupAnnouncementConfig & {height: string; showInPortal?: boolean};
const StyledAnnouncement = styled.div<StyledAnnouncementProps>`
  ${({theme, align, height, showInPortal}) => css`
    position: ${showInPortal ? 'fixed' : 'relative'};
    bottom: 0;
    height: ${height};
    background-color: ${theme.content.background};
    box-shadow: 0 -4px 10px 0 ${theme.color.secondaryA100};
    padding: 0 25px 25px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    /* iOS 'fullscreen' hacks */
    @supports (-webkit-touch-callout: none) {
      @media (height: 100vh), (orientation: landscape) {
        bottom: 0;
      }
    }

    & h3 {
      font-size: 20px;
      font-weight: bold;
      color: ${theme.text.color.secondary};
      margin: 15px 0 0px;
      display: flex;
      align-items: center;
      justify-content: ${align};

      & svg {
        margin-right: 10px;
      }
    }

    .message {
      text-align: ${align};
      font-size: 13px;
    }
  `}
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: center;
`;

const ActionsWrapper = styled.div<Partial<Pick<PopupAnnouncementConfig, 'ctaDirection'>>>`
  ${({ctaDirection}) => css`
    display: flex;
    flex-direction: ${ctaDirection};
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 100%;
    max-width: 410px;
    gap: 16px;
  `}
`;

const StyledActionA = styled(ActionA)`
  ${({theme}) => css`
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 8px;
    text-transform: ${theme.text.transform};
  `}
`;

const AnnouncementWrapper = ({
  showInPortal,
  children,
  ...props
}: WithComponentPropsWithoutRef<typeof Portal, {showInPortal?: boolean}>) => {
  return showInPortal ? <Portal {...props}>{children}</Portal> : <>{children}</>;
};

type CtaDirection = 'row' | 'column';

/** Popup announcement CTA */
interface PopupAnnouncementCta {
  /** */
  url?: string;
  /** */
  to?: string;
  /** */
  content?: JSX.Element | string | null;
  /** */
  confirmTrackId?: string;
  /** */
  onConfirm: () => void;
  /** */
  size?: ButtonSize;
  /** */
  variation?: ButtonVariation;
  /** */
  loading?: boolean;
}
/** Popup announcement options */
export interface PopupAnnouncementConfig {
  /** */
  header: string;
  /** */
  message: string | JSX.Element | null;
  /** */
  includeIcon: boolean;
  /** */
  showInPortal: boolean;
  /** */
  align: 'left' | 'center' | 'right';
  /** */
  ctas: PopupAnnouncementCta[];
  /** */
  ctaDirection: CtaDirection;
  /** */
  collapsable: boolean;
  /** */
  isCollapsed: boolean;
  /** */
  onCollapse?: (isCollapsed: boolean) => void;
  /** */
  collapseLabel?: string;
  /** */
  uncollapseLabel?: string;
  /** */
  dismissable: boolean;
  /** */
  onDismiss?: () => void;
  /** */
  dismissLabel?: string;
  /** */
  dismissPosition?: 'bottom' | 'actions';
  /** */
  height?: string;
  /** */
  collapsedHeight?: string;
}

/** Default CTA config */
const initialCta: PopupAnnouncementCta = {
  url: undefined,
  to: undefined,
  content: `Let's Go!`,
  confirmTrackId: undefined,
  onConfirm: () => {},
  size: ButtonSize.MINI,
  variation: ButtonVariation.PRIMARY,
  loading: false,
};
/** Default config */
const initialPopupAnnouncementConfig: PopupAnnouncementConfig = {
  header: '',
  message: '',
  includeIcon: true,
  dismissable: true,
  showInPortal: false,
  align: 'left',
  ctas: [],
  ctaDirection: 'row',
  collapsable: true,
  isCollapsed: false,
  onCollapse: () => {},
  collapseLabel: 'Hide',
  uncollapseLabel: 'Show',
  onDismiss: () => {},
  dismissLabel: 'Not now',
  dismissPosition: 'actions',
  height: 'unset',
  collapsedHeight: '36px',
};

/**
 *
 */
export default function PopupAnnouncement({
  options,
  ...props
}: WithComponentPropsWithoutRef<
  'div',
  {
    options?: Partial<
      Omit<PopupAnnouncementConfig, 'ctas'> & {
        ctas: (PopupAnnouncementCta | null | undefined)[];
      }
    >;
  }
>) {
  // merge defaults to create complete config
  const config: PopupAnnouncementConfig = {
    ...initialPopupAnnouncementConfig,
    ...(options || {}),
    ctas: [...(options?.ctas?.filter(c => !!c).map(c => ({...initialCta, ...c})) || [])],
  };

  const [isCollapsed, setIsCollapsed] = useState<boolean>(config.isCollapsed);

  // manual toggle the collapse state
  function toggleIsCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  // when the isCollapsed prop from the config changes, update the value
  useEffect(() => {
    if (config.collapsable && config.isCollapsed !== isCollapsed) {
      setIsCollapsed(config.isCollapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.isCollapsed, config.collapsable]);

  // when the isCollapsed local state changes, call the onCollapse callback
  useEffect(() => {
    if (config.collapsable && !!config.onCollapse) {
      config.onCollapse(isCollapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed, config.collapsable]);

  const dismissButton = config.dismissable ? (
    <StyledActionA onClick={config.onDismiss}>{config.dismissLabel}</StyledActionA>
  ) : null;

  const collapseButton = config.collapsable ? (
    <StyledActionA onClick={() => toggleIsCollapsed()}>
      {isCollapsed ? config.uncollapseLabel : config.collapseLabel}
    </StyledActionA>
  ) : null;

  return (
    <AnnouncementWrapper showInPortal={config.showInPortal}>
      <StyledAnnouncement
        {...props}
        align={config.align}
        showInPortal={config.showInPortal}
        height={config.collapsable && isCollapsed ? config.collapsedHeight : config.height}
      >
        <>
          {config.collapsable ? <ActionsWrapper>{collapseButton}</ActionsWrapper> : null}
          {config.collapsable && isCollapsed ? null : (
            <>
              <ContentWrapper>
                <div className="message">
                  {config.includeIcon || config.header ? (
                    <h3>
                      {config.includeIcon ? (
                        <>
                          <Icon variation={Icon.variations.ANNOUNCE} />{' '}
                        </>
                      ) : null}
                      <span>{config.header}</span>
                    </h3>
                  ) : null}
                  {typeof config.message === 'string' ? <p>{config.message}</p> : config.message}
                </div>
                <ActionsWrapper ctaDirection={config.ctaDirection}>
                  {config.dismissPosition === 'actions' ? dismissButton : null}
                  {config.ctas.map((cta, idx) =>
                    cta.url || cta.to ? (
                      <Button
                        key={'popup-announcement-cta-' + idx}
                        variation={cta.variation}
                        size={cta.size}
                        onClick={cta.onConfirm}
                        href={cta.to ? undefined : cta.url}
                        to={!cta.to ? undefined : cta.to}
                        target={cta.to ? undefined : '_blank'}
                        data-clicktrackid={cta.confirmTrackId}
                      >
                        {cta.content}
                      </Button>
                    ) : null,
                  )}
                </ActionsWrapper>
              </ContentWrapper>
              {config.dismissPosition === 'bottom' ? (
                <ActionsWrapper>{dismissButton}</ActionsWrapper>
              ) : null}
            </>
          )}
        </>
      </StyledAnnouncement>
    </AnnouncementWrapper>
  );
}
