/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {useEffect} from 'react';
import {get} from 'lodash';
import {useGlobalState} from 'store';
import {useSetGSCurrentTheme} from 'store/actions';
import {ENV} from 'config/globals';
import {Oem} from 'config/environments/env.types';
import {THEME_ID, THEME_IDS, THEMES, ThemeConfig} from './../themes';

const LOCAL_STORAGE_KEY: string = 'THEME_ID';
/**
 * Helper: Returns the themeId that should be used as the defult theme, based on the current oem
 * @returns themeId string
 */
function getDefaultThemeId(): string {
  if (ENV.oem === Oem.ORIGIN) return THEME_ID.originLight;
  else if (ENV.oem === Oem.TELUS) return THEME_ID.telusLight;
  else if (ENV.oem === Oem.NRG) return THEME_ID.nrgLight;
  else if (ENV.oem === Oem.VIVINT) return THEME_ID.vivintLight;
  else return THEME_ID.ohmLight;
}

/**
 * Helper: Gets a theme config for the provided themeId
 * @param themeId themeId for the config to get
 * @returns theme config
 */
function getThemeById(themeId: string): ThemeConfig | undefined {
  const theme = THEMES.find(t => t.themeId === themeId);
  return theme;
}

/**
 * Checks to make sure a themeId is valid in the set of allowed themes, otherwise returns undefined
 * @param themeId themeId to validate
 * @returns themeId, if valid, otherise undefined
 */
function validateThemeId(themeId?: string): string | undefined {
  return themeId && THEME_IDS.includes(themeId) ? themeId : undefined;
}

/**
 * Hook to retreive the theme based on the stored id.
 * Note: this is reserved for use in injecting into the Theme Provider only.
 * To consume the current theme, use the useTheme() hook from 'styled-components'
 * @returns theme config object
 */
export function useGetTheme(): ThemeConfig {
  const globalState = useGlobalState();
  const setThemeId = useSetTheme();

  const defaultThemeId = getDefaultThemeId();
  let persistedThemeId: undefined | string = undefined;
  try {
    persistedThemeId = localStorage.getItem(LOCAL_STORAGE_KEY) ?? undefined;
  } catch (e) {
    persistedThemeId = undefined;
  }
  const globalStateThemeId = (get(globalState, 'currentTheme') as unknown) as string;
  const themeId =
    validateThemeId(globalStateThemeId) ||
    validateThemeId(persistedThemeId) ||
    validateThemeId(defaultThemeId) ||
    THEMES[0].themeId;

  // keep storage methods in sync
  useEffect(() => {
    if (persistedThemeId !== themeId || globalStateThemeId !== themeId) {
      setThemeId(themeId);
    }
  }, [themeId, persistedThemeId, globalStateThemeId, setThemeId]);

  // theme config, which is guarenteed to return a Theme given the above themeId definition
  const themeConfig = getThemeById(themeId) as ThemeConfig;
  return themeConfig;
}

/**
 * Hook to set the theme based on the provided callback id
 * @returns callback to set the theme id, which returns the newly set theme config
 */
export function useSetTheme(): (themeId?: string) => ThemeConfig | undefined {
  const setGSCurrentTheme = useSetGSCurrentTheme();
  const defaultThemeId = getDefaultThemeId();
  /**
   * Switch the current theme. Uses the default themeId if none is provided.
   * @param themeId theme id
   */
  function setThemeId(themeId: string = defaultThemeId): ThemeConfig | undefined {
    if (!validateThemeId(themeId)) return undefined;
    // store in global store
    setGSCurrentTheme(themeId);
    // store in local persistant storage
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, themeId);
    } catch (e) {
      // just catch, we're not using any fallback for now
      return undefined;
    }
    return getThemeById(themeId);
  }
  return setThemeId;
}
