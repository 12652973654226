import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace Referral {
  /**********
   * Interactions
   **********/

  /**
   * Get all referral offers
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getReferralOffers<TResponse = ReferralOffersData, TConfigData = never>(
    dt?: string,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    const mergedConfig = !!dt ? {...config, params: {...config?.params, dt}} : config;
    return await axios.get(`/api/v2/referrals/offers`, mergedConfig);
  }

  /**********
   * Types
   **********/

  export interface ReferralOffer {
    offerAbbreviation: string;
    offerTitle: string;
    referrerBonus: string;
    refereeBonus: string;
    offerDetails: string;
    isDefault: boolean;
    eligiblePrograms: string[];
  }

  export interface ReferralOffersData {
    pageHeader: string;
    pageBody: string;
    pageBanner: string;
    boostBanner: string | null;
    shortLink: string | null;
    offers: ReferralOffer[];
  }
}
