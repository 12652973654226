/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const EventsLine: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9146 5.05531C16.9401 4.71096 16.7272 4.39371 16.3988 4.28683C16.0705 4.17995 15.7116 4.31106 15.5295 4.60442L9.05245 15.0376C8.65958 15.6704 9.1147 16.4886 9.85957 16.4886H14.5258L14.0854 22.4447C14.0599 22.789 14.2728 23.1063 14.6012 23.2132C14.9295 23.32 15.2884 23.1889 15.4705 22.8956L21.9475 12.4624C22.3404 11.8296 21.8853 11.0114 21.1404 11.0114H16.4742L16.9146 5.05531Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 21.3203L4.25856 23.4065C4.17078 23.4384 4.17076 23.5626 4.25854 23.5945L14.9658 27.488C14.9879 27.4961 15.0121 27.4961 15.0342 27.488L25.7417 23.5944C25.8294 23.5625 25.8295 23.4384 25.7417 23.4065L21 21.6805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
