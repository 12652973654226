/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function QRCode({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3519 6.86561C19.3519 7.3055 19.3552 7.74539 19.3519 8.18528C19.3485 8.70182 19.0052 9.0484 18.4885 9.0484C17.6019 9.05174 16.7186 9.05174 15.8319 9.0484C15.3153 9.0484 14.9653 8.70182 14.962 8.18862C14.9553 7.29883 14.9553 6.40572 14.962 5.51594C14.9653 5.01273 15.3119 4.65948 15.8186 4.65615C16.7186 4.64949 17.6152 4.64948 18.5152 4.65615C19.0085 4.65948 19.3485 5.01273 19.3552 5.50594C19.3585 5.73589 19.3552 5.96583 19.3552 6.19577C19.3519 6.41905 19.3519 6.64233 19.3519 6.86561ZM17.9019 6.85228C17.9019 6.69565 17.9052 6.53902 17.9019 6.38239C17.8986 6.17578 17.8386 6.11246 17.6286 6.10913C17.3086 6.10579 16.9886 6.10913 16.6686 6.10913C16.4986 6.10913 16.4119 6.18577 16.4086 6.35573C16.4086 6.68898 16.4053 7.02224 16.4086 7.35549C16.4086 7.51212 16.4886 7.59876 16.6486 7.59876C16.9819 7.59876 17.3152 7.59876 17.6486 7.59876C17.8219 7.59876 17.8986 7.51212 17.8986 7.34216C17.9019 7.17886 17.9019 7.01557 17.9019 6.85228Z"
        fill="currentColor"
      />
      <path
        d="M4.66557 17.1564C4.66557 16.7232 4.66557 16.2899 4.66557 15.8567C4.66891 15.2935 4.99557 14.9603 5.55222 14.9569C6.4322 14.9536 7.30885 14.9536 8.18883 14.9569C8.69882 14.9569 9.04882 15.2935 9.05215 15.8034C9.05882 16.7065 9.05882 17.6129 9.05215 18.5161C9.04882 18.9959 8.69882 19.3425 8.2155 19.3492C7.31219 19.3559 6.40554 19.3559 5.50222 19.3492C5.0189 19.3459 4.68224 18.9959 4.67224 18.5127C4.67224 18.4994 4.67224 18.4861 4.67224 18.4727C4.66557 18.0328 4.66557 17.593 4.66557 17.1564ZM6.85553 16.4066C6.68553 16.4066 6.51553 16.4066 6.34554 16.4066C6.19221 16.4066 6.11554 16.4832 6.11554 16.6365C6.11554 16.9764 6.11554 17.3164 6.11554 17.6563C6.11554 17.8096 6.19221 17.8929 6.33887 17.8929C6.68553 17.8962 7.03219 17.8995 7.37885 17.8929C7.51551 17.8895 7.59885 17.8162 7.59885 17.6729C7.59885 17.3264 7.59885 16.9798 7.59885 16.6332C7.59885 16.4799 7.51551 16.4066 7.36552 16.4032C7.19552 16.4066 7.02552 16.4066 6.85553 16.4066Z"
        fill="currentColor"
      />
      <path
        d="M4.66225 6.84226C4.66225 6.40236 4.65892 5.96247 4.66225 5.52591C4.66559 5.00604 5.00558 4.65946 5.52224 4.65613C6.41888 4.64946 7.31887 4.64946 8.21551 4.65613C8.6855 4.65946 9.04216 5.00604 9.0455 5.47593C9.0555 6.3857 9.0555 7.29881 9.0455 8.20859C9.04216 8.70846 8.69217 9.04505 8.19551 9.04505C7.30553 9.04838 6.41222 9.04838 5.5189 9.04505C5.00891 9.04171 4.67225 8.70513 4.65892 8.19526C4.65892 8.18193 4.65892 8.1686 4.65892 8.15527C4.66559 7.72204 4.66559 7.28215 4.66225 6.84226ZM7.59886 6.84559C7.60219 6.67563 7.60219 6.50567 7.60219 6.33238C7.59886 6.18908 7.52219 6.1091 7.3822 6.1091C7.03554 6.10577 6.68555 6.10577 6.33889 6.1091C6.20222 6.11244 6.11889 6.18242 6.11889 6.32905C6.11889 6.67563 6.11889 7.02554 6.11889 7.37213C6.11889 7.51542 6.19222 7.5954 6.33555 7.5954C6.68221 7.59874 7.0322 7.59874 7.37886 7.5954C7.53219 7.5954 7.60219 7.50876 7.59886 7.35546C7.59553 7.18884 7.59886 7.01555 7.59886 6.84559Z"
        fill="currentColor"
      />
      <path
        d="M4.79221 2.01012C5.2122 2.01012 5.63219 2.01012 6.05218 2.01012C6.43884 2.01012 6.68217 2.23673 6.68217 2.58331C6.67883 2.91989 6.43551 3.14317 6.06218 3.14317C5.26886 3.14317 4.47555 3.14317 3.68223 3.14317C3.29224 3.14317 3.14891 3.28647 3.14891 3.67637C3.14891 4.45618 3.14891 5.23599 3.14891 6.0158C3.14891 6.34572 3.02558 6.55233 2.77891 6.64564C2.41226 6.77894 2.02226 6.52901 2.0156 6.12577C2.00226 5.24266 1.9856 4.35621 2.02226 3.47309C2.05893 2.60997 2.72892 2.02011 3.59223 2.01012C3.99222 2.00678 4.39221 2.01012 4.79221 2.01012Z"
        fill="currentColor"
      />
      <path
        d="M4.77689 21.9985C4.3769 21.9985 3.97691 22.0019 3.57691 21.9985C2.69027 21.9852 2.02361 21.3254 2.01361 20.4389C2.00361 19.6058 2.01028 18.7726 2.01028 17.9429C2.01028 17.5829 2.25694 17.323 2.5836 17.3263C2.91693 17.3297 3.14692 17.5763 3.14692 17.9495C3.15026 18.7427 3.14692 19.5358 3.14692 20.3289C3.14692 20.7222 3.28692 20.8655 3.67691 20.8655C4.49023 20.8655 5.30354 20.8621 6.11686 20.8688C6.41019 20.8688 6.64352 21.0787 6.67685 21.3453C6.71352 21.6353 6.55685 21.8885 6.28686 21.9719C6.21353 21.9952 6.13353 21.9985 6.05353 21.9985C5.6302 21.9985 5.20355 21.9985 4.77689 21.9985Z"
        fill="currentColor"
      />
      <path
        d="M21.9952 4.77275C21.9952 5.19931 21.9952 5.62587 21.9952 6.05243C21.9952 6.42568 21.7719 6.67561 21.4386 6.68228C21.0986 6.68561 20.8619 6.43234 20.8619 6.0491C20.8586 5.25596 20.8619 4.46283 20.8619 3.66636C20.8619 3.28312 20.7219 3.14315 20.3419 3.14315C19.5353 3.14315 18.7286 3.14315 17.922 3.14315C17.6753 3.14315 17.492 3.03984 17.3853 2.81323C17.2886 2.60328 17.2953 2.38667 17.462 2.23004C17.5753 2.1234 17.752 2.02009 17.902 2.01676C18.7819 1.99677 19.6619 1.99343 20.5386 2.01343C21.3186 2.03009 21.9685 2.68993 21.9919 3.47307C22.0052 3.90629 21.9952 4.33952 21.9952 4.77275Z"
        fill="currentColor"
      />
      <path
        d="M21.9986 19.2359C21.9986 19.6358 22.0019 20.0357 21.9986 20.4356C21.9886 21.3253 21.3286 21.9885 20.4386 21.9952C19.6119 22.0018 18.7853 21.9985 17.9586 21.9952C17.592 21.9952 17.3287 21.7586 17.3287 21.4353C17.3253 21.0987 17.5853 20.8621 17.9653 20.8621C18.7586 20.8621 19.5519 20.8621 20.3453 20.8621C20.7253 20.8621 20.8653 20.7222 20.8653 20.3389C20.8653 19.5458 20.8619 18.7526 20.8653 17.9562C20.8686 17.4996 21.2452 17.213 21.6352 17.363C21.8652 17.4496 21.9952 17.6429 21.9986 17.9162C21.9986 18.3561 21.9986 18.796 21.9986 19.2359Z"
        fill="currentColor"
      />
      <path
        d="M10.5454 7.59541C10.5454 7.26216 10.5421 6.92558 10.5454 6.59233C10.5487 6.28241 10.6954 6.13911 11.0087 6.13577C11.1921 6.13244 11.3754 6.13911 11.5587 6.13244C11.8487 6.12244 12.0054 5.96582 12.0154 5.67589C12.022 5.48593 12.0154 5.29598 12.0187 5.10603C12.0254 4.82276 12.1787 4.66947 12.4587 4.65947C12.6487 4.65281 12.8387 4.65614 13.0287 4.65947C13.2887 4.66614 13.462 4.80943 13.4654 5.06604C13.4754 5.77586 13.472 6.48236 13.462 7.19218C13.4587 7.43212 13.2954 7.57542 13.0554 7.58542C12.8654 7.59208 12.6754 7.58542 12.4854 7.58875C12.162 7.59208 12.002 7.75204 11.9954 8.07196C11.992 8.25525 11.9987 8.43854 11.992 8.62183C11.9854 8.89843 11.8354 9.04839 11.5654 9.05506C11.3687 9.05839 11.1721 9.05839 10.9754 9.05506C10.7054 9.04839 10.5487 8.8951 10.5454 8.62516C10.5387 8.27525 10.5454 7.93533 10.5454 7.59541Z"
        fill="currentColor"
      />
      <path
        d="M19.3518 12.7441C19.3518 13.314 19.3518 13.8838 19.3518 14.457C19.3518 14.7836 19.1985 14.9336 18.8685 14.9402C18.6985 14.9436 18.5285 14.9436 18.3585 14.9402C18.0652 14.9336 17.9085 14.7803 17.9052 14.4904C17.9019 14.3137 17.9052 14.1371 17.9052 13.9605C17.9019 13.6306 17.7385 13.4706 17.4052 13.4673C17.2219 13.4639 17.0385 13.4706 16.8552 13.4639C16.6019 13.4573 16.4486 13.314 16.4386 13.0607C16.4319 12.8441 16.4319 12.6275 16.4419 12.4109C16.4519 12.1743 16.6052 12.0243 16.8452 12.0143C17.0352 12.0076 17.2252 12.0143 17.4152 12.011C17.7419 12.0076 17.8985 11.8543 17.9052 11.5311C17.9085 11.3478 17.9019 11.1645 17.9085 10.9812C17.9152 10.708 18.0619 10.5547 18.3352 10.5447C18.5385 10.538 18.7418 10.5413 18.9452 10.5447C19.2018 10.5513 19.3552 10.708 19.3585 10.9712C19.3552 11.5644 19.3518 12.1543 19.3518 12.7441Z"
        fill="currentColor"
      />
      <path
        d="M9.05876 10.5447C9.41209 10.5447 9.76875 10.5413 10.1221 10.5447C10.4021 10.548 10.5254 10.6613 10.5254 10.9346C10.5287 11.6444 10.5287 12.3509 10.5254 13.0607C10.5254 13.3473 10.4054 13.4606 10.1121 13.4606C9.89541 13.4606 9.67875 13.4639 9.46209 13.4606C9.19209 13.4573 9.07876 13.344 9.07209 13.0774C9.06876 12.8674 9.07209 12.6575 9.07209 12.4475C9.06876 12.1143 8.9521 11.9943 8.6221 11.991C8.39877 11.9876 8.17545 11.9943 7.95212 11.9876C7.73546 11.981 7.60546 11.8643 7.59546 11.6477C7.58546 11.3978 7.58546 11.1479 7.59212 10.9012C7.59879 10.668 7.73212 10.5413 7.96878 10.5413C8.33878 10.5413 8.69877 10.5447 9.05876 10.5447Z"
        fill="currentColor"
      />
      <path
        d="M4.66226 12.0077C4.66226 11.6478 4.65893 11.2879 4.66226 10.9279C4.6656 10.6414 4.75559 10.548 5.03892 10.548C5.28892 10.548 5.53558 10.5447 5.78557 10.548C6.00224 10.5514 6.1089 10.6613 6.11223 10.8813C6.11557 11.0579 6.11557 11.2345 6.11223 11.4112C6.11223 11.9844 6.14223 12.0143 6.70555 12.0143C6.87555 12.0143 7.04555 12.011 7.21554 12.0143C7.46554 12.0177 7.5722 12.1243 7.57554 12.3743C7.57887 12.6309 7.57887 12.8841 7.57554 13.1407C7.5722 13.3507 7.46887 13.4607 7.26221 13.464C6.49556 13.4673 5.72891 13.4673 4.96226 13.464C4.76893 13.464 4.6656 13.354 4.66226 13.1507C4.65893 12.7675 4.6656 12.3876 4.66226 12.0077C4.6656 12.0077 4.66226 12.0077 4.66226 12.0077Z"
        fill="currentColor"
      />
      <path
        d="M12.0053 13.4839C12.3387 13.4839 12.6753 13.4806 13.0086 13.4839C13.3153 13.4873 13.4653 13.6372 13.472 13.9472C13.4753 14.1304 13.4786 14.3137 13.4686 14.497C13.4586 14.777 13.302 14.9269 13.0186 14.9369C12.822 14.9402 12.6253 14.9336 12.4287 14.9402C12.162 14.9502 12.0053 15.1069 11.9953 15.3768C11.9887 15.5801 11.9953 15.7834 11.992 15.9867C11.9853 16.2466 11.8353 16.3999 11.572 16.4066C11.3753 16.4132 11.1787 16.4099 10.982 16.4066C10.712 16.3999 10.5487 16.2466 10.5454 15.98C10.542 15.2902 10.542 14.6037 10.5454 13.9138C10.5487 13.6439 10.7054 13.4906 10.982 13.4873C11.322 13.4806 11.662 13.4873 12.0053 13.4839C12.0053 13.4873 12.0053 13.4873 12.0053 13.4839Z"
        fill="currentColor"
      />
      <path
        d="M16.4086 17.8829C16.4086 18.2095 16.4086 18.5394 16.4086 18.866C16.4052 19.1992 16.2619 19.3459 15.9319 19.3525C15.7486 19.3559 15.5653 19.3592 15.3819 19.3525C15.1253 19.3425 14.9619 19.1859 14.9619 18.9293C14.9586 18.2361 14.9586 17.5396 14.9686 16.8465C14.9719 16.5865 15.1319 16.4333 15.3919 16.4266C15.5886 16.4199 15.7853 16.4199 15.9819 16.4266C16.2486 16.4333 16.4086 16.5899 16.4119 16.8598C16.4119 17.1997 16.4086 17.5396 16.4086 17.8829Z"
        fill="currentColor"
      />
      <path
        d="M13.4686 10.5014C13.4686 10.8413 13.472 11.1846 13.4686 11.5245C13.4653 11.8444 13.312 11.9944 12.9953 11.9977C12.8053 12.001 12.6153 12.001 12.4253 11.9977C12.1853 11.991 12.0253 11.8411 12.022 11.6044C12.0153 10.898 12.012 10.1881 12.022 9.48164C12.0253 9.23836 12.1753 9.09173 12.4187 9.0784C12.6353 9.06507 12.852 9.06507 13.0687 9.0784C13.312 9.09173 13.452 9.24503 13.452 9.48497C13.4553 9.82489 13.452 10.1648 13.452 10.5081C13.4586 10.5014 13.4653 10.5014 13.4686 10.5014Z"
        fill="currentColor"
      />
      <path
        d="M17.1653 14.9569C17.8253 14.9569 17.872 15.0169 17.8887 15.6701C17.902 16.2966 17.7953 16.4299 17.1487 16.4099C16.4887 16.3899 16.432 16.3499 16.432 15.6901C16.432 15.5934 16.432 15.4935 16.432 15.3968C16.4387 15.1102 16.5887 14.9603 16.8687 14.9569C16.9687 14.9536 17.0653 14.9569 17.1653 14.9569Z"
        fill="currentColor"
      />
      <path
        d="M12.7521 17.8762C12.0654 17.8762 12.0154 17.8262 12.0188 17.1397C12.0188 16.4866 12.0821 16.4232 12.7354 16.4232C13.4154 16.4232 13.4721 16.4832 13.4721 17.1631C13.4687 17.8162 13.4087 17.8762 12.7521 17.8762Z"
        fill="currentColor"
      />
      <path
        d="M11.2721 17.8995C11.9454 17.8995 11.9954 17.9495 11.9954 18.6227C11.9954 19.2958 11.9387 19.3525 11.2721 19.3525C10.6054 19.3525 10.5454 19.2925 10.5454 18.626C10.5454 17.9562 10.5987 17.9028 11.2721 17.8995Z"
        fill="currentColor"
      />
      <path
        d="M17.9019 18.626C17.9019 17.9528 17.9519 17.8995 18.6252 17.9029C18.7286 17.9029 18.8352 17.8995 18.9385 17.9062C19.1919 17.9195 19.3419 18.0628 19.3485 18.3128C19.3552 18.5227 19.3552 18.7326 19.3485 18.9393C19.3419 19.1859 19.1885 19.3392 18.9419 19.3525C18.7452 19.3625 18.5486 19.3592 18.3519 19.3558C18.0619 19.3525 17.9086 19.1925 17.9019 18.9026C17.9019 18.8093 17.9019 18.716 17.9019 18.626Z"
        fill="currentColor"
      />
      <path
        d="M15.692 13.484C16.342 13.474 16.4087 13.5406 16.4087 14.1971C16.4087 14.3004 16.412 14.4071 16.4087 14.5104C16.3987 14.7703 16.2487 14.9269 15.992 14.9369C15.7887 14.9436 15.5853 14.9469 15.3853 14.9369C15.1254 14.9269 14.9654 14.757 14.9587 14.497C14.9554 14.3137 14.9587 14.1305 14.962 13.9505C14.972 13.6306 15.1187 13.4873 15.4387 13.4873C15.522 13.484 15.6087 13.484 15.692 13.484Z"
        fill="currentColor"
      />
      <path
        d="M16.4086 11.2578C16.4086 11.2645 16.4086 11.2712 16.4086 11.2778C16.4553 11.9843 16.192 12.0077 15.6653 11.9877C15.632 11.9877 15.602 11.981 15.5687 11.9777C15.002 11.9343 14.9653 11.8943 14.9587 11.3212C14.9587 11.2178 14.9587 11.1112 14.9587 11.0079C14.9653 10.7013 15.1187 10.5413 15.4287 10.5347C15.5987 10.5314 15.7686 10.5347 15.9386 10.538C16.2653 10.5447 16.4053 10.6846 16.4086 11.0046C16.4086 11.0845 16.4086 11.1712 16.4086 11.2578Z"
        fill="currentColor"
      />
    </svg>
  );
}
