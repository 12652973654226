/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function More({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1595 3H3.84051C2.82566 3 2 3.67265 2 4.50051C2 5.32732 2.82566 6 3.84051 6H20.1595C21.1743 6 22 5.32732 22 4.50051C22 3.67265 21.1743 3 20.1595 3ZM20.1595 10H3.84051C2.82566 10 2 10.6726 2 11.5005C2 12.3273 2.82566 13 3.84051 13H20.1595C21.1743 13 22 12.3273 22 11.5005C22 10.6726 21.1743 10 20.1595 10ZM3.84051 17H20.1595C21.1743 17 22 17.6727 22 18.5005C22 19.3273 21.1743 20 20.1595 20H3.84051C2.82566 20 2 19.3273 2 18.5005C2 17.6727 2.82566 17 3.84051 17Z"
        fill="currentColor"
      />
    </svg>
  );
}
