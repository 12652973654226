/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function HomeBolt({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 91 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M63.4696 3.41602H75.3999V23.8268L63.4696 11.8965V3.41602Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.251 0.666626L90.0019 45.9442H78.2747V81.6666H12.3411V45.9442H0.5L45.251 0.666626ZM32.7096 54.4431L50.7488 36.4039L47.0834 46.5375H59.5887L41.3339 66.0859L44.3524 54.4431H32.7096Z"
        fill="currentColor"
      />
    </svg>
  );
}
