/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {useGlobalState} from 'store';
import {get} from 'lodash';
import {SURVEY_USER_ATTRIBUTE_PREFIX} from './SurveyAnnouncement';

/**
 * Tigger key
 * @typedef { {} } TriggeredSurveyData
 */
/**
 * Function to set triggered survey data to a trigger key
 * @typedef {(surveyKey: string, additionalSurveyFields: {}) => void} SetTriggeredSurveyData
 */

/**
 * Hook to Get and Set triggered survey data, using sessionStorage
 * @param {string} triggerKey
 * @returns {[ TriggeredSurveyData, SetTriggeredSurveyData ]}
 */
export function useTriggeredSurvey(triggerKey) {
  const globalState = useGlobalState();

  // SET
  /** @type {SetTriggeredSurveyData} */
  function setTriggeredSurveyData(surveyKey, additionalSurveyFields) {
    // get user attribute data for this survey
    const surveyUserAttribute = SURVEY_USER_ATTRIBUTE_PREFIX + surveyKey;
    const surveyUserAttributeValue = get(globalState.user.attributes, surveyUserAttribute);

    // if no trigger is provided, we can't proceed.
    // if the user state already has an attribute value >= 1,
    // we've already attemped to show this survey to them in the past, so bail
    if (!triggerKey || parseInt(surveyUserAttributeValue) >= 1) {
      return;

      // no surveyKey means there's no data to set, so we should remove the stored trigger
    } else if (!surveyKey) {
      sessionStorage.removeItem(triggerKey);

      // otherwise, set the survey data into storage!
    } else {
      sessionStorage.setItem(
        triggerKey,
        JSON.stringify({
          triggerKey,
          surveyKey,
          additionalSurveyFields,
        }),
      );
    }
  }
  // GET
  const raw = sessionStorage.getItem(triggerKey);
  const value = raw ? JSON.parse(raw) : undefined;
  // RETURN
  return [value, setTriggeredSurveyData];
}
