/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useEffect} from 'react';
import axios from 'axios';

import {useGlobalState, useGlobalDispatch, ActionType} from 'store';
import {isEmpty} from 'lodash';
import {useFlashError} from 'hooks/useFlash';
import useQuery from 'hooks/useQuery';
import {useNavigate, useLocation} from 'react-router-dom';
import {useGetUserIsAuthenticated} from 'store/selectors';
import {useViewerFirstLoadLazyQuery} from 'graphql/api.generated';

/**
 * there are a few routes which require the access token in the query
 * add them here to avoid stripping it out
 */
const ROUTES_TO_KEEP_ACCESS_TOKEN = ['/reset-password'];
export default function GetUserAuthorization() {
  const globalDispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const flashError = useFlashError();
  const query = useQuery();
  const accessToken = query.get('access_token');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isEmpty(globalState.user)) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      async function fetchUser() {
        try {
          // Provide the access token as a query param, if provided.
          // I don't love doing this as a query param, but doing it as a header
          // was breaking CORS and I can't figure out why.
          const response = await axios.get('/api/v2/user_status', {
            cancelToken: source.token,
            params: !!accessToken
              ? {
                  access_token: accessToken,
                }
              : {},
          });
          // Should always return something, even if user is not logged in
          globalDispatch({
            type: ActionType.SET_USER,
            payload: response.data,
          });

          // clear the access token after the request is made
          if (!!accessToken) {
            query.delete('access_token');
            // if we had an access token, but are not logged in, assume an expired access token
            // the id in the payload indicates a logged-in user
            if (!response.data?.id) {
              // edge case for users coming in with access token to set up a password
              // trigger a new email for them
              if (location.pathname === '/signup/password') {
                flashError({
                  message:
                    'For security purposes, that link has expired. ' +
                    'Please enter your email for a new link.',
                  source: 'GetUserAuthorization',
                });
                navigate(
                  {
                    pathname: '/forgot-password',
                    search: query.toString(),
                  },
                  {replace: true},
                );
              } else {
                navigate(
                  {
                    search: query.toString(),
                  },
                  {replace: true},
                );
                flashError({
                  message: 'For security purposes, that link has expired!',
                  source: 'GetUserAuthorization',
                });
              }
            }
            // if we are heading to /reset-password, we need the access token around!
            else if (!ROUTES_TO_KEEP_ACCESS_TOKEN.includes(location.pathname)) {
              navigate(
                {
                  search: query.toString(),
                },
                {replace: true},
              );
            }
          }

          if (window.gtmActive && response.data.id)
            window.dataLayer.push({user_id: response.data.id});
        } catch (error) {
          if (!axios.isCancel(error)) {
            // Something unexpected happened
            flashError({source: 'GetUserAuthorization'});
            globalDispatch({
              type: ActionType.SET_USER,
              payload: {
                isAdmin: false,
              },
            });
          }
        }
      }

      fetchUser();
      return () => source.cancel();
    }
  }, [flashError, globalDispatch, globalState.user, accessToken, location, navigate, query]);

  const isAuthenticated = useGetUserIsAuthenticated();
  const [doViewerQuery] = useViewerFirstLoadLazyQuery();
  useEffect(() => {
    if (isAuthenticated) {
      doViewerQuery();
    }
  }, [isAuthenticated, doViewerQuery]);
  return null;
}
