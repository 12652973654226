/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {useTheme} from 'styled-components';
import {useSetTheme} from '.';
import {ThemeConfig, THEMES} from '../themes';

/**
 * Hook to cycle to the next available theme and set it as current.
 * @returns callback to cycle to the next theme
 */
export function useCycleTheme(): () => ThemeConfig {
  const setThemeId = useSetTheme();
  const theme = useTheme();

  const themeIndex: number = THEMES.findIndex(x => x.themeId === theme.themeId);
  const nextIndex: number = themeIndex < THEMES.length - 1 ? themeIndex + 1 : 0;
  const nextTheme: ThemeConfig = THEMES[nextIndex];

  /**
   * Cycle through available themes and set the next one as current.
   * @returns theme config that was cycled to
   */
  function cycleTheme(): ThemeConfig {
    return setThemeId(nextTheme.themeId) as ThemeConfig;
  }
  return cycleTheme;
}
