/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import * as Theme from './theme.types';
import * as Utils from './../utils';

/**********************
 * Theme Info
 **********************/
const THEME_INFO: Theme.Info = {
  themeId: 'vivintLight',
  name: 'Vivint Light Theme',
  shorthand: 'vivint',
};

/**********************
 * Color Palette Config
 **********************/
/**
 * Used with the generateColorPalettes function to generate scales of colors
 */
const COLOR_PALETTE_CONFIG: Theme.ColorPaletteConfig = {
  /**
   * Base Color Palette Colors
   * Used to generate color luminosity and overlay scales.
   *
   * FORMAT: css rgba color string: rgba(x, x, x, x)
   */
  baseColors: {
    primary: Utils.formatHex('#54aa8c'),
    secondary: Utils.formatHex('#000000'),
    alert: Utils.formatHex('#f8cc1c'),
    info: Utils.formatHex('#203d67'),
    error: Utils.formatHex('#ff555d'),
    success: Utils.formatHex('#75bbaa'),
    grey: Utils.formatHex('#9aafb3'),
  },

  /**
   * Overlay Color Palette Colors
   * Used to generate color overlay scales.
   *
   * FORMAT: css rgba color string: rgba(x, x, x, x)
   */
  baseOverlayColors: {
    white: Utils.formatHex('#fff', 1),
    black: Utils.formatHex('#000', 1),
  },

  /**
   * Colors to use as light and dark contrast colors with reference to the
   * color palette input color. For example, if the color palette color is
   * light-ish, its contrast color will use this dark color.
   *
   * FORMAT: css rgba color string: rgba(x, x, x, x)
   */
  contrastColors: {
    dark: Utils.formatHex('#000'),
    light: Utils.formatHex('#fff'),
  },

  /**
   * Luminosity factors are used to generate a color scale.
   * 0 to +1  A tint of the source color, +1 represents full white
   * 0 to -1  A shade of the source color, -1 represents full black
   * 0        The source color, unchanged
   *
   * FORMAT: number, between -1 and 1
   */
  luminosityFactors: {
    50: 0.93,
    100: 0.8,
    200: 0.6,
    300: 0.4,
    400: 0.2,
    450: 0.1,
    500: 0,
    600: -0.2,
    700: -0.4,
    800: -0.6,
    900: -0.8,
  },

  /**
   * Overlay factors are used to generate alpha versions of a color.
   * 0 represents fully transparent
   * 1 represents fully opaque
   *
   * FORMAT: number, between 0 and 1
   */
  overlayFactors: {
    A50: 0.05,
    A100: 0.1,
    A200: 0.2,
    A300: 0.3,
    A400: 0.4,
    A500: 0.5,
    A600: 0.6,
    A700: 0.7,
    A800: 0.8,
    A900: 0.9,
  },
};

/**********************
 * General Colors
 **********************/
const COLOR: Theme.Color = {
  ...Utils.generateColorPalettes(COLOR_PALETTE_CONFIG),
  currency: Utils.formatHex('#f8cc1c'),
  currencyContrast: Utils.formatHex('#fff'),
  /**
   * This is an obviously wrong color to be implemented as a placeholder
   * when it's not clear what other theme color to use. References to this should
   * all be resolved in the code review process and no references to this
   * should ever make it into Main or any production build.
   */
  wrong: Utils.formatHex('#ff00ff', 1),
};

/**********************
 * Text
 **********************/
const textDarkNavy = '#192145';
const TEXT_COLOR: Theme.TextColor = {
  primary: Utils.formatHex(textDarkNavy, 1),
  secondary: Utils.formatHex(textDarkNavy, 0.8),
  tertiary: Utils.formatHex(textDarkNavy, 0.6),
  subdued: COLOR.secondaryA300,
  primaryContrast: COLOR.white,
  secondaryContrast: COLOR.whiteA800,
  tertiaryContrast: COLOR.whiteA600,
};
const TEXT: Theme.Text = {
  fontFamily:
    // eslint-disable-next-line max-len
    "'Poppins', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif",
  metaFontSize: '11px',
  color: TEXT_COLOR,
  transform: 'uppercase',
  link: {
    color: COLOR.secondary,
    textDecoration: 'none',
    hover: {
      color: COLOR.secondaryA800,
    },
    active: {
      color: COLOR.secondaryA600,
    },
    focus: {
      color: COLOR.secondaryA800,
    },
  },
  actionLink: {
    color: COLOR.primary,
    textDecoration: 'none',
    hover: {
      color: COLOR.primaryA800,
    },
    active: {
      color: COLOR.primaryA600,
    },
    focus: {
      color: COLOR.primaryA800,
    },
  },
};

/**********************
 * Standard Theme Styles
 **********************/
const STANDARD: Theme.Standard = {
  border: `1px solid ${COLOR.secondaryA100}`,
  borderDashed: `1px dashed ${COLOR.secondary}`,
  borderRadius: '4px',
  borderRadiusSm: '2px',
  borderRadiusLg: '8px',
  borderRadiusXl: '20px',
  boxShadow: `0 4px 4px 0 ${COLOR.secondaryA100}`,
  boxShadowSm: `0 1px 6px 1px ${COLOR.secondaryA50}`,
  boxShadowLg: `0 2px 24px 10px ${COLOR.secondaryA50}`,
  backdropColor: COLOR.blackA200,
  backdropBlurColor: COLOR.secondaryA500,
};

/**********************
 * Breakpoints
 **********************/
const BREAKPOINT: Theme.Breakpoint = {
  //
};

/**********************
 * Body
 **********************/
const BODY: Theme.Body = {
  color: TEXT.color.primary,
  background: Utils.formatHex('#f4f4fa'),
};

/**********************
 * Page Content
 **********************/
const CONTENT: Theme.Content = {
  color: TEXT.color.primary,
  background: COLOR.white,
  emptySection: {
    background: COLOR.secondary50,
    color: TEXT_COLOR.tertiary,
    button: {
      color: COLOR.primary,
      background: COLOR.primaryContrast,
    },
  },
  outlinedSection: {
    background: COLOR.primary100,
    border: STANDARD.borderDashed,
    fontSize: '12px',
    fontMargin: '0 0 16px',
  },
  highlightedSection: {
    background: COLOR.primary100,
    color: TEXT.color.primary,
    button: {
      color: COLOR.primary,
      background: COLOR.primaryContrast,
    },
  },
  boldSection: {
    background: COLOR.primary,
    color: COLOR.white,
    button: {
      color: COLOR.primary,
      background: COLOR.primaryContrast,
    },
  },
  input: {
    color: TEXT_COLOR.primary,
    background: COLOR.blackA50,
    border: 'none',
    borderRadius: STANDARD.borderRadius,
    focus: {
      background: '',
      border: `2px solid ${COLOR.primary}`,
    },
    error: {
      background: '',
      border: `2px solid ${COLOR.error}`,
    },
  },
  list: {
    section: {
      margin: '0',
    },
    header: {
      fontSize: '13px',
      height: '40px',
    },
  },
};

/**********************
 * Brandbar Masthead
 **********************/
const BRANDBAR: Theme.Brandbar = {
  color: COLOR.white,
  background: COLOR.primary,
  logo: '',
  active: {
    color: COLOR.secondary,
  },
};

/**********************
 * Priamry Navbar
 **********************/
const NAVBAR: Theme.Navbar = {
  background: COLOR.white,
  color: COLOR.secondary,
  iconColor: COLOR.secondary,
  active: {
    color: COLOR.secondary,
    iconColor: COLOR.secondary,
    fontWeight: 700,
  },
};

/**********************
 * Buttons
 **********************/
const BUTTON_GENERAL: Theme.ButtonGeneral = {
  borderRadius: '999px',
};
const BUTTON_PRIMARY: Theme.Button = {
  color: COLOR.white,
  background: COLOR.primary,
  border: '1px solid transparent',
  hover: {
    color: COLOR.primaryContrast,
    background: COLOR.primary400,
    border: '',
  },
  active: {
    color: COLOR.primaryContrast,
    background: COLOR.primary600,
    border: '',
  },
  disabled: {
    color: COLOR.white,
    background: COLOR.primary200,
    border: '',
  },
};
const BUTTON_PRIMARY_ERROR: Theme.Button = {
  color: COLOR.primaryContrast,
  background: COLOR.error,
  border: '1px solid transparent',
  hover: {
    color: COLOR.primaryContrast,
    background: COLOR.error400,
    border: '',
  },
  active: {
    color: COLOR.primaryContrast,
    background: COLOR.error600,
    border: '',
  },
  disabled: {
    color: COLOR.primaryContrast,
    background: COLOR.error200,
    border: '',
  },
};
const BUTTON_PRIMARY_SUCCESS: Theme.Button = {
  color: COLOR.primaryContrast,
  background: COLOR.success,
  border: '1px solid transparent',
  hover: {
    color: COLOR.primaryContrast,
    background: COLOR.success400,
    border: '',
  },
  active: {
    color: COLOR.primaryContrast,
    background: COLOR.success600,
    border: '',
  },
  disabled: {
    color: COLOR.primaryContrast,
    background: COLOR.success200,
    border: '',
  },
};
const BUTTON_SECONDARY: Theme.Button = {
  color: COLOR.white,
  background: COLOR.primary,
  border: `1px solid ${COLOR.primary}`,
  hover: {
    color: COLOR.primary,
    background: COLOR.primary100,
    border: '',
  },
  active: {
    color: COLOR.primary600,
    background: COLOR.white,
    border: `1px solid ${COLOR.primary600}`,
  },
  disabled: {
    color: COLOR.white,
    background: COLOR.primary200,
    border: `1px solid ${COLOR.primary200}`,
  },
};
const BUTTON_TERTIARY: Theme.Button = {
  color: COLOR.primary,
  background: 'transparent',
  border: 'none',
  hover: {
    color: COLOR.primary400,
    background: '',
    border: '',
  },
  active: {
    color: COLOR.primary600,
    background: '',
    border: '',
  },
  disabled: {
    color: COLOR.primary200,
    background: '',
    border: '',
  },
};

const TABS: Theme.TABS = {
  inactive: COLOR.secondaryA100,
  activeGradientTint: COLOR.primary,
};

const CHARTS: Theme.ChartStyles = {
  data: {
    strokeWidth: 4,
    high: {
      // TODO: some day this will have a better reference than error
      color: COLOR.error,
    },
    mid: {
      color: COLOR.primary,
    },
    low: {
      color: COLOR.info300,
    },
  },
  ticks: {
    color: COLOR.info100,
    strokeWidth: 1,
  },
  labels: {
    color: COLOR.grey600,
  },
};

/**********************
 * Final Theme Config
 **********************/
export const themeConfig: Theme.ThemeConfig = {
  ...THEME_INFO,
  standard: STANDARD,
  color: COLOR,
  text: TEXT,
  breakpoint: BREAKPOINT,
  body: BODY,
  content: CONTENT,
  brandbar: BRANDBAR,
  navbar: NAVBAR,
  button: {
    ...BUTTON_GENERAL,
    primary: BUTTON_PRIMARY,
    primaryError: BUTTON_PRIMARY_ERROR,
    primarySuccess: BUTTON_PRIMARY_SUCCESS,
    secondary: BUTTON_SECONDARY,
    tertiary: BUTTON_TERTIARY,
  },
  tabs: TABS,
  charts: CHARTS,
};
