/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import {WithComponentPropsWithoutRef} from 'types';
import {ButtonSize, ButtonVariation} from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import {trackStructuredEvent} from 'utils';
import {ButtonContentWithIcon} from 'components/shared/ButtonContentWithIcon';

export default function DeviceConnectionBillboard({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const ATTRIBUTE_KEY = 'BILLBOARD_DEVICE_CONNECTION_COLLAPSED';
  const isCollapsed: boolean = globalState?.user?.attributes[ATTRIBUTE_KEY] === '1';

  async function handleOnCollapse(isNowCollapsed: boolean) {
    if (!isNowCollapsed) {
      trackStructuredEvent({
        category: 'billboard',
        action: 'view',
        label: 'device_limbo_without_offer',
      });
    }
    await setUserAttributes({[ATTRIBUTE_KEY]: isNowCollapsed ? '1' : '0'});
  }

  return (
    <>
      <PopupAnnouncement
        {...props}
        options={{
          header: 'Link devices and save more energy',
          message: '',
          includeIcon: false,
          dismissable: false,
          collapsable: true,
          isCollapsed: isCollapsed,
          collapseLabel: 'Hide',
          uncollapseLabel: 'Link Your Devices',
          onCollapse: handleOnCollapse,
          align: 'center',
          ctaDirection: 'column',
          ctas: [
            {
              to: '/devices/add',
              confirmTrackId: '',
              onConfirm: () => {
                trackStructuredEvent({
                  category: 'billboard',
                  label: 'device_limbo_add_device',
                  action: 'click',
                });
              },
              content: (
                <ButtonContentWithIcon
                  iconVariation={Icon.variations.DEVICES}
                  label="Link your devices"
                  showCarat={true}
                />
              ),
              variation: ButtonVariation.PRIMARY,
              size: ButtonSize.STANDARD,
            },
          ],
        }}
      />
    </>
  );
}
