/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const OHM_REVIEWAPP_ENV: EnvConfig = {
  ...(require('./ohm.beta.env').OHM_BETA_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Ohm Reviewapp',
  backendBaseUrl: 'https://login.ohmconnect.com',
};
