/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function ShippingBoxOutline({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1984 19.9999L20.0022 38.9998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0043 28.7473V11.2527C37.0043 10.9909 36.9348 10.7338 36.8028 10.5076C36.6709 10.2815 36.4813 10.0945 36.2533 9.96563L20.7296 1.19137C20.5077 1.06592 20.2571 1 20.0021 1C19.7472 1 19.4966 1.06592 19.2747 1.19137L3.75097 9.96563C3.52304 10.0945 3.33341 10.2815 3.20147 10.5076C3.06953 10.7338 3 10.9909 3 11.2527V28.7473C3 29.0091 3.06952 29.2662 3.20147 29.4924C3.33341 29.7185 3.52304 29.9055 3.75097 30.0344L19.2747 38.8086C19.4966 38.9341 19.7472 39 20.0021 39C20.2571 39 20.5077 38.9341 20.7296 38.8086L36.2533 30.0344C36.4813 29.9055 36.6709 29.7185 36.8028 29.4924C36.9348 29.2662 37.0043 29.0091 37.0043 28.7473Z"
        fill="currentColor"
        fillOpacity="0"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.802 10.5059L20.1984 20L3.20386 10.5039"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0826 23.7905V14.9198L11.9365 5.33911"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
