/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function ReferralMadness({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="68"
      height="44"
      viewBox="0 0 68 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.4995 30.636C32.6274 29.5112 28.0925 28.5252 30.2884 24.3772C36.9753 11.7518 32.0607 5 25.0013 5C17.8015 5 13.0102 12.0111 19.7141 24.3772C21.9766 28.5492 17.2702 29.5352 12.503 30.636C8.14944 31.6418 7.98652 33.8037 8.00069 37.5833L8.00636 39H41.9933L41.999 37.6273C42.016 33.8193 41.8687 31.6461 37.4995 30.636Z"
        fill="currentColor"
      />
      <path
        d="M46.1807 16.824L49.8987 24H44.9067L42.8267 19.996L39.7847 24H34.9747L41.0587 16.59L37.3927 9.492H42.3847L44.4647 13.522L47.5067 9.492H52.3167L46.1807 16.824Z"
        fill="currentColor"
      />
      <path
        d="M53.4318 19.71C55.3212 18.358 56.8292 17.24 57.9558 16.356C59.0825 15.472 60.0532 14.5707 60.8678 13.652C61.6998 12.716 62.1852 11.8233 62.3238 10.974C62.3758 10.7313 62.4018 10.506 62.4018 10.298C62.4018 9.79533 62.2632 9.414 61.9858 9.154C61.7258 8.894 61.3272 8.764 60.7898 8.764C59.4552 8.764 58.6058 9.70867 58.2418 11.598H53.9258C54.3765 9.414 55.3125 7.776 56.7338 6.684C58.1725 5.57467 59.8192 5.02 61.6738 5.02C63.4765 5.02 64.8112 5.42733 65.6778 6.242C66.5618 7.05667 67.0038 8.13133 67.0038 9.466C67.0038 9.882 66.9692 10.2633 66.8998 10.61C66.5878 12.378 65.6692 14.0247 64.1438 15.55C62.6358 17.0753 60.7638 18.6007 58.5278 20.126H65.5478L64.8978 23.74H51.8458L52.4178 20.438L53.4318 19.71Z"
        fill="currentColor"
      />
    </svg>
  );
}
