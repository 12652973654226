/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {get} from 'lodash';

import {captureException, trackStructuredEvent} from 'utils';
import {useGlobalDispatch, ActionType} from 'store';
import {FlashedMessageLevel, FlashedMessages} from 'api';

const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong. Please refresh or try again.';

/**
 * This function is pretty flexible, and does two distinct things:
 * 1. If `capture` is passed in (which can be either a string or an exception), it will capture
 * it via our `captureException` function (currently will pass to sentry). It will also pass in
 * any `extra` values (`extra` should be an object).
 * 2. Flashes an error message to the user. We'll always do this when this function is called.
 * The message that we flash will be (in priority order):
 *    1. The `message` argument
 *    2. If that doesn't exist, we'll try to pull `response.data.error` out of `capture` or `error`.
 *       This is common for AJAX responses, so it's convenient to be able to pass those in directly.
 *    3. If `response.data.error` doesn't exist, we'll show the default generic error message.
 * Here are a couple examples:
 *    * `flashError({message: "That feature didn't work. Please try again", capture: error})` will
 *      show the `message` passed in as a flash message, and will send the captured exception to
 *      sentry via `captureException`.
 *    * `flashError({message: "Some message"}) will show the `message` as a flash message.
 *    * `flashError({capture: error})` will try to pull `response.data.error` out of `capture`.
 *      If it's a typical AJAX response that contains an `error` key it will use that as the flash
 *      message. Otherwise it will use the default error message as the flash message.
 */
export function useFlashError() {
  const globalDispatch = useGlobalDispatch();

  return useCallback(
    (args?: FlashErrorArgs) => {
      const {message, error, capture, extra, source} = args || {};
      // If `capture` is passed in, capture the exception/string
      if (capture) captureException(capture, extra);

      const responseError = get(capture || error || {}, 'response.data.error', null);
      const errorText = message || responseError || DEFAULT_ERROR_MESSAGE;

      // Display a flash message (see above explanation of how we determine what message to show)
      globalDispatch({
        type: ActionType.ADD_FLASH_MESSAGE,
        payload: {
          type: FlashedMessageLevel.ERROR,
          text: errorText,
        },
      });

      trackStructuredEvent({
        action: 'flash_error',
        category: source || '',
        label: responseError || '',
        property: errorText,
        value: capture ? 1 : 0,
      });
      return;
    },
    [globalDispatch],
  );
}

export interface FlashErrorArgs {
  /** message to display to user */
  message?: string;
  /** error payload */
  error?: unknown;
  /** payload to be captured */
  capture?: unknown;
  /** extra data to capture along with the error */
  extra?: unknown;
  /** source component of the error */
  source: string;
}

export function useFlashInfo() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    (message?: string) => {
      globalDispatch({
        type: ActionType.ADD_FLASH_MESSAGE,
        payload: {
          type: FlashedMessageLevel.INFO,
          text: message,
        },
      });
      return;
    },
    [globalDispatch],
  );
}

export function useFlashSuccess() {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    (message?: string) => {
      globalDispatch({
        type: ActionType.ADD_FLASH_MESSAGE,
        payload: {
          type: FlashedMessageLevel.SUCCESS,
          text: message,
        },
      });
      return;
    },
    [globalDispatch],
  );
}

/**
 * Handles showing the appropriate messages and message levels
 * from a map of message strings received from api
 */
export function useFlashMessages() {
  const flashInfo = useFlashInfo();
  const flashSuccess = useFlashSuccess();
  const flashError = useFlashError();

  return useCallback(
    (messages: FlashedMessages, captureException: boolean = false) => {
      messages?.error?.forEach(m =>
        flashError({
          ...(captureException ? {capture: m} : {message: m}),
          source: 'useFlashMessages',
        }),
      );
      messages?.success?.forEach(m => flashSuccess(m));
      messages?.info?.forEach(m => flashInfo(m));
    },
    [flashError, flashSuccess, flashInfo],
  );
}
