/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */

/**
 * Converts HEX color values into an array of RGBA number values
 * @param hex #-prefixed hex color value. Accepts either 6-digit or shortened 3-digit hex values
 * @param alpha optional alpha value, as a decimal value between 0 and 1
 * @returns {[r:number,g:number,b:number,a:number]} array of RGBA values
 */
export function hexToRgbaValues(hex: string, alpha = 1): number[] {
  const rgbArr = (hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g) as RegExpMatchArray).map(x => parseInt(x, 16));

  return [...rgbArr, alpha];
}

/**
 * Converts and array of RGBA number values into a HEX color values
 * @param {[r:number,g:number,b:number,a:number]} rgbaArr arry of RGBA values
 * @returns #-prefixed hex color value
 */
export function rgbaValuesToHex(rgbaArr: number[]): string {
  const [r, g, b, a] = rgbaArr;
  const hexR = Math.round(r).toString(16);
  const hexG = Math.round(g).toString(16);
  const hexB = Math.round(b).toString(16);
  const hexA = Math.round(a * 255).toString(16);

  return (
    '#' +
    (hexR.length === 1 ? '0' + hexR : hexR) +
    (hexG.length === 1 ? '0' + hexG : hexG) +
    (hexB.length === 1 ? '0' + hexB : hexB) +
    '/' +
    (hexA.length === 1 ? '0' + hexA : hexA)
  );
}

/**
 * Converts and array of RGBA number values into a HEX color values
 * @param {[r:number,g:number,b:number,a:number]} rgbaArr arry of RGBA values
 * @returns #-prefixed hex color value
 */
export function rgbaValuesToHslaValues(rgbaArr: number[]): number[] {
  const [r, g, b, a] = rgbaArr;
  const rPct = r / 255;
  const gPct = g / 255;
  const bPct = b / 255;

  const max = Math.max(rPct, gPct, bPct);
  const min = Math.min(rPct, gPct, bPct);

  const d = max - min;
  const hsl = {h: 0, s: 0, l: (max + min) / 2};
  hsl.s = (hsl.l > 0.5 ? d / (2 - max - min) : d / (max + min)) || 0;

  if (max === min) {
    // achromatic
  } else {
    switch (max) {
      case rPct:
        hsl.h = (gPct - bPct) / d + (gPct < bPct ? 6 : 0);
        break;
      case gPct:
        hsl.h = (bPct - rPct) / d + 2;
        break;
      case bPct:
        hsl.h = (rPct - gPct) / d + 4;
        break;
    }
  }
  hsl.h = Math.round(hsl.h * 60);
  if (hsl.h < 0) {
    hsl.h += 360;
  }

  return [hsl.h, hsl.s, hsl.l, a];
}

/**
 * Format an array of RGBA values into a CSS rgba(x, x, x, x) string
 * @param {[r:number,g:number,b:number,a:number]} rgbaArr arry of RGBA values
 * @returns CSS rgba(x, x, x, x) formatted string
 */
export function formatRgba(rgbaArr: number[]): string {
  const [r = 0, g = 0, b = 0, a = 1] = rgbaArr;
  return 'rgba(' + [Math.round(r), Math.round(g), Math.round(b), a].join(', ') + ')';
}

/**
 * Unformat a css rgba string into an array of RGBA values
 * @param rgbaCssString CSS rgba(x, x, x, x) formatted string
 * @returns {[r:number,g:number,b:number,a:number]} arry of RGBA values
 */
export function unformatRgba(rgbaCssString: string): number[] {
  return (
    rgbaCssString
      .slice(5, -1)
      .split(', ')
      // convert to number
      .map(x => +x)
  );
}

/**
 * Format a hex value into a CSS rgba(x, x, x, x) string
 * @param hex #-prefixed hex color value. Accepts either 6-digit or shortened 3-digit hex values
 * @param alpha optional alpha value, as a decimal value between 0 and 1
 * @returns CSS rgba(x, x, x, x) formatted string
 */
export function formatHex(hex: string, alpha = 1): string {
  return formatRgba(hexToRgbaValues(hex, alpha));
}

/**
 * Returns either a light color or a dark color based on the general lightness of the reference color
 * @param {[r:number,g:number,b:number]} refRgbArr array of RGB values to reference
 * @param light light color to possibly return
 * @param dark dark color to possibly return
 * @returns passes on either the light or dark input values
 */
export function contrastColor<Tlight, Tdark>(
  refRgbArr: number[],
  light?: Tlight,
  dark?: Tdark,
): Tlight | Tdark | string {
  const [r, g, b] = refRgbArr;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? dark || 'black' : light || 'white';
}

/**
 * Returns either a light color or a dark color based on the general lightness of the reference color
 * @param rgbaCssString array of RGB values to reference
 * @param light light color to possibly return
 * @param dark dark color to possibly return
 * @returns passes on either the light or dark input values
 */
export function cssContrastColor<Tlight, Tdark>(
  rgbaCssString: string,
  light?: Tlight,
  dark?: Tdark,
): Tlight | Tdark | string {
  return contrastColor(unformatRgba(rgbaCssString), light, dark);
}

/**
 * Updates an RGBA css string with a new alpha value
 * @param rgbaCssString RGBA css string in the format rgba(x, x, x, x)
 * @param alpha new alpha value, as a decimal value between 0 and 1
 * @returns RGBA css string in the format rgba(x, x, x, x)
 */
export function realpha(rgbaCssString: string, alpha = 1): string {
  const [r = 0, g = 0, b = 0] = unformatRgba(rgbaCssString);
  return formatRgba([r, g, b, alpha]);
}

/**
 * Given an RGB value and a shade factor, calculates the shaded RGB values
 * @param {[r:number,g:number,b:number]} rgbArr array of RGB values
 * @param shadeFactor number between 0 and 1 representing the shade factor.
 * 0 represents no shade (source color), 1 represents full shade (full black)
 * @returns {[r:number,g:number,b:number]} array of shaded RGB values
 */
export function rgbShade(rgbArr: number[], shadeFactor = 0): number[] {
  const [currentR = 0, currentG = 0, currentB = 0] = rgbArr;
  const newR = +(currentR * (1 - shadeFactor)).toFixed(2);
  const newG = +(currentG * (1 - shadeFactor)).toFixed(2);
  const newB = +(currentB * (1 - shadeFactor)).toFixed(2);
  return [newR, newG, newB];
}

/**
 * Given an RGB value and a tint factor, calculates the tinted RGB values
 * @param {[r:number,g:number,b:number]} rgbArr array of RGB values
 * @param tintFactor number between 0 and 1 representing the tint factor.
 * 0 represents no tint (source color), 1 represents full tint (full white)
 * @returns {[r:number,g:number,b:number]} array of tinted RGB values
 */
export function rgbTint(rgbArr: number[], tintFactor = 0): number[] {
  const [currentR = 0, currentG = 0, currentB = 0] = rgbArr;
  const newR = +(currentR + (255 - currentR) * tintFactor).toFixed(2);
  const newG = +(currentG + (255 - currentG) * tintFactor).toFixed(2);
  const newB = +(currentB + (255 - currentB) * tintFactor).toFixed(2);
  return [newR, newG, newB];
}

/**
 * Determines the resulting color from alpha blending of two RGB colors
 * @param {[r:number,g:number,b:number]} rgbBackground array of RGB values, to serve as the base color
 * @param {[r:number,g:number,b:number,a:number]} rgbaForeground array of RGBA, to blend with the base
 * @returns {[r:number,g:number,b:number]} array of blended RGB values
 */
export function rgbAlphaBlend(rgbBackground: number[], rgbaForeground: number[]): number[] {
  const [bgR = 0, bgG = 0, bgB = 0] = rgbBackground;
  const [fgR = 0, fgG = 0, fgB = 0, fgA = 1] = rgbaForeground;
  const newR = +(bgR + (fgR - bgR) * fgA).toFixed(2);
  const newG = +(bgG + (fgG - bgG) * fgA).toFixed(2);
  const newB = +(bgB + (fgB - bgB) * fgA).toFixed(2);
  return [newR, newG, newB];
}
