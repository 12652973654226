/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const ORG_BETA_ENV: EnvConfig = {
  ...(require('./org.production.env').ORG_PRODUCTION_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Origin Beta',
  backendBaseUrl: 'https://api.beta.spike.originenergy.com.au',
  ssoLoginUrl: 'https://api.beta.spike.originenergy.com.au/connect/origin',
  cookieDomain: '.originenergy.com.au',
  // Note: Key below is copied from backend config.
  // For now, let it fall through from production
  // googleRecaptchaSiteKey: '6LflkuQUAAAAAG4Ccn2faUmcOvpE9DX7Lem0_DcJ',
  // Link to go "back" to an external utility account dashboard from our dashboard
  dashboardAccountLink: 'https://staticsite-cdn-staging.origindigital-pac.com.au/my',
  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '2c7cc792-bcb3-42f2-8bf6-88c2102d1c6f',
};
