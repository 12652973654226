/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Refresh({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3333 5.55945C23.8247 5.8659 29 11.2061 29 17.7652C29 24.5211 23.5143 30 16.75 30C10.6274 30 5.55238 25.5114 4.64453 19.6499C4.4846 18.6174 5.34703 17.7652 6.39189 17.7652C7.28002 17.7652 7.98453 18.4917 8.14686 19.3648C8.90125 23.4227 12.4716 26.5043 16.75 26.5043C21.5753 26.5043 25.5 22.5845 25.5 17.7652C25.5 13.1427 21.8857 9.35807 17.3333 9.05511L17.3333 14.3362C17.3333 14.7866 16.7846 15.0074 16.4726 14.6825L9.40576 7.32126L16.4941 0.775042C16.8144 0.47928 17.3333 0.706428 17.3333 1.14236L17.3333 5.55945Z"
        fill="currentColor"
      />
    </svg>
  );
}
