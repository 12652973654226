/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Outlet} from 'react-router-dom';
import {AppTopBrandBar, AppTopBrandBarProps} from '../AppTopBrandBar';
import {PageContainer} from '../containers';
import {TopBrandBarBackButton} from '../TopBrandBarBackButton';
import styled, {css} from 'styled-components';
import {ProfileSettingsLink} from '../ProfileSettingsLink';
import {BootstrapStyles} from '../../internal/BootstrapStyles';

const StyledPageContainer = styled(PageContainer)`
  ${({theme}) => css`
    text-align: center;
    color: ${theme.text.color.primary};
    background: ${theme.body.background};
    box-sizing: border-box;
    line-height: 1.2;
  `}
`;

export const InternalLayout: React.FC<AppTopBrandBarProps> = ({
  children,
  className,
  leftContent,
  maxContentWidth = 'unset',
  ...props
}) => {
  return (
    <>
      <BootstrapStyles />
      <AppTopBrandBar
        {...props}
        leftContent={leftContent ?? <TopBrandBarBackButton />}
        rightContent={
          <>
            <div style={{paddingRight: '8px'}}>INTERNAL</div>
            <ProfileSettingsLink />
          </>
        }
        maxContentWidth={maxContentWidth}
        isInverted
      />
      <StyledPageContainer className={className} maxContentWidth={maxContentWidth}>
        <Outlet />
        {children}
      </StyledPageContainer>
    </>
  );
};
