/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Home({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.0001 6.30579L3.44055 13.3622C3.44055 13.3721 3.43805 13.3868 3.43305 13.4068C3.42814 13.4266 3.42554 13.441 3.42554 13.4512V20.5965C3.42554 20.8545 3.51986 21.0781 3.70845 21.2664C3.89699 21.4548 4.12025 21.5496 4.3783 21.5496H10.0944V15.8331H13.9057V21.5498H19.6218C19.8798 21.5498 20.1034 21.4552 20.2917 21.2664C20.4802 21.0783 20.5749 20.8546 20.5749 20.5965V13.4512C20.5749 13.4116 20.5695 13.3816 20.5599 13.3622L12.0001 6.30579Z"
          fill="currentColor"
        />
        <path
          d="M23.8345 11.7544L20.5746 9.04506V2.9714C20.5746 2.83255 20.5299 2.71836 20.4404 2.62899C20.3515 2.53973 20.2373 2.4951 20.0982 2.4951H17.24C17.101 2.4951 16.9869 2.53973 16.8975 2.62899C16.8083 2.71836 16.7637 2.8326 16.7637 2.9714V5.8742L13.1315 2.83729C12.8143 2.57925 12.4371 2.45026 12.0004 2.45026C11.5637 2.45026 11.1866 2.57925 10.869 2.83729L0.165418 11.7544C0.066195 11.8336 0.0118128 11.9403 0.00169755 12.0743C-0.00836551 12.2082 0.0263078 12.3252 0.105822 12.4243L1.02876 13.5259C1.10827 13.6152 1.21234 13.6698 1.34139 13.6898C1.46053 13.6998 1.57967 13.6649 1.69881 13.5856L12.0001 4.9959L22.3014 13.5855C22.381 13.6548 22.485 13.6893 22.614 13.6893H22.6588C22.7876 13.6697 22.8915 13.6148 22.9714 13.5257L23.8944 12.4243C23.9738 12.3249 24.0086 12.2081 23.9983 12.0741C23.9881 11.9405 23.9335 11.8338 23.8345 11.7544Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
