/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

import {captureException} from 'utils';
import {ErrorContainer} from 'components/shared/styled';
import {ENV} from 'config/globals';

// https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // we're catching at the top level of the app, so any exception is a crasher.
    // in the future, we may want to wrap specific parts of the UI in error
    // boundaries, and allow specific widgets to crash and retry without blocking
    // the whole app; in that case, log level should likely be 'error' instead.
    captureException(error, errorInfo, 'fatal');
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <h2>Oops, something went wrong.</h2>
          <p>You can reload the page and try again.</p>
          <p>
            If the problem continues, you can let us know <a href={ENV.askShortUrl}>here</a>.
          </p>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}
