/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const VIVINT_STAGING_ENV: EnvConfig = {
  ...(require('./vivint.beta.env').VIVINT_BETA_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Vivint Staging',
  backendBaseUrl: 'https://login.ohmconnect.com',
};
