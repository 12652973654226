/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const RewardsLine: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.99982"
        y="15.5"
        width="18"
        height="9.99995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5"
        y="10.5"
        width="20"
        height="4.99997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10.5V25.4999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.5C15 5.84315 16.3432 4.5 18 4.5V4.5C19.6569 4.5 21 5.84314 21 7.49998V7.49998C21 9.15683 19.6569 10.5 18 10.5H15V7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00018 7.49998C9.00018 5.84314 10.3433 4.5 12.0002 4.5V4.5C13.657 4.5 15.0002 5.84315 15.0002 7.5V10.5H12.0002C10.3433 10.5 9.00018 9.15683 9.00018 7.49998V7.49998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
