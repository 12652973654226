/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
export const fadeInOut = {
  in: {opacity: 1},
  out: {opacity: 0},
};

export const crossfade = {
  enter: {opacity: 0},
  visible: {opacity: 1, transition: {ease: 'easeOut', duration: 0.5}},
  exit: {opacity: 0},
};

export const slideInOut = {
  enter: dir => ({x: dir === 'right' ? '-100%' : '100%'}),
  visible: {x: 0, transition: {ease: 'easeOut', duration: 0.5}},
  exit: dir => ({x: dir === 'right' ? '100%' : '-100%'}),
};

export const slideList = {
  in: {
    x: 0,
    transition: {
      duration: 0.15,
    },
  },
  out: {
    x: '-100%',
    transition: {
      duration: 0.15,
    },
  },
};

export const slideDetail = {
  in: {
    x: 0,
    transition: {
      duration: 0.15,
    },
  },
  out: {
    x: '100%',
    transition: {
      duration: 0.15,
    },
  },
};

export const flash = {
  enter: {opacity: 0, scale: 0.85, transition: {duration: 0.2}},
  visible: {opacity: 1, scale: 1},
  exit: exitSpeed => ({
    opacity: 0,
    // milliseconds to seconds
    transition: {duration: exitSpeed * 0.001},
  }),
};

export const splash = {
  enter: {opacity: 0, scale: 3},
  visible: {opacity: 1, scale: 1},
  exit: {opacity: 0, scale: 1.5},
};
