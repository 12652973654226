/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Unsupported({className}) {
  return (
    <svg
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M8.378 9.298V1.55c0-.855.694-1.55 1.55-1.55.854 0 1.549.695 1.549 1.55v7.748c0 .855-.695 1.55-1.55 1.55-.855 0-1.55-.695-1.55-1.55zm7.692-7.026c-.502-.397-1.235-.31-1.632.193-.398.503-.311 1.234.192 1.632 1.844 1.455 2.9 3.632 2.9 5.976 0 4.192-3.41 7.603-7.602 7.603s-7.603-3.411-7.603-7.603c0-2.343 1.056-4.52 2.897-5.973.504-.398.59-1.128.193-1.633-.398-.503-1.127-.589-1.633-.192C1.378 4.172 0 7.014 0 10.073 0 15.547 4.454 20 9.927 20c5.474 0 9.927-4.454 9.927-9.927 0-3.06-1.38-5.903-3.784-7.8z"
      />
    </svg>
  );
}
