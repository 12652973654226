/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Alert({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.3544 0 0 3.3547 0 7.5C0 11.6456 3.3547 15 7.5 15C11.6456 15 15 11.6453 15 7.5C15 3.3544 11.6453 0 7.5 0ZM7.5 13.8281C4.00216 13.8281 1.17188 10.9976 1.17188 7.5C1.17188 4.00216 4.00239 1.17188 7.5 1.17188C10.9978 1.17188 13.8281 4.00239 13.8281 7.5C13.8281 10.9978 10.9976 13.8281 7.5 13.8281Z"
        fill="currentColor"
      />
      <path
        d="M7.5 3.7757C7.17639 3.7757 6.91406 4.03802 6.91406 4.36163V8.13487C6.91406 8.45848 7.17639 8.7208 7.5 8.7208C7.82361 8.7208 8.08594 8.45848 8.08594 8.13487V4.36163C8.08594 4.03802 7.82361 3.7757 7.5 3.7757Z"
        fill="currentColor"
      />
      <path
        d="M7.5 11.0203C7.93687 11.0203 8.29102 10.6662 8.29102 10.2293C8.29102 9.79244 7.93687 9.43829 7.5 9.43829C7.06313 9.43829 6.70898 9.79244 6.70898 10.2293C6.70898 10.6662 7.06313 11.0203 7.5 11.0203Z"
        fill="currentColor"
      />
    </svg>
  );
}
