/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const NRG_STAGING_ENV: EnvConfig = {
  ...(require('./nrg.beta.env').NRG_BETA_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Nrg Staging',
  backendBaseUrl: 'https://login.ohmconnect.com',
};
