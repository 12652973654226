import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace FriendBuy {
  /**********
   * Interactions
   **********/

  /**
   * Get FriendBuy auth data
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getAuthData<TResponse = AuthData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/friendbuy/auth`, config);
  }

  /**********
   * Types
   **********/

  export interface AuthData {
    authString: string;
    signature: string;
  }
}
