/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function BoltLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5257 2.05646C13.5516 1.71322 13.3405 1.39641 13.0138 1.2881C12.6871 1.17979 12.3285 1.30778 12.1443 1.59852L4.4638 13.7177C4.10514 14.2837 4.51175 15.0227 5.18177 15.0227H10.9968L10.4744 21.9435C10.4484 22.2868 10.6595 22.6036 10.9862 22.7119C11.3129 22.8202 11.6715 22.6922 11.8557 22.4015L19.5362 10.2823C19.8949 9.71634 19.4882 8.97727 18.8182 8.97727H13.0032L13.5257 2.05646ZM18.2692 9.47933L18.2688 9.48004L18.2692 9.47933Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
