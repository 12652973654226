import {ApolloClient, createHttpLink, InMemoryCache, from, ApolloLink} from '@apollo/client';
import {ENV} from 'config/globals';

// generated by Fragment Matcher plugin
import generatedIntrospection from '../fragment-matcher.generated';

export const httpLink = createHttpLink({
  // ON-7826: moved internal graphql api path
  uri: `${ENV.backendBaseUrl}/graphql/internal`,
  credentials: 'include',
});

export const csrfMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({headers = {}}) => {
    return {
      headers: {
        ...headers,
        'X-CSRFToken': getCookie('csrf_token'),
      },
    };
  });
  return forward(operation);
});

/**
 * Build an Ohm-specific cache, as a factory to easily reuse in testing
 */
export const getOhmCache = () =>
  new InMemoryCache({
    possibleTypes: {
      ...generatedIntrospection.possibleTypes,
    },
  });

const ohmApolloCache = getOhmCache();

export const ohmApolloClient = new ApolloClient({
  cache: ohmApolloCache,
  link: from([csrfMiddleware, httpLink]),
});

// eslint-disable-next-line max-len
// swiped this from https://stackoverflow.com/questions/66894641/configuring-csrf-tokens-with-apollo-client-and-graphene-django
function getCookie(name: string) {
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      // Does this cookie string begin with the name we want?
      if (cookie.trim().startsWith(name + '=')) {
        return decodeURIComponent(cookie.trim().substring(name.length + 1));
      }
    }
  }
  return undefined;
}
