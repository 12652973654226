import {Country} from 'config/regions';

export const postCodeLength: Record<Country, number> = {
  [Country.AU]: 4,
  [Country.US]: 5,
  [Country.CA]: 6,
};

export const postCodeMaxLength: Record<Country, number> = {
  [Country.AU]: 4,
  [Country.US]: 5,
  [Country.CA]: 7, // Up to 7 with optional space separator
};

export const postCodePattern: Record<Country, string> = {
  [Country.AU]: '\\d*',
  [Country.US]: '\\d*',
  [Country.CA]: '[A-Za-z\\d \\-]*',
};

export const postCodeValidation: Record<Country, RegExp> = {
  [Country.AU]: /^\d{4}$/,
  [Country.US]: /^\d{5}$/,
  [Country.CA]: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
};

export const postCodeLabel: Record<Country, string> = {
  [Country.AU]: 'Postcode',
  [Country.US]: 'Zip Code',
  [Country.CA]: 'Postal Code',
};

export const postCodeType: Record<Country, string> = {
  [Country.AU]: 'digit',
  [Country.US]: 'digit',
  [Country.CA]: 'character',
};

export const provinceLabel: Record<Country, string> = {
  [Country.AU]: 'Province',
  [Country.US]: 'State',
  [Country.CA]: 'Province',
};
