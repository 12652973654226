/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Close({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.34659 26.6528C3.57678 26.8848 3.87975 27 4.18272 27C4.48541 27 4.78838 26.8833 5.02031 26.6528L14.5005 17.1729L23.9814 26.6528C24.2119 26.8848 24.5146 27 24.8176 27C25.1188 27 25.4215 26.8833 25.6534 26.6528C26.1155 26.1907 26.1155 25.4416 25.6534 24.9795L16.1736 15.4998L25.6534 6.02025C26.1155 5.55786 26.1155 4.80868 25.6534 4.34658C25.1913 3.88447 24.4421 3.88447 23.98 4.34658L14.5001 13.8262L5.02031 4.34658C4.5579 3.88447 3.8087 3.88447 3.34659 4.34658C2.88447 4.80868 2.88447 5.55786 3.34659 6.02025L12.8268 15.4995L3.34659 24.9795C2.88447 25.4416 2.88447 26.1907 3.34659 26.6528Z"
        fill="currentColor"
      />
    </svg>
  );
}
