/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {NavLink, matchPath, useLocation, NavLinkProps, To, resolvePath} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {ComponentPropsWithoutRef} from '../../../types';

const StyledNavBar = styled.nav`
  ${({theme}) => css`
    background: ${theme.navbar.background};
    box-shadow: 0px -4px 4px ${theme.color.secondaryA100};
    height: 60px;
    position: sticky;
    bottom: 0;
    flex: none;
    width: 100%;
    z-index: 2;

    /* iOS only */
    @supports (-webkit-touch-callout: none) {
      /* hacky way to know if the Safari toolbar is hidden;
        this covers save-to-homescreen launched instances
        as well as Safari; we could use display-mode: standalone
        except that doesn't trigger in Safari when the toolbar is hidden
        (tradeoff: we get the extra height in iOS Firefox all the time) */
      @media (height: 100vh), (orientation: landscape), (display-mode: standalone) {
        height: 80px;
      }
    }
  `}
`;

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  max-width: 800px;
  padding: 0;

  @media (min-width: 321px) {
    justify-content: space-evenly;
  }
`;

const NavItem = styled.li`
  flex: 0 0 64px;
  height: 100%;

  @media (min-width: 576px) {
    flex-basis: 66px;
  }
`;

const NavItemLink = styled(NavLink)<{$isActive?: boolean}>`
  ${({theme, $isActive}) => css`
    color: ${$isActive ? theme.navbar.active.color : theme.navbar.color};
    display: block;
    font-size: 12px;
    font-weight: ${$isActive ? theme.navbar.active.fontWeight : '500'};
    height: 100%;
    padding: 4px 0 6px;
    text-align: center;
    text-transform: ${theme.text.transform};
    width: 100%;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: ${theme.navbar.color};
    }
  `}
`;

const NavIcon = styled.div<{$isActive?: boolean}>`
  ${({theme, $isActive}) => css`
    color: ${$isActive ? theme.navbar.active.iconColor : theme.navbar.iconColor};
    display: block;
    height: 30px;
    margin: 0 auto 2px;
    width: 30px;
  `}
`;

export type BottomNavLink = NavLinkProps &
  React.HTMLAttributes<HTMLElement> & {
    label: string;
    icon?: React.FC;
    activeIcon?: React.FC;
    extraPaths?: To[];
  };

export type BottomNavBarProps = ComponentPropsWithoutRef<typeof StyledNavBar> & {
  navLinks: BottomNavLink[];
};
export const BottomNavBar = React.forwardRef<HTMLElement, BottomNavBarProps>(
  ({navLinks, ...props}, ref) => {
    const {pathname} = useLocation();

    function isActivePath(navLink: BottomNavLink): boolean {
      // merge route path with optional extraPaths list
      const paths = navLink.extraPaths ? [navLink.to, ...navLink.extraPaths] : [navLink.to];
      return paths.some(p =>
        matchPath({path: resolvePath(p).pathname, end: navLink.end ?? false}, pathname),
      );
    }

    return (
      <StyledNavBar {...props} ref={ref}>
        <NavList>
          {navLinks.map((link, idx) => (
            <NavItem key={`${idx}_${link.label}`}>
              <NavItemLink
                to={link.to}
                end={link.end}
                data-clicktrackid="primary_nav_link_click"
                $isActive={isActivePath(link)}
              >
                {link.icon ? (
                  <NavIcon
                    $isActive={isActivePath(link)}
                    as={isActivePath(link) ? link.activeIcon || link.icon : link.icon}
                  />
                ) : null}
                {link.label}
              </NavItemLink>
            </NavItem>
          ))}
        </NavList>
      </StyledNavBar>
    );
  },
);
