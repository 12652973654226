/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable max-len */
import React from 'react';

export function BoxWithUpArrow({className}: {className?: string}) {
  return (
    <svg
      {...{className}}
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1875 9.5H1V19.5H15V9.5H12.8125"
        stroke="#1B1B26"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 3.5V13.5" stroke="#1B1B26" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M5 5.5L8 1.5L11 5.5"
        stroke="#1B1B26"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
