/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const OHM_NGROK_ENV: EnvConfig = {
  ...(require('./ohm.development.env').OHM_DEVELOPMENT_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.DEVELOPMENT,
  name: 'Ohm NGrok',
  backendBaseUrl: 'https://ohm-lg-dev.ohmconnect.com',
};
