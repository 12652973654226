/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {trackStructuredEvent} from 'utils';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import {WithComponentPropsWithoutRef} from 'types';
import {get} from 'lodash';

export default function UtilityConnectionBillboard({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const ATTRIBUTE_KEY = 'BILLBOARD_UTILITY_CONNECTION_COLLAPSED';
  const isCollapsed: boolean = globalState?.user?.attributes[ATTRIBUTE_KEY] === '1';
  const hasUtilityZipCode = get(globalState, 'userSettings.utility_info.zip_code') !== null;

  async function handleOnCollapse(isNowCollapsed: boolean) {
    if (!isNowCollapsed) {
      trackStructuredEvent({
        category: 'billboard',
        action: 'view',
        label: 'utility_limbo_classic',
      });
    }
    await setUserAttributes({[ATTRIBUTE_KEY]: isNowCollapsed ? '1' : '0'});
  }

  return (
    <>
      {
        <PopupAnnouncement
          {...props}
          options={{
            header: 'Finish your account setup',
            message: (
              <p>
                <span role="img" aria-label="">
                  🔐
                </span>{' '}
                Unlock energy events and rewards by connecting your utility account.
              </p>
            ),
            includeIcon: false,
            dismissable: false,
            collapsable: true,
            isCollapsed: isCollapsed,
            collapseLabel: 'Hide',
            uncollapseLabel: 'Finish your account setup',
            onCollapse: handleOnCollapse,
            align: 'center',
            ctas: [
              {
                to: hasUtilityZipCode ? '/signup/utility?edit=true' : '/signup/zip_code?edit=true',
                confirmTrackId: 'pre_authdone_finish_setup_cta_click',
                onConfirm: () =>
                  trackStructuredEvent({
                    category: 'billboard',
                    action: 'click',
                    label: 'utility_limbo_classic_connect_utility',
                  }),
              },
            ],
          }}
        />
      }
    </>
  );
}
