/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {reduce} from 'lodash';

import iconTypes from './iconTypes';
import {WithComponentPropsWithoutRef} from 'types';

function Icon({
  className,
  variation,
}: WithComponentPropsWithoutRef<'svg', {variation: keyof typeof iconTypes}>) {
  const IconVariation = iconTypes[variation];

  return <IconVariation className={className || ''} />;
}

// Expose available options from iconTypes mapping as string constants
Icon.variations = reduce<typeof iconTypes, Record<string, string>>(
  iconTypes,
  (result, value, key) => ({...result, [key]: key}),
  {},
);

export default Icon;
