import {ViewerFirstLoadDocument} from 'graphql/api.generated';
import {MockedResponse} from '@apollo/react-testing';
import {viewerFirstLoadMock} from '../../../graphql/mocks/viewerFirstLoad.mock';

export const userUiCustomizationMock: MockedResponse = {
  request: {
    query: ViewerFirstLoadDocument,
  },
  result: {
    data: {
      viewer: {
        ...viewerFirstLoadMock.result.data.viewer,
        __typename: 'User',
        features: [],
        uiCustomization: {
          __typename: 'UserUiCustomization',
          customBrandBarImageUrl:
            'https://partner-assets.ohmconnect.com/sunpower/temp_sunpower_oc_logo.svg',
        },
      },
      currentSession: {
        ...viewerFirstLoadMock.result.data.currentSession,
      },
    },
  },
};
