/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Outlet} from 'react-router-dom';
import {AppTopBrandBar, AppTopBrandBarProps} from '../AppTopBrandBar';
import {PageContainer} from '../containers';
import {TopBrandBarBackButton} from '../TopBrandBarBackButton';
import styled, {css} from 'styled-components';
import {PrimaryNavBar} from '../PrimaryNavBar';
import BillboardAnnouncement from '../../BillboardAnnouncement/BillboardAnnouncement';
import FlashMessage from '../FlashMessage/FlashMessage';
import {TriggeredSurveyAnnouncement} from '../SurveyAnnouncement';
import {useSetBrowserTheme} from '../../../theme/hooks/useSetBrowserTheme';

const StyledPageContainer = styled(PageContainer)`
  ${({theme}) => css`
    background: ${theme.content.background};
    box-shadow: 0px 0px 4px ${theme.color.secondaryA100};
  `}
`;

const StyledAppTopBrandBar = styled(AppTopBrandBar)`
  text-transform: uppercase;
`;
const StickyBottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;
`;
export type MainLayoutProps = AppTopBrandBarProps & {
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  className,
  leftContent,
  maxContentWidth = '800px',
  topContent,
  bottomContent,
  ...props
}) => {
  const {ref} = useSetBrowserTheme();
  return (
    <>
      <StyledAppTopBrandBar
        ref={ref}
        {...props}
        leftContent={leftContent ?? <TopBrandBarBackButton />}
        maxContentWidth={maxContentWidth}
        hasShadow
      />
      {topContent}
      <StyledPageContainer className={className} maxContentWidth={maxContentWidth}>
        <Outlet />
        {children}
      </StyledPageContainer>
      <StickyBottomContainer>
        {bottomContent}
        <BottomMatter />
      </StickyBottomContainer>
    </>
  );
};

const FlashMessageWrapper = styled.div`
  position: relative;
  height: 0;
`;

const BottomMatter: React.FC = () => {
  return (
    <>
      <FlashMessageWrapper>
        <FlashMessage />
      </FlashMessageWrapper>
      <TriggeredSurveyAnnouncement />
      <BillboardAnnouncement />
      <PrimaryNavBar />
    </>
  );
};
