/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const VIVINT_DEVELOPMENT_ENV: EnvConfig = {
  ...(require('./vivint.production.env').VIVINT_PRODUCTION_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.DEVELOPMENT,
  name: 'Vivint Development',
  backendBaseUrl: `${window.location.protocol}//${
    window.location.host.endsWith(':3000')
      ? window.location.host.replace(':3000', ':6543')
      : window.location.host.replace(':5000', ':6543')
  }`,

  // Use test facebook app which supports https://local.ohm.com -- this is OhmConnectCadirTest app
  facebookAppId: '252577201849229',
  friendBuyMerchantId: '930a1754-a2ee-44fc-aa10-297bbd57f2b0',
  gmailClientId: '442034863238-0v8pik6jv5gjf733mpm897v1ituqdf35.apps.googleusercontent.com',
  // Note: Key below is copied from backend config.
  googleRecaptchaSiteKey: '6LcR1HoUAAAAAPxiOT2l3y8oq0-bd9K9mRWHy2wJ',
  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '10000000-ffff-ffff-ffff-000000000001',
  oceEnrollmentUrl: `${window.location.protocol}//${window.location.hostname}:3001/`,

  emsAppUrl: `${window.location.protocol}//${window.location.hostname}:4200`,
};
