/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import styled, {css} from 'styled-components';
import {ProgressBar, ProgressBarProps} from '../ProgressBar';

interface TopBrandBarWrapperProps {
  $hasShadow?: boolean;
  $isInverted?: boolean;
}
const TopBrandBarWrapper = styled.nav<TopBrandBarWrapperProps>`
  ${({theme, $hasShadow, $isInverted}) => css`
    background: ${$isInverted ? theme.brandbar.color : theme.brandbar.background};
    box-shadow: ${$hasShadow ? theme.standard.boxShadow : 'none'};
    transition: box-shadow 200ms ease-in-out;
    color: ${$isInverted ? theme.brandbar.background : theme.brandbar.color};
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: none;
  `}
`;

interface TopBrandBarContentProps {
  $align?: 'left' | 'center' | 'right';
  $flexAmount?: number;
  $maxContentWidth?: string;
  $isCompact?: boolean;
}
const TopBrandBarContentContainer = styled.div<TopBrandBarContentProps>`
  ${({$maxContentWidth, $isCompact}) => css`
    display: flex;
    flex-direction: row;
    flex: auto;
    overflow: hidden;
    max-width: ${$maxContentWidth};
    width: 100%;
    height: ${$isCompact ? '40px' : '48px'};
  `}
`;
const TopBrandBarContent = styled.div<TopBrandBarContentProps>`
  ${({$align = 'center', $flexAmount = 1}) => css`
    display: flex;
    flex: ${$flexAmount};
    flex-direction: row;
    align-items: center;
    justify-content: ${$align === 'left'
      ? 'flex-start'
      : $align === 'right'
      ? 'flex-end'
      : 'center'};
    text-align: ${$align};
    font-weight: 700;
    padding: 4px 12px;
    position: relative;
  `}
`;
const StyledProgressBar = styled(ProgressBar)`
  opacity: 0.75;
  height: 4px;
  /* flex none keeps the progress bar from disappearing when the page gets long,
  relative prevents scrolling it */
  flex: none;
  position: relative;
  width: 100%;
`;

export type TopBrandBarProps = React.HTMLAttributes<HTMLElement> & {
  defaultContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  lowerContent?: React.ReactNode;
  hasShadow?: boolean;
  isCompact?: boolean;
  isInverted?: boolean;
  progressBar?: ProgressBarProps;
  maxContentWidth?: string;
};

export const TopBrandBar = React.forwardRef<HTMLElement, TopBrandBarProps>(
  (
    {
      children,
      defaultContent,
      leftContent,
      rightContent,
      lowerContent,
      hasShadow,
      isCompact,
      isInverted,
      progressBar,
      maxContentWidth,
      ...props
    },
    ref,
  ) => {
    return (
      <TopBrandBarWrapper $hasShadow={hasShadow} ref={ref} $isInverted={isInverted}>
        <TopBrandBarContentContainer
          $maxContentWidth={maxContentWidth}
          $isCompact={isCompact}
          {...props}
        >
          <TopBrandBarContent $align={'left'} $flexAmount={1}>
            {leftContent}
          </TopBrandBarContent>
          <TopBrandBarContent $align={'center'} $flexAmount={3}>
            {children || defaultContent}
          </TopBrandBarContent>
          <TopBrandBarContent $align={'right'} $flexAmount={1}>
            {rightContent}
          </TopBrandBarContent>
        </TopBrandBarContentContainer>
        {lowerContent}
        {progressBar ? <StyledProgressBar {...progressBar} /> : null}
      </TopBrandBarWrapper>
    );
  },
);
