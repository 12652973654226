/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const ORG_TEST_ENV: EnvConfig = {
  ...(require('./org.development.env').ORG_DEVELOPMENT_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.TEST,
  name: 'Origin Test',
  backendBaseUrl: `http://${window.location.host.replace(':3000', ':6543')}`,
};
