/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {ActionType, PayloadOf, useGlobalDispatch, useGlobalState} from '..';
import {initialValue} from '../GlobalStore';

type BrandBarDisplay = PayloadOf<ActionType.SET_BRAND_BAR_DISPLAY>;
type SetBrandBarDisplayReturnArgsA = [Partial<BrandBarDisplay>];
type SetBrandBarDisplayReturnArgsB = [Partial<BrandBarDisplay>, undefined | false];
type SetBrandBarDisplayReturnArgsC = [BrandBarDisplay, true];
type SetBrandBarDisplayReturnArgs =
  | SetBrandBarDisplayReturnArgsA
  | SetBrandBarDisplayReturnArgsB
  | SetBrandBarDisplayReturnArgsC;
type SetBrandBarDisplayReturn = (...args: SetBrandBarDisplayReturnArgs) => void;

function useUpdateBrandBarDisplay() {
  const globalState = useGlobalState();
  const globalDispatch = useGlobalDispatch();

  return React.useCallback<SetBrandBarDisplayReturn>(
    (data, replace?: boolean) => {
      globalDispatch({
        type: ActionType.SET_BRAND_BAR_DISPLAY,
        payload: replace ? (data as BrandBarDisplay) : {...globalState.brandBarDisplay, ...data},
      });
    },
    [globalDispatch, globalState.brandBarDisplay],
  );
}

/** Set the content of the BrandBar (auto cleans up to reset todefault brandbar content) */
export function useSetBrandBarDisplay(
  /** Value to set when the provided deps change
   * (either a node representing the main content, or a full config) */
  value?: Partial<PayloadOf<ActionType.SET_BRAND_BAR_DISPLAY>> | React.ReactNode,
  /** A set of dependencies that cause the content to update and re-render */
  dependencies: React.DependencyList = [],
) {
  const updateBrandBarDisplay = useUpdateBrandBarDisplay();

  /** The default value */
  const initialContentRef = React.useRef(initialValue.brandBarDisplay);

  /** Transform the input to a safe config object, based on its type */
  const safe = React.useCallback(
    (x: Partial<PayloadOf<ActionType.SET_BRAND_BAR_DISPLAY>> | React.ReactNode) =>
      (!React.isValidElement(x) && typeof x === 'object' ? x : {mainContent: x}) as Partial<
        PayloadOf<ActionType.SET_BRAND_BAR_DISPLAY>
      >,
    [],
  );

  /** Actually persist the change to the store */
  const setSafeValue = React.useCallback(
    (
      /** Value to set (either a node representing the main content, or a full config) */
      value: Partial<PayloadOf<ActionType.SET_BRAND_BAR_DISPLAY>> | React.ReactNode,
    ) => {
      updateBrandBarDisplay({...initialContentRef.current, ...safe(value)}, true);
    },
    [safe, updateBrandBarDisplay],
  );

  /** Set the value when the deps change (if applicable) */
  React.useEffect(
    () => {
      if (value !== undefined) setSafeValue(value);
      /** useEffect will call the cleanup when its unmounted */
      return () => {
        setSafeValue({});
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );

  return setSafeValue;
}
