/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect} from 'react';
import {get, isEmpty, assign} from 'lodash';
import queryString from 'query-string';

import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';

/**
 * @readonly
 * @enum {string}
 */
export const SURVEYS = {
  SAMPLE: 'SAMPLE',
};
/**
 * @type {{[SURVEYS: SURVEYS]:{url: string, message: string}} }
 */
const SURVEY_DATA = {
  [SURVEYS.SAMPLE]: {
    url: '',
    message: '',
  },
};

export const SURVEY_USER_ATTRIBUTE_PREFIX = `ANNOUNCE_SURVEY_`;

// TODO: rename `survey` prop to `surveyKey`
export default function SurveyAnnouncement({className, survey, additionalSurveyFields}) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const userId = globalState.user.id;
  const isBetaVolunteer = get(globalState.user.attributes, 'SPA_BETA_VOLUNTEER');

  const surveyUserAttribute = SURVEY_USER_ATTRIBUTE_PREFIX + survey;
  const surveyUserAttributeValue = parseInt(
    get(globalState.user.attributes, surveyUserAttribute) || 0,
  );

  useEffect(() => {
    // We're going to show the survey announcement but need to set the rel_user attribute
    // 0 == proxy for "show it but we need to set the rel_user value to 1"
    // 1 == show it
    // 2 == has been shown and dismissed
    // 3 == has been shown and they clicked "let's go" to fill the survey
    if (surveyUserAttributeValue === 0) setUserAttributes({[surveyUserAttribute]: 1});
  }, [setUserAttributes, surveyUserAttribute, surveyUserAttributeValue]);

  if (surveyUserAttributeValue > 1) return null;

  const defaultHiddenFields = {
    new: !globalState.user.wasInLegacy ? 'yes' : 'no',
    devices: !isEmpty(globalState.connectionDevices) ? 'yes' : 'no',
    user_id: userId,
    beta_type: isBetaVolunteer ? 'volunteered' : 'voluntold',
  };
  const allHiddenFields = assign(defaultHiddenFields, additionalSurveyFields);

  return (
    <PopupAnnouncement
      {...{className}}
      options={{
        header: 'Share your feedback!',
        message: SURVEY_DATA[survey].message,
        onDismiss: () => setUserAttributes({[surveyUserAttribute]: 2}),
        ctas: [
          {
            url: `${SURVEY_DATA[survey].url}${queryString.stringify(allHiddenFields)}`,
            onConfirm: () => setUserAttributes({[surveyUserAttribute]: 3}),
          },
        ],
      }}
    />
  );
}
