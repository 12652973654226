import React from 'react';

const TrendLineUp = ({className}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.9729 15.027C19.9729 15.292 20.0779 15.547 20.2659 15.734C20.4529 15.922 20.7079 16.027 20.9729 16.027C21.2379 16.027 21.4928 15.922 21.6798 15.734C21.8678 15.547 21.9729 15.292 21.9729 15.027V7.02699C21.9729 6.76199 21.8678 6.50699 21.6798 6.31999C21.4928 6.13199 21.2379 6.02699 20.9729 6.02699H12.9729C12.7079 6.02699 12.4529 6.13199 12.2659 6.31999C12.0779 6.50699 11.9729 6.76199 11.9729 7.02699C11.9729 7.29199 12.0779 7.54699 12.2659 7.73399C12.4529 7.92199 12.7079 8.02699 12.9729 8.02699H18.5629L12.9729 13.617L9.67286 10.317C9.48587 10.134 9.23485 10.031 8.97285 10.031C8.71085 10.031 8.45984 10.134 8.27284 10.317L2.27284 16.317C2.10784 16.507 2.01985 16.753 2.02785 17.004C2.03585 17.256 2.13887 17.496 2.31587 17.675C2.49187 17.855 2.73082 17.961 2.98182 17.972C3.23382 17.983 3.48086 17.9 3.67286 17.737L8.97285 12.427L12.2728 15.727C12.4598 15.91 12.7109 16.013 12.9729 16.013C13.2349 16.013 13.4859 15.91 13.6729 15.727L19.9729 9.42698V15.027Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrendLineUp;
