/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Unsupported({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.25C9.62663 0.25 7.30655 0.953788 5.33316 2.27236C3.35977 3.59094 1.8217 5.46508 0.913451 7.6578C0.00519941 9.85051 -0.232441 12.2633 0.230582 14.5911C0.693605 16.9189 1.83649 19.057 3.51472 20.7353C5.19295 22.4135 7.33115 23.5564 9.65892 24.0194C11.9867 24.4824 14.3995 24.2448 16.5922 23.3365C18.7849 22.4283 20.6591 20.8902 21.9776 18.9168C23.2962 16.9434 24 14.6234 24 12.25C23.9963 9.06853 22.7309 6.01842 20.4812 3.76878C18.2316 1.51914 15.1815 0.253677 12 0.25V0.25ZM16.345 15.2897C16.4308 15.3753 16.4989 15.4771 16.5454 15.5891C16.5919 15.7011 16.6159 15.8212 16.616 15.9425C16.616 16.0638 16.5922 16.1839 16.5458 16.296C16.4994 16.4081 16.4314 16.5099 16.3456 16.5956C16.2599 16.6814 16.1581 16.7494 16.046 16.7958C15.9339 16.8422 15.8138 16.866 15.6925 16.866C15.5712 16.8659 15.4511 16.8419 15.3391 16.7954C15.2271 16.7489 15.1253 16.6808 15.0397 16.5949L12 13.5553L8.96034 16.5949C8.78718 16.7677 8.55252 16.8647 8.3079 16.8646C8.06328 16.8645 7.82872 16.7672 7.65575 16.5943C7.48277 16.4213 7.38554 16.1867 7.38541 15.9421C7.38528 15.6975 7.48226 15.4628 7.65505 15.2897L10.6947 12.25L7.65505 9.21033C7.48226 9.03718 7.38528 8.80251 7.38541 8.55789C7.38554 8.31328 7.48277 8.07871 7.65575 7.90574C7.82872 7.73277 8.06328 7.63554 8.3079 7.63541C8.55252 7.63528 8.78718 7.73226 8.96034 7.90505L12 10.9447L15.0397 7.90505C15.2128 7.73226 15.4475 7.63528 15.6921 7.63541C15.9367 7.63554 16.1713 7.73277 16.3443 7.90574C16.5172 8.07871 16.6145 8.31328 16.6146 8.55789C16.6147 8.80251 16.5177 9.03718 16.345 9.21033L13.3053 12.25L16.345 15.2897Z"
        fill="currentColor"
      />
    </svg>
  );
}
