/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function StreakShield({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6279 0C15.3998 5.25433 11.496 5.5 5 5.5V26.6475C5 35.0863 10.9177 37.2882 21.6279 44C32.3381 37.2882 38.2558 35.0863 38.2558 26.6475V5.5C31.7598 5.5 27.856 5.25433 21.6279 0ZM20.6155 27.5L14.2377 21.3363L16.5231 19.0648L20.6155 22.957L28.7114 14.7528L30.9987 17.0225L20.6155 27.5V27.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
