/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function PhoneOutline({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.286 1.5H7.71399C6.76799 1.5 6 2.44017 6 3.6V20.4C6 21.5598 6.76799 22.5 7.71399 22.5H16.286C17.232 22.5 18 21.5598 18 20.4V3.6C18 2.44017 17.232 1.5 16.286 1.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.75H12.0101"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
