/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function ShippingBoxHalfFill({className}) {
  return (
    <svg
      {...{className}}
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6701 9.03701C31.665 9.02757 31.6611 9.01781 31.6558 9.00837C31.6514 9.00068 31.6462 8.99378 31.6417 8.98616C31.414 8.5949 31.0892 8.27113 30.6997 8.04717L17.2597 0.334524C16.875 0.11521 16.4412 1.39408e-05 16 1.26524e-09C15.5588 -1.39383e-05 15.125 0.115155 14.7403 0.334444L1.30016 8.04732C0.904058 8.27493 0.57493 8.6057 0.346718 9.00552C0.344296 9.0098 0.341406 9.01361 0.339062 9.01789C0.334922 9.02542 0.331835 9.03328 0.327851 9.04089C0.113364 9.42846 0.000476604 9.86546 0 10.31V25.688C0.000441155 26.1482 0.121011 26.6001 0.349428 26.9976C0.577846 27.3951 0.905949 27.724 1.30032 27.9509L14.7403 35.6635C15.0983 35.8678 15.4994 35.9816 15.9098 35.9952C15.9354 35.997 15.9607 35.9996 15.9867 35.9998C15.9912 35.9999 15.9958 36 16.0003 36C16.0351 36 16.0692 35.9975 16.1033 35.9947C16.5092 35.9792 16.9056 35.8657 17.2597 35.6636L30.6998 27.9507C31.0942 27.7239 31.4223 27.395 31.6506 26.9975C31.879 26.6 31.9996 26.1482 32 25.688V10.31C31.9995 9.86396 31.8859 9.42553 31.6701 9.03701ZM16 2.59722L28.1301 9.5583L23.8543 12.0406L11.6295 5.10533L16 2.59722ZM16.1625 16.5062L3.89262 9.54522L9.02047 6.6025L21.2605 13.5465L16.1625 16.5062ZM17.3009 32.6543L17.4423 18.7573L22.5816 15.7736V21.331C22.5816 21.6756 22.7164 22.0062 22.9565 22.2499C23.1965 22.4936 23.5221 22.6305 23.8616 22.6305C24.201 22.6305 24.5266 22.4936 24.7666 22.2499C25.0067 22.0062 25.1416 21.6756 25.1416 21.331V14.2874L29.44 11.7919V25.688L17.3009 32.6543Z"
        fill="currentColor"
      />
    </svg>
  );
}
