/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {get} from 'lodash';
import styled from 'styled-components';

import {useGlobalState, useGlobalDispatch, ActionType} from 'store';

import {flash} from 'components/shared/animations';
import Snackbar from 'components/shared/Snackbar';

const MessageWrapper = styled.div`
  bottom: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  /* iOS 'fullscreen' hacks */
  @supports (-webkit-touch-callout: none) {
    @media (height: 100vh), (orientation: landscape) {
      bottom: 0;
    }
  }
`;

const SnackbarMotionWrapper = styled(motion.div)`
  padding-bottom: 16px;
`;

const StyledSnackbar = styled(Snackbar)`
  pointer-events: auto;
`;

function MessageAnimationWrapper({message, dismissMessage}) {
  const [exitSpeed, setExitSpeed] = useState(800);

  function handleDismiss() {
    // faster exit animation when clicked
    setExitSpeed(200);
    dismissMessage();
  }

  return (
    <SnackbarMotionWrapper
      initial="enter"
      animate="visible"
      exit="exit"
      variants={flash}
      custom={exitSpeed}
    >
      <StyledSnackbar {...message} onDismiss={handleDismiss} />
    </SnackbarMotionWrapper>
  );
}

const LONG_MESSAGE_CHAR_THRESHOLD = 45;
const [SHORT_MESSAGE_READING_DELAY, LONG_MESSAGE_READING_DELAY] = [3500, 7000];

export default function FlashMessage({className = ''}) {
  const globalState = useGlobalState();
  const globalDispatch = useGlobalDispatch();

  // new messages are appended; always read the current
  // message from the head of the list:
  const message = get(globalState, 'flashMessages[0]');

  useEffect(() => {
    let timer;
    message &&
      (timer = setTimeout(
        () => {
          // drop the current message from the head of the list
          globalDispatch({type: ActionType.DROP_FLASH_MESSAGE});
        },
        // allow more time to read longer messages
        message.text.length >= LONG_MESSAGE_CHAR_THRESHOLD
          ? LONG_MESSAGE_READING_DELAY
          : SHORT_MESSAGE_READING_DELAY,
      ));
    // make sure to clean up the timer so we don't accidentally
    // clear a subsequently-rendered message too early:
    return () => clearTimeout(timer);
  }, [globalDispatch, message]);

  function dismissMessage() {
    globalDispatch({type: ActionType.DROP_FLASH_MESSAGE});
  }

  return (
    <MessageWrapper role="alert" className={className}>
      <AnimatePresence exitBeforeEnter>
        {message && <MessageAnimationWrapper key={message.text} {...{message, dismissMessage}} />}
      </AnimatePresence>
    </MessageWrapper>
  );
}
