/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {omit, get, pickBy, intersection} from 'lodash';

import {ENV} from 'config/globals';
import {Oem} from 'config/environments';
import {useGlobalState} from 'store';
import {useIsOhmPerks} from 'store/ohmPerks/ohmPerks.selectors';
import {isLongTermNoIntervalData} from 'utils';

import {UTILITY_STATUS, useGetUtilityStatusDisplay} from 'components/Settings/utilityStatusConfig';

export function useGetUserIsAuthenticated() {
  return !!useGetUserId();
}

export function useGetUserId() {
  const globalState = useGlobalState();
  return globalState?.user?.id;
}

export function useGetProductPlan() {
  const globalState = useGlobalState();
  const featureKeys = get(globalState, 'features.featureKeys', []);

  const isOhmPerks = useIsOhmPerks();

  return useCallback(
    () =>
      // if the user has no feature keys, we can't determine their plan yet, so return an empty string
      featureKeys.length > 0 ? (isOhmPerks ? 'OHM_PERKS' : 'OHM_CLASSIC') : '',
    [featureKeys.length, isOhmPerks],
  );
}

export function useGetOhmTermsLink() {
  const globalState = useGlobalState();
  const isTexasRetail = get(globalState, 'user.programEnrollment.texas_retail', false);

  return useCallback(() => {
    // @todo: come up with a generic solution for handling multiple values for terms link
    // within the same OEM/environment
    const OHM_TX_TERMS_LINK = 'https://www.ohmconnect.com/texas/terms-of-service';
    return isTexasRetail ? OHM_TX_TERMS_LINK : ENV.termsUrl;
  }, [isTexasRetail]);
}

export function useGetConfigForDevice() {
  const globalState = useGlobalState();

  return useCallback(
    deviceConfigId => {
      const deviceConfig = get(globalState, `deviceConfigs.${deviceConfigId}`);
      const deviceConnectionTitle =
        deviceConfig &&
        get(globalState, `connectionConfigs.${deviceConfig.connectionConfigId}.title`);
      return {
        ...deviceConfig,
        connectionTitle: deviceConnectionTitle,
      };
    },
    [globalState],
  );
}

export function useGetDeviceConfigsForConnectionConfig(connectionConfigId) {
  const globalState = useGlobalState();
  const deviceConfigs = get(globalState, 'deviceConfigs');
  return deviceConfigs
    ? Object.values(deviceConfigs).filter(
        deviceConfig => deviceConfig.connectionConfigId === connectionConfigId,
      )
    : null;
}

// Should we implement a pattern for memoizing functions like these? We could use lodash `memoize`
// but we'd need to provide a resolver for the cache key that will represent both the arguments
// so that if either change, the cache key would be different.
// Also, let's consider whether we want to use a pattern for selectors like this that read
// from the global store directly rather than having to pass those values in as parameters.
// Since regular functions can't use hooks (e.g. `useContext`), this would mean that these functions
// would either have to be custom hooks or react components rather than just normal functions.
export function getEventsForDevice(allEvents, deviceId) {
  return allEvents.reduce(
    (events, event) =>
      deviceId in event.devices && (event.participation_results_available || event.ohm_hour_ended)
        ? events.concat({
            ...omit(event, 'devices', 'reduction_points', 'total_participation_points'),
            ...event.devices[deviceId],
          })
        : events,
    [],
  );
}

// In the new dashboard, we only want to show upcoming *combined* events
export function useGetValidUpcomingEvents() {
  const globalState = useGlobalState();
  return useCallback(
    () =>
      globalState.upcomingEvents &&
      globalState.upcomingEvents.filter(
        e => e && e.ohmhour_type.startsWith('PARTICIPATION_REDUCTION'),
      ),
    [globalState.upcomingEvents],
  );
}

export function useGetUtilityEnrollmentModalHidden() {
  const globalState = useGlobalState();
  const userAttributes = get(globalState, 'user.attributes');

  return useCallback(() => {
    return !!get(userAttributes, 'HIDE_UTILITY_ENROLLMENT_MODAL', false);
  }, [userAttributes]);
}

export function useGetCurrentOauthOutage() {
  const globalState = useGlobalState();
  const utilityInfo = get(globalState, 'userSettings.utility_info');

  return useCallback(
    () => ({
      inOutage: get(utilityInfo, 'current_oauth_is_in_outage'),
      outageEndDttm: get(utilityInfo, 'current_oauth_outage_end_dttm'),
    }),
    [utilityInfo],
  );
}

export function useGetUserUtilityNeverConnected() {
  const globalState = useGlobalState();
  const userAttributes = get(globalState, 'user.attributes');
  const userSettings = get(globalState, 'userSettings');

  return useCallback(
    () =>
      !get(userAttributes, 'HIDE_UTILITY_ENROLLMENT_MODAL', false) &&
      ['PreValid', 'AuthStart'].includes(get(userSettings, 'utility_widget_state')) &&
      get(userSettings, 'ever_reached_auth_done') === false &&
      get(userSettings, 'utility_widget_substate') !== 'UtilityNotSupported',
    [userAttributes, userSettings],
  );
}

export function useGetUtilityConnectionIssue() {
  const globalState = useGlobalState();
  const getUtilityStatusDisplay = useGetUtilityStatusDisplay();
  const userAttributes = get(globalState, 'user.attributes');
  const userSettings = get(globalState, 'userSettings');

  return useCallback(() => {
    const utilityEnrollModalNotHidden = !get(
      userAttributes,
      'HIDE_UTILITY_ENROLLMENT_MODAL',
      false,
    );
    const userHasEverReachedAuthDone = get(userSettings, 'ever_reached_auth_done') === true;
    const isNotHappySubstate = !['UtilityNotSupported'].includes(
      get(userSettings, 'utility_widget_substate'),
    );
    const isIssueSubstate = ['ConflictingDRP', 'Duplicate', 'AttestationNeeded'].includes(
      get(userSettings, 'utility_widget_substate'),
    );
    const isConed = get(userSettings, 'utility_info.acronym') === 'CONED';
    const isConEdConflictingSubstate =
      isConed && get(userSettings, 'utility_widget_substate') === 'ConflictingDRP';
    const utilityConnectionStatusIsIssue =
      getUtilityStatusDisplay(
        get(userSettings, 'utility_widget_state'),
        get(userSettings, 'utility_widget_substate'),
        get(userSettings, 'meter_status.current_meter_data'),
        get(userSettings, 'days_since_last_enroll_state'),
      ).connectionStatus === UTILITY_STATUS.ISSUE;
    const ohmHourEligibleIsIssue = get(userSettings, 'ohmhour_eligible.label') === 'Issue';
    const _isLongTermNoIntervalData = isLongTermNoIntervalData(
      get(userSettings, 'utility_widget_substate'),
      get(userSettings, 'days_since_last_enroll_state'),
    );
    return (
      utilityEnrollModalNotHidden &&
      userHasEverReachedAuthDone &&
      isNotHappySubstate &&
      !isConEdConflictingSubstate &&
      (isIssueSubstate ||
        _isLongTermNoIntervalData ||
        utilityConnectionStatusIsIssue ||
        ohmHourEligibleIsIssue)
    );
  }, [userAttributes, userSettings, getUtilityStatusDisplay]);
}

export function useGetShowRetailDetails() {
  const globalState = useGlobalState();
  const user = get(globalState, 'user');
  const showRetailDetails = get(user, 'programEnrollment.texas_retail', false);

  // This may one day depend on enroll states, etc,
  // but for now if they're in our Texas program, show them this
  return useCallback(() => showRetailDetails, [showRetailDetails]);
}

export function useGetShouldHidePasswordReset() {
  // Note that this is specific to, well, hiding the password reset functionality ...
  // even though it mostly mirrors getShowRetailDetails, they could differ in future
  const globalState = useGlobalState();
  const isTexasRetail = get(globalState, 'user.programEnrollment.texas_retail', false);

  // This may one day depend on enroll states, etc,
  // but for now if they're in our Texas program, show them this
  return useCallback(() => isTexasRetail, [isTexasRetail]);
}

export function useGetShowSystemEmail() {
  const globalState = useGlobalState();
  const ssoLogin = get(globalState.userSettings, 'sso_login', true);

  return useCallback(() => ENV.oem !== Oem.ORIGIN || !ssoLogin, [ssoLogin]);
}

/**
 * gets the map of all event subscriptions for the user
 * @returns {Record<string, boolean>} map of event subscriptions for the user
 */
export function useEventSubscriptions() {
  const globalState = useGlobalState();
  const subscriptions = get(globalState, `userSettings.event_subscriptions`, {});
  // only return boolean keys
  return pickBy(subscriptions, value => typeof value === 'boolean');
}

export const EVENT_SUBSCRIPTIONS = {
  OH_ADVANCE: 'ohm_hour_advance',
  OH_ADVANCE_SMS: 'ohm_hour_advance_sms',
  OH_START: 'ohm_hour_start',
  OH_START_SMS: 'ohm_hour_start_sms',
  OH_END: 'ohm_hour_end',
  OH_END_SMS: 'ohm_hour_end_sms',
  OH_RESULTS: 'ohm_hour_results',
  OH_RESULTS_SMS: 'ohm_hour_results_sms',
  AO_START_SMS: 'auto_ohm_start_sms',
  AO_END_SMS: 'auto_ohm_end_sms',
  AO_RESULTS: 'auto_ohm_results',
  AO_RESULTS_SMS: 'auto_ohm_results_sms',
};

/**
 * get the state of one specific event subscription.
 * @param {EVENT_SUBSCRIPTIONS[keyof EVENT_SUBSCRIPTIONS]} subscriptionKey
 *  one of the values from the `EVENT_SUBSCRIPTIONS` const
 * @returns {boolean} current user state of requested subscription
 */
export function useEventSubscription(subscriptionKey) {
  const allSubscriptions = useEventSubscriptions();
  const subscription = get(allSubscriptions, subscriptionKey, false);
  return subscription;
}

export function useRealTimeEnrolled() {
  const globalState = useGlobalState();
  // for some reason, this particular prop in settings is an object
  // with 1 key of "checked" and the value is either "" or "checked"
  const isRTEnrolled =
    get(globalState, 'userSettings.real_time_enrolled.checked', '') === 'checked';

  return isRTEnrolled;
}

/**
 * determine whether the current user has a device connected via NEST_SDM
 * @returns {boolean} whether or not user has a NEST_SDM device
 */
export function useGetHasNestDevice() {
  const globalState = useGlobalState();
  const devices = get(globalState, 'connectionDevices');

  if (!devices) return false;

  return Object.values(devices).some(device => {
    return device.deviceName === 'NEST_SDM' || device.deviceName === 'NEST_API';
  });
}

export function useShippingAddress() {
  const globalState = useGlobalState();
  return globalState?.user?.shippingAddress;
}

export function useUserEmail() {
  const globalState = useGlobalState();
  return globalState?.userSettings?.system_email;
}

export function useEmailRedemptionBlocker() {
  const globalState = useGlobalState();
  const pointsBlockers = get(globalState, 'wallet.points_blockers', []);

  const EMAIL_BLOCKERS_ORDER = ['missing_address', 'verified_address'];

  const emailBlocker = get(intersection(EMAIL_BLOCKERS_ORDER, pointsBlockers), '[0]');
  return emailBlocker;
}
