/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function CalendarEmpty({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2C17.552 2 18 2.448 18 3V4H19C20.657 4 22 5.343 22 7V19C22 20.657 20.657 22 19 22H5C3.343 22 2 20.657 2 19V7C2 5.343 3.343 4 5 4H6V3C6 2.448 6.448 2 7 2C7.552 2 8 2.448 8 3V4H16V3C16 2.448 16.448 2 17 2ZM4 12V19C4 19.552 4.448 20 5 20H19C19.552 20 20 19.552 20 19V12H4ZM4 10H20V7C20 6.448 19.552 6 19 6H18V7C18 7.552 17.552 8 17 8C16.448 8 16 7.552 16 7V6H8V7C8 7.552 7.552 8 7 8C6.448 8 6 7.552 6 7V6H5C4.448 6 4 6.448 4 7V10Z"
        fill="currentColor"
      />
    </svg>
  );
}
