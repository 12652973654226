/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import styled, {createGlobalStyle, css} from 'styled-components';
import {Link} from 'react-router-dom';
import {Button} from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import ListItem, {Subtitle} from 'components/shared/ListItem';
import WattCoin from 'components/shared/svgs/WattCoin';
import {cssContrastColor} from 'theme/utils';
import {BaseColors, Color} from 'theme';
import {BrandLogo} from './BrandLogo';
import {Oem} from 'config/environments';
import {ENV} from 'config/globals';

// Any global styled components can go here

export const GlobalStyles = createGlobalStyle`
${({theme}) => css`
  html {
    /* Scroll */
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;

    /* Allow overscroll on touch devices only  */
    @media (hover: none) and (pointer: coarse) {
      overscroll-behavior: auto;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    /* Reset and cascade box-sizing. https://css-tricks.com/box-sizing/ */
    box-sizing: border-box;
    margin: 0;
  }

  body {
    font-family: ${theme.text.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    background: ${theme.body.background};
    color: ${theme.body.color};

    /*
    ariakit forces overflow: hidden on body when dialogs are open on iOS clients
    which breaks our layout and hides page content
    */
    overflow: visible !important;
  }

  #root,
  #docs-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    min-width: 100dvw;
    min-height: 100dvh;
    position: relative;
    overflow: visible;
    z-index: 0;
  }

  ${'' /* overrides for OneTrust banner */}
  #onetrust-banner-sdk {
    bottom: 0 !important;
    position: fixed !important;
    top: auto !important;
  }

  * {
    font-family: ${theme.text.fontFamily};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a,
  a:hover,
  a:active,
  a:focus {
    color: ${theme.text.link.color};
    text-decoration: ${theme.text.link.textDecoration};
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  a:hover {
    color: ${theme.text.link.hover.color};
  }
  a:active {
    color: ${theme.text.link.active.color};
  }
  a:focus {
    color: ${theme.text.link.focus.color};
  }

  button,
  [role='button'] {
    /* fix for ON-2900 */
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`}
`;

export const VisuallyHidden = styled.span`
  /* content will not appear visually on screen but will be accessible to screen readers */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const ErrorContainer = styled.div`
  ${({theme}) => css`
    color: ${theme.color.error};
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
    max-width: 380px;
  `}
`;

// TODO: add type safety
export const StandalonePage = styled.div<{announcement?: any}>`
  ${({theme, announcement}) => css`
    color: ${theme.body.color};
    background: ${theme.body.background};
    margin: 0 auto;
    max-width: 800px;
    padding: 16px 16px 24px;
    text-align: center;
    overflow: auto;
    position: relative;
    min-height: ${announcement ? 'initial' : '100%'};
    height: ${announcement ? 'calc(100% - 170px)' : 'auto'};
  `}
`;

export const StandaloneHeader = styled.div`
  margin: -16px -16px 0;
`;

export const BrandedTitle = styled.h1`
  font-size: 36px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  text-align: center;
`;

export const BrandedPage = styled.div`
  ${({theme}) => css`
    background: ${theme.content.background};
    box-shadow: 0px 0px 4px ${theme.color.secondaryA100};
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    display: flow-root;
  `}
`;

export const BrandBarTitle = styled.div`
  ${({theme}) => css`
    margin: 0 auto;
    text-transform: ${theme.text.transform};
  `}
`;

export const InvertedBrandLogo = styled(BrandLogo)`
  ${({theme}) => css`
    flex: 1 0 auto;
    height: ${ENV.oem === Oem.ORIGIN ? '80% !important' : '22px !important'};
    width: auto;
    color: ${theme.brandbar.background};
    margin: 0;
  `}
`;

export const InvertedBrandLogoWrapper = styled.div`
  line-height: 0;
  font-size: 0;
  max-height: 30px;
  margin-bottom: 10px;
`;

// Dashboard Shared
export const DashboardWatts = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const WattsWrapper = styled.div<{charCount: number}>`
  ${({charCount}) => css`
    font-size: ${charCount > 6 ? `${18 * (1 - (charCount - 6) * 0.1)}px` : '18px'};
    font-weight: 700;
    line-height: 28px;

    @media (min-width: 321px) {
      font-size: ${charCount > 5 ? `${28 * (1 - (charCount - 5) * 0.1)}px` : '28px'};
    }
  `}
`;

export const DashboardWattCoin = styled(WattCoin)`
  flex: 0 0 auto;
  height: 18px;
  width: 18px;

  @media (min-width: 321px) {
    height: 22px;
    margin-bottom: 3px;
    width: 22px;
  }
`;

export const EmptyContentSection = styled.div`
  ${({theme}) => css`
    background-color: ${theme.content.emptySection.background};
    border-radius: ${theme.standard.borderRadiusLg};
    box-shadow: ${theme.standard.boxShadowLg};
    color: ${theme.content.emptySection.color};
    font-size: 13px;
    margin: 0 16px;
    padding: 24px 16px;
    text-align: center;
  `}
`;

export const BrandBarLink = styled(Link)`
  ${({theme}) => css`
    padding: 0;
    margin: 0;
    border: none;
    flex: none;
    &,
    &:visited {
      color: currentColor;
      background: transparent;
    }
    &:hover,
    &:active,
    &:focus {
      color: ${theme.brandbar.active.color};
      background: transparent;
    }
  `}
`;

export const ListIcon = styled(Icon)`
  height: 24px;
  margin: 0;
  width: 24px;
`;

export const StyledPageHeader = styled.div`
  ${({theme}) => css`
    background-color: ${theme.content.background};
    color: ${theme.content.color};
    border-bottom: ${theme.standard.border};
    box-shadow: ${theme.standard.boxShadow};
    box-sizing: border-box;
    height: 90px;
    position: sticky;
    top: 0;
    z-index: 1;
  `}
`;

export const HeaderContent = styled.div`
  ${({theme}) => css`
    color: ${theme.text.color.primary};
    font-size: 26px;
    font-weight: 700;
    justify-content: space-between;
    line-height: 50px;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px 10px;
    position: relative;
    text-align: center;
  `}
`;

export const StyledPageContent = styled.div`
  ${({theme}) => css`
    background-color: ${theme.content.background};
    color: ${theme.text.color.primary};
    min-height: 100%;
    margin: 0 auto;
    max-width: 800px;
    overflow-x: hidden;
    overflow-y: visible;
    position: relative;
  `}
`;

export const SubtitleText = styled.span`
  ${({theme}) => css`
    color: ${theme.text.color.secondary};
    display: block;
    font-size: 14px;
  `}
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const Pill = styled.div`
  ${({theme}) => css`
    border-radius: ${theme.standard.borderRadius};
    display: inline-block;
    font-size: 9px;
    letter-spacing: 0.5px;
    font-weight: 700;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    text-transform: ${theme.text.transform};
    width: fit-content;
    background-color: ${theme.color.grey200};
    color: ${theme.color.grey600};
  `}
`;

export const TermsText = styled.div`
  ${({theme}) => css`
    color: ${theme.text.color.tertiary};
    font-size: 12px;
    font-weight: 500;
    line-height: 2;

    h3 {
      font-size: 16px;
      margin: 16px 0 8px;
    }
    h4 {
      font-size: 14px;
      margin: 12px 0 6px;
    }
    p {
      margin: 0 0 8px;
    }
  `}
`;

export const FormHeader = styled.h1`
  font-size: 32px;
  margin: 18px 5px 0;
`;

export const SmallFormHeader = styled(FormHeader)`
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
`;

export const FormPrompt = styled.p`
  font-size: 14px;
  margin: 5px;
`;

export const FormLineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 5px;

  & > * {
    flex: 1;
    display: block;
  }

  & > :nth-child(n + 2) {
    margin-left: 15px;
  }

  .stateField {
    flex: 0 33%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 22px 0 15px;
`;

export const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

export const StyledLegend = styled.legend`
  margin: 0;
  padding: 0;
`;

export const ConfigToggleLink = styled(Link)`
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  padding: 0;
  position: absolute;
  right: 10px;
`;

export const ConfigToggleIcon = styled(Icon)`
  height: 24px;
  margin: 13px;
  width: 24px;
`;

export const HeaderWrapper = styled.div`
  ${({theme}) => css`
    margin: 0 -16px 14px -16px;
    padding: 0 16px 16px;
    border-bottom: ${theme.standard.border};
    border-bottom-width: 4px;
  `}
`;

export const HeaderMessage = styled.p`
  text-align: center;
  font-size: 14px;
  padding: 0 14px;
  margin-bottom: 0;
`;

export const LargeHeader = styled.h2`
  font-weight: 700;
  font-size: 21px;
  margin: 0 0 8px 0;
`;

export const MediumHeader = styled.h2`
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 5px 0;
`;

export const SmallHeader = styled.h2`
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 4px 0;
`;

export const ExtraSmallHeader = styled.h2`
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 4px 0;
`;

export const Divider = styled.div`
  ${({theme}) => css`
    height: 0;
    padding: 15px 30px;
    border-top: ${theme.standard.border};
  `}
`;

export const Section = styled.div`
  ${({theme}) => css`
    border-bottom: ${theme.standard.border};
    font-size: 15px;
    padding: 16px 24px;
    text-align: left;
  `}
`;

export const SectionHeader = styled.div`
  ${({theme}) => css`
    border-bottom: ${theme.standard.border};
    font-size: 13px;
    font-weight: 600;
    padding: 5px 24px;
    text-align: center;
    text-transform: ${theme.text.transform};
  `}
`;

export const SignupPrompt = styled(TermsText)`
  line-height: 21px;
  margin: 35px auto 16px;
  text-align: center;
`;

export const AllCaps = styled.span`
  text-transform: uppercase;
`;

export const UnderlinedLink = styled(Link)`
  ${({theme}) => css`
    &:link {
      text-decoration: underline;
    }
    &:hover {
      color: ${theme.text.link.hover.color};
    }
    &:active {
      color: ${theme.text.link.active.color};
    }
    &:focus {
      color: ${theme.text.link.focus.color};
    }
  `}
`;

export const UnderlinedA = styled.a`
  ${({theme}) => css`
    &:link {
      text-decoration: underline;
    }
    &:hover {
      color: ${theme.text.link.hover.color};
    }
    &:active {
      color: ${theme.text.link.active.color};
    }
    &:focus {
      color: ${theme.text.link.focus.color};
    }
  `}
`;

// @todo: Will be adjusting the primary-colored background content in a followup to this ticket
// and will make this more dynamic per theme, only used in the Origin tutorial screens at this time.
export const BrandedUnderlinedA = styled.a`
  ${({theme}) => css`
    color: ${theme.color.white};
    &:link {
      text-decoration: underline;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${theme.color.primaryContrast};
    }
  `}
`;

export const ActionLink = styled(Link)`
  ${({theme}) => css`
    &,
    &:link,
    &:visited {
      color: ${theme.text.actionLink.color};
      text-decoration: ${theme.text.actionLink.textDecoration};
    }
    &:hover {
      color: ${theme.text.actionLink.hover.color};
    }
    &:active {
      color: ${theme.text.actionLink.active.color};
    }
    &:focus {
      color: ${theme.text.actionLink.focus.color};
    }
  `}
`;

export const ActionA = styled.a`
  ${({theme}) => css`
    &,
    &:link,
    &:visited {
      color: ${theme.text.actionLink.color};
      text-decoration: ${theme.text.actionLink.textDecoration};
    }
    &:hover {
      color: ${theme.text.actionLink.hover.color};
    }
    &:active {
      color: ${theme.text.actionLink.active.color};
    }
    &:focus {
      color: ${theme.text.actionLink.focus.color};
    }
  `}
`;

export const InlineActionButton = styled.button`
  ${({theme}) => css`
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    appearance: none;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    padding: 0;
    border: none;
    background: transparent;
    color: ${theme.text.actionLink.color};

    &:focus {
      color: ${theme.text.actionLink.focus.color};
    }

    &:hover {
      color: ${theme.text.actionLink.hover.color};
    }

    &:active {
      color: ${theme.text.actionLink.active.color};
    }

    &:disabled,
    &:hover:disabled,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      cursor: not-allowed;
      color: ${theme.text.actionLink.hover.color};
    }
  `}
`;

export const FakeLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

// TODO: add type safety
export const MessageBox = styled.div<{info?: any; success?: any}>`
  ${({theme, info, success}) => css`
    background-color: ${theme.content.background};
    color: ${theme.content.color};
    border: solid 2px ${info ? theme.color.grey : success ? theme.color.success : theme.color.error};
    border-radius: 10px;
    font-size: 15px;
    margin: 24px 0;
    padding: 16px 18px;
    text-align: left;
  `}
`;

export const MessageBoxLink = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const MessageBoxActionIcon = styled(Icon)`
  flex: 0 0 50px;
  margin-right: -18px;
`;

export const OptionsWrapper = styled.div`
  text-align: left;
`;

/**
 * a header row of an options table
 * @prop {boolean} disabled: whether this header row should be styled disabled (.4 opacity);
 *  does not disable any form controls
 */
// TODO: add type safety
export const OptionsHeaderRow = styled.div<{disabled: any}>`
  ${({disabled}) => css`
    display: flex;
    justify-content: flex-end;
    ${!!disabled ? 'opacity: 0.4;' : ''}
  `}
`;

/**
 * a single row of an options table
 * @prop {boolean} disabled: whether this row should be styled disabled (.4 opacity);
 *  does not disable any form controls
 */
// TODO: add type safety
export const OptionsRow = styled.div<{disabled: any}>`
  ${({theme, disabled}) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: ${theme.standard.border};
    ${!!disabled ? 'opacity: 0.4;' : ''}
  `}
`;

export const OptionIcon = styled(Icon)`
  margin-right: 15px;
  width: 30px;
  height: 30px;
`;

export const OptionLabel = styled.span`
  flex: 3 1 auto;
  display: flex;
  flex-direction: column;
  font-weight: 600;
`;

export const OptionSubLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

// TODO: add type safety
export const Option = styled.span<{double: any}>`
  ${({double}) => css`
    display: flex;
    flex: ${double ? '0 1 50%' : '0 0 25%'};
    padding: 0 8px;
    justify-content: center;
    font-weight: 600;
  `}
`;

export const Message = styled.p<{align?: 'left' | 'right' | 'center'}>`
  ${({align}) => css`
    text-align: ${align} ||left;
    padding: 0 14px;
  `}
`;

// TODO: add type safety
export const Ribbon = styled.div<{type: any}>`
  ${({theme, type}) => css`
    ${() => {
      if (type === 'alert') {
        return css`
          background: ${theme.color.alert300};
          color: ${cssContrastColor(theme.color.alert300)};
        `;
      } else if (type === 'error') {
        return css`
          background: ${theme.color.error300};
          color: ${cssContrastColor(theme.color.error300)};
        `;
      } else if (type === 'message') {
        return css`
          background: ${theme.color.success300};
          color: ${cssContrastColor(theme.color.success300)};
        `;
      } else {
        return css`
          background: ${theme.color.primary200};
          color: ${theme.text.color.primary};
        `;
      }
    }}
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    padding: 10px;
    text-align: center;
  `}
`;

export const Subhead = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 24px 0 16px;
`;

export const Subtext = styled.p`
  font-size: 14px;
  padding: 0 14px;
`;

export const PageSubTitle = styled.p`
  ${({theme}) => css`
    background: ${theme.content.background};
    box-shadow: ${theme.standard.boxShadow};
    color: ${theme.text.color.tertiary};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0 16px 16px;
    position: relative;
    text-align: center;
  `}
`;

export const PageSubHeadBar = styled.div`
  ${({theme}) => css`
    border-bottom: ${theme.standard.border};
    border-bottom-width: 2px;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    padding: 8px 16px;
  `}
`;

export const StandalonePageHeader = styled.h1`
  ${({theme}) => css`
    display: block;
    font-size: 32px;
    color: ${theme.text.color.primary};
    line-height: 36px;
    font-weight: 800;
    margin: 0 8px 16px;

    @media (max-width: 320px) {
      font-size: 27px;
      line-height: 32px;
    }
  `}
`;

// TODO: add type safety
export const PWithMargin = styled.p<{margin: string | undefined}>`
  ${({margin}) => css`
    margin: ${{
      medium: '12px 8px',
    }[margin || 'medium']};
  `}
`;

export const StruckOutPrice = styled.span`
  ${({theme}) => css`
    text-decoration: line-through;
    font-weight: normal;
    color: ${theme.color.secondaryA100};
  `}
`;

interface ColorizedCheckOrCrossCircleIconProps {
  // TODO: add type safety
  checked: any;
}
export const ColorizedCheckOrCrossCircleIcon = styled(Icon).attrs<
  ColorizedCheckOrCrossCircleIconProps
>(({checked}) => ({
  variation: checked ? Icon.variations.CHECK_CIRCLE : Icon.variations.CLOSE_CIRCLE,
}))<ColorizedCheckOrCrossCircleIconProps>`
  ${({theme, checked}) => css`
    color: ${checked ? theme.color.success : theme.color.error};
    height: 20px;
    width: 20px;
  `}
`;

export const FlagListItem = styled(ListItem)`
  ${({theme}) => css`
    &,
    &:hover {
      color: ${theme.text.color.tertiary};
      margin-bottom: 16px;
    }
    &,
    &:last-child {
      border-radius: 7px;
      border: ${theme.content.outlinedSection.border};
    }
    &:last-child {
      margin-bottom: 0;
    }

    ${Subtitle} {
      opacity: 0.6;
    }
  `}
`;

export const Flag = styled.div`
  ${({theme}) => css`
    background-color: ${theme.content.outlinedSection.background};
    padding: 16px 24px;

    & > p {
      font-size: ${theme.content.outlinedSection.fontSize};
      font-weight: 400;
      line-height: 18px;
      margin: ${theme.content.outlinedSection.fontMargin};
    }

    ${FlagListItem} + p {
      margin-top: 16px;
    }
  `}
`;

export const CenterImg = styled.img`
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  width: 120px;

  @media (min-width: 576px) {
    width: 170px;
  }
`;

export const DetailCallout = styled.div`
  ${({theme}) => css`
    background-color: ${theme.color.primaryA100};
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    padding: 16px;
    position: relative;
    margin: 16px -16px;

    @media (min-width: 321px) {
      font-size: 18px;
    }

    & > span {
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 8px;
    }
  `}
`;

export const DetailCalloutEdit = styled(ActionLink)`
  ${({theme}) => css`
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
  `}
`;

export const ConfettiImg = styled.img`
  height: 304px;
  left: 50%;
  margin-left: -187.5px;
  pointer-events: none;
  position: absolute;
  top: -152px;
  width: 375px;
  z-index: 100;

  animation: 3s ease-out 0s 1 tada;
  opacity: 0;
  transform: scale3d(1, 1.3, 1) translate3d(0, 456px, 0);
  transition: all;

  @keyframes tada {
    0% {
      opacity: 0.2;
      transform: scale3d(0, 0, -0.5) translate3d(0, -24px, 0);
    }
    7% {
      opacity: 1;
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: scale3d(1.2, 1.3, 1.2) translate3d(0, 456px, 0);
    }
  }
`;

export const Highlight = styled.span<{baseColor?: keyof BaseColors}>`
  ${({theme, baseColor = 'primary'}) => css`
    background-color: ${theme.color[(baseColor + '100') as keyof Color]};
    color: ${theme.color[baseColor as keyof Color]};
    border-radius: ${theme.standard.borderRadius};
    padding: 1px 5px;
  `}
`;

export const SecondaryButtonAlt = styled(Button)`
  ${({theme}) => css`
    background: ${theme.color.primary100};
    color: ${theme.color.primary};
    border: none;
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.color.primary200};
        box-shadow: none;
        border: none;
        color: ${theme.content.highlightedSection.button.color};
      }
      &:focus {
        background: ${theme.color.primary100};
        background-color: ${theme.color.primary100};
        border: none;
        box-shadow: 0px 5px 12px ${theme.color.secondaryA100};
        color: ${theme.content.highlightedSection.button.color};
      }
    }

    &:link,
    &:visited {
      color: ${theme.color.primaryContrast};
    }
    &:active {
      background-color: ${theme.color.primary300};
      box-shadow: none;
      border: none;
      color: ${theme.color.primaryContrast};
    }

    &:disabled {
      background-color: ${theme.color.primaryA300};
    }
    ${() => {
      return css`
        border: none;
        display: inline-block;
        margin: 0 auto;
        min-width: 215px;
        padding: 2px;
        width: auto;
      `;
    }};
  `}
`;
