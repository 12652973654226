/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function PersonOutline({className}: {className: string}) {
  return (
    <svg
      role="img"
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.625C8.47901 2.625 5.62501 5.479 5.62501 9C5.62501 11.328 6.87303 13.364 8.73603 14.477C6.18403 15.459 4.25302 17.564 3.92402 20.205C3.84102 20.871 4.39301 21.375 5.00001 21.375C5.63401 21.375 6.08903 20.866 6.18403 20.317C6.57403 18.06 8.90201 16.125 12 16.125C15.098 16.125 17.426 18.06 17.816 20.317C17.911 20.866 18.366 21.375 19 21.375C19.607 21.375 20.159 20.871 20.076 20.205C19.747 17.564 17.816 15.459 15.264 14.477C17.127 13.364 18.375 11.328 18.375 9C18.375 5.479 15.521 2.625 12 2.625ZM7.87501 9C7.87501 6.722 9.72201 4.875 12 4.875C14.278 4.875 16.125 6.722 16.125 9C16.125 11.278 14.278 13.125 12 13.125C9.72201 13.125 7.87501 11.278 7.87501 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
