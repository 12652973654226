/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Exclude({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM2 8c0-1.293.416-2.49 1.115-3.471l8.356 8.356C10.49 13.584 9.293 14 8 14c-3.308 0-6-2.692-6-6zm12 0c0 1.293-.416 2.49-1.115 3.471L4.53 3.115C5.51 2.416 6.707 2 8 2c3.308 0 6 2.692 6 6z"
        fill="currentColor"
      />{' '}
    </svg>
  );
}
