/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

/** Recognized currency types */
export enum CurrencyType {
  /** US Dollar */
  USD = 'USD',
  /** US Cent */
  USC = 'USC',
  /** AU Dollar */
  AUD = 'AUD',
  /** AU Cent */
  AUC = 'AUC',
  /** CA Dollar */
  CAD = 'CAD',
  /** CA Cent */
  CAC = 'CAC',
}
