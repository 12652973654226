/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import styled, {css} from 'styled-components';

export const FloatingPageContainer = styled.div<{
  widthPx?: number;
}>`
  ${({theme, widthPx = 512}) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.content.background};
    color: ${theme.text.color.primary};
    flex: 1;
    align-self: stretch;
    @media (min-width: ${Math.max(widthPx + 32, 576)}px) {
      align-self: center;
      border: ${theme.standard.border};
      border-radius: ${theme.standard.borderRadiusLg};
      box-shadow: ${theme.standard.boxShadow};
      flex: initial;
      width: ${widthPx + 'px'};
      height: auto;
      margin: 80px 16px;
    }
  `}
`;
