/* eslint-disable max-len */
import {MockedResponse} from '@apollo/react-testing';
import {AccountSetupStatus, ViewerFirstLoadDocument, ViewerFirstLoadQuery} from '../api.generated';

export const viewerFirstLoadMock = {
  request: {
    query: ViewerFirstLoadDocument,
  },
  result: {
    data: {
      viewer: {
        __typename: 'User',
        features: [
          {
            __typename: 'Feature',
            _id: 'user1:feature1',
            description: 'icon variant with cash symbol',
            isEnabled: true,
            key: 'Presentational.PrimaryNav.RewardsCashIcon',
            userOverrideState: 'NONE',
          },
        ],
        googleRhr: {
          __typename: 'UserGoogleRhrData',
          enrollmentAppUri:
            'https://home.nest.com/login/?next=%2Fenergy-partner-enrollment%3Fid%f106ac68-e64f-4ccf-a6ab-2e5f4f14826d',
          expectedPartnerName: 'f106ac68-e64f-4ccf-a6ab-2e5f4f14826d',
          expectedTermsOfServiceUri:
            'https://support.google.com/product-documentation/answer/11335402',
          id: 'UserGoogleRhrData:1',
        },
        uiCustomization: {
          __typename: 'UiCustomization',
          customBrandBarImageUrl: null,
        },
      },
      currentSession: {
        __typename: 'Session',
        hasPartnerAccountLinkingRedirect: false,
        id: '121',
        user: {
          __typename: 'User',
          id: '1',
          accountSetupStatus: AccountSetupStatus.Complete,
        },
      },
    } as ViewerFirstLoadQuery,
  },
};

// this creates a typecheck test to ensure that our mock is a valid mock object,
// without creating type inference issues downstream.

// if there is a type error on the last line, the mock isn't valid

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const typeCheck: MockedResponse<ViewerFirstLoadQuery> = viewerFirstLoadMock;
