import {useEffect, useState} from 'react';

export function useImagePreload(src?: string) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (!src) {
      return;
    }
    setIsImageLoaded(false);
    const img = new Image();
    img.src = src;
    img.onload = () => setIsImageLoaded(true);
  }, [src]);

  return isImageLoaded;
}
