/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function QuestionCircleOutline({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 25C1 11.7463 11.7463 1 25 1C38.2537 1 49 11.7463 49 25C49 38.2537 38.2537 49 25 49C11.7463 49 1 38.2537 1 25Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M22.686 34.817C22.686 36.254 23.852 37.42 25.29 37.42C26.73 37.42 27.894 36.254 27.894 34.817C27.894 33.379 26.73 32.212 25.29 32.212C23.852 32.212 22.686 33.379 22.686 34.817Z"
        fill="currentColor"
      />
      <path
        d="M27.427 29.608C27.402 28.225 27.492 27.102 29.375 25.07C30.786 23.549 32.538 21.658 32.572 18.777C32.594 16.848 31.98 15.194 30.792 13.99C29.525 12.706 27.638 12 25.48 12C20.936 12 18 15.229 18 20.229H22.188C22.188 17.133 23.915 16.035 25.392 16.035C26.715 16.035 28.115 16.912 28.236 18.59C28.367 20.353 27.425 21.25 26.232 22.383C23.292 25.181 23.236 26.535 23.25 29.608H27.427Z"
        fill="currentColor"
      />
    </svg>
  );
}
