/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function RealTime({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 27.6619V25.8706C2.46312 25.8706 1.96581 24.5976 2.7281 23.8948L26 2.43657L49.2719 23.8948C50.0342 24.5976 49.5369 25.8706 48.5 25.8706V27.6619H46.7087L46.7087 48.9222H5.29131V27.6619H3.5ZM40.2913 6.34499V4.09647H40.6261C42.6047 4.09647 44.2087 5.70046 44.2087 7.67908V9.95707L40.2913 6.34499Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3.58262"
      />
      <circle cx="25.9998" cy="30.6435" r="15.3528" fill="white" />
      <path
        d="M26.0002 17.1715C33.4286 17.1715 39.4721 23.215 39.4721 30.6434C39.4721 38.0718 33.4286 44.1152 26.0002 44.1152C18.5718 44.1152 12.5284 38.0718 12.5284 30.6434C12.5284 23.215 18.5718 17.1715 26.0002 17.1715ZM26.0002 14.4772C17.0724 14.4772 9.83398 21.7156 9.83398 30.6434C9.83398 39.5712 17.0724 46.8096 26.0002 46.8096C34.928 46.8096 42.1664 39.5712 42.1664 30.6434C42.1664 21.7156 34.928 14.4772 26.0002 14.4772ZM33.8785 31.2618C34.1507 31.3129 34.1507 31.7104 33.8799 31.7629C31.3108 32.2492 25.7362 33.2596 25.0599 33.2596C24.0912 33.2596 23.3072 32.4756 23.3072 31.5069C23.3072 30.8172 24.3445 24.1688 24.8228 21.4771C24.8686 21.2185 25.2431 21.2333 25.2848 21.496L26.6118 29.8997L33.8785 31.2618Z"
        fill="currentColor"
      />
    </svg>
  );
}
