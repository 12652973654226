import React from 'react';
import {Routes} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const SentryRoutes: React.FC<{children: React.ReactNode}> = ({children}) => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return <SentryRoutes>{children}</SentryRoutes>;
};

export default SentryRoutes;
