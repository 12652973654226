/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function BoltCircle({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM16.8259 10.259C16.8795 10.285 16.9238 10.3224 16.9544 10.3674C16.985 10.4124 17.0007 10.4633 17 10.515C16.9992 10.5666 16.9821 10.6172 16.9502 10.6616L11.4558 18.8171C11.4137 18.8725 11.3505 18.9146 11.2764 18.9366C11.2022 18.9587 11.1214 18.9594 11.0467 18.9387C10.972 18.9181 10.9077 18.8772 10.8641 18.8225C10.8205 18.7679 10.8001 18.7027 10.8061 18.6373L11.4164 13.726H6.89117C6.82879 13.726 6.76754 13.7122 6.71375 13.6859C6.65995 13.6596 6.61554 13.6218 6.5851 13.5764C6.55467 13.531 6.53931 13.4797 6.5406 13.4277C6.5419 13.3758 6.5598 13.325 6.59247 13.2807L12.1792 5.12433C12.2224 5.06902 12.2866 5.02743 12.3616 5.00626C12.4366 4.98509 12.518 4.98557 12.5927 5.00762C12.6673 5.02967 12.7309 5.07201 12.7731 5.12783C12.8154 5.18364 12.8338 5.24968 12.8256 5.31531L12.0554 10.2192H16.6493C16.7113 10.2192 16.7723 10.2329 16.8259 10.259Z"
        fill="currentColor"
      />
    </svg>
  );
}
