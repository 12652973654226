/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import styled from 'styled-components';

export const PaddedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 36px;
  flex: 1;
  z-index: 0;
  @media (min-width: 576px) {
    padding: 32px 80px;
  }
`;

export const SlimPaddedContentContainer = styled(PaddedContentContainer)`
  padding: 24px;
  @media (min-width: 576px) {
    padding: 32px 56px;
  }
`;
