/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ComponentProps} from '../../../types';
import {Button} from '../Button';

export type BackButtonProps = ComponentProps<typeof Button> & {
  to?: string;
  href?: string;
  preventDefault?: boolean;
};

export const BackButton: React.FC<React.PropsWithChildren<BackButtonProps>> = ({
  href,
  to,
  onClick = () => null,
  preventDefault = false,
  children,
  ...props
}) => {
  const renderElement = href ? 'a' : to ? Link : Button;
  const navigate = useNavigate();

  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    onClick(e);
    if (!href && !to && !preventDefault) navigate(-1);
  }

  // if there's no history before this view (eg. deep link from
  // email or SMS, bookmark, etc.), and we haven't set an explicit
  // url, path, or click handler, the button will do nothing,
  // so we should hide it.
  //
  // eslint-disable-next-line no-restricted-globals
  const noWayBack = !href && !to && history.length < 2;

  return noWayBack ? null : (
    <Button as={renderElement} {...{...props, href, to}} onClick={handleClick}>
      {children}
    </Button>
  );
};
