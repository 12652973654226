/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import api from 'api';
import {useCallback, useEffect} from 'react';
import {ActionType, GlobalStoreState, useGlobalDispatch, useGlobalState} from 'store';
import {useGetUserIsAuthenticated} from 'store/selectors';
import {INTERVAL_POLLING_EXPIRY} from 'config/globals';
import useInterval from './useInterval';

export function useFeaturesHaveBeenFetched(): boolean {
  const state: GlobalStoreState = useGlobalState();

  return !!state?.features?.updated;
}

export function useFeature(
  feature: string | ((tree: typeof api.Features.Tree) => string),
): boolean {
  const state: GlobalStoreState = useGlobalState();
  const safeFeature = typeof feature === 'string' ? feature : feature(api.Features.Tree);

  return state?.features?.featureKeys?.includes(safeFeature) ?? false;
}

/**
 * searches for any feature which matches the wildcard feature string passed
 *
 * ### Currently uses `startsWith` up to the first `*` found in the wildcard
 * TODO: improve wildcard searching with custom regex transform
 *
 * @param featureWildcard a wildcard feature to search for, currently only supports
 * `startsWith` matching up to the first `*` found in the wildcard
 * @returns
 */
export function useFeatureWildcard(featureWildcard: string): boolean {
  const state: GlobalStoreState = useGlobalState();

  // take everything before the first * in the feature wildcard
  const [cleanedWildcard] = featureWildcard.split('*', 1);

  return (
    state?.features?.featureKeys?.some(featureKey => featureKey.startsWith(cleanedWildcard)) ??
    false
  );
}

/**
 * starts a refresh interval to fetch features from the backend
 * */
export function useRefreshFeatures() {
  // this hook is not covered by tests (due to awkward testing of useInterval)
  // but useFetchFeatures is covered.

  const {doFetch} = useFetchFeatures();
  // 5 minutes interval with expiry
  useInterval(doFetch, 5 * 60 * 1000, INTERVAL_POLLING_EXPIRY);
  useEffect(() => {
    doFetch();
  }, [doFetch]);
}

/**
 * async hook to fetch features once
 */
export function useFetchFeatures() {
  const dispatch = useGlobalDispatch();
  const userIsAuthenticated = useGetUserIsAuthenticated();
  const {isLoading, lastUpdated, doFetch: fetchFeatures} = api.Features.useGetAllFeatureKeys();

  // Rewrap the useFetch callback, and only execute if authenticated
  const doFetch = useCallback(() => {
    if (!userIsAuthenticated) {
      // not authenticated, features are empty, so don't fetch
      dispatch({
        type: ActionType.SET_FEATURES,
        payload: [],
      });
      // nothing to cleanup
      return () => {};
    } else {
      // otherwise, make the fetch and return the cleanup fn
      return fetchFeatures({
        actionResolver: r => ({
          type: ActionType.SET_FEATURES,
          payload: r.data ?? [],
        }),
      });
    }
  }, [dispatch, fetchFeatures, userIsAuthenticated]);

  return {isLoading, updated: lastUpdated, doFetch};
}
