/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function BoltCircle({className}) {
  return (
    <svg
      {...{className}}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4281 8.47144C19.3798 8.40057 19.3099 8.34162 19.2253 8.30061C19.1408 8.2596 19.0446 8.23799 18.9468 8.23799H11.6998L12.9148 0.511417C12.9278 0.408009 12.8987 0.303958 12.832 0.216018C12.7654 0.128077 12.6651 0.0613639 12.5473 0.0266196C12.4296 -0.00812459 12.3012 -0.00887808 12.1829 0.0244805C12.0646 0.0578391 11.9632 0.123369 11.8951 0.210518L3.08195 13.0617C3.03041 13.1315 3.00216 13.2114 3.00012 13.2933C2.99808 13.3752 3.02231 13.456 3.07032 13.5275C3.11833 13.599 3.18838 13.6585 3.27325 13.7C3.35812 13.7414 3.45474 13.7633 3.55314 13.7633H10.6918L9.72899 21.5015C9.71953 21.6046 9.75175 21.7073 9.82052 21.7933C9.88929 21.8794 9.99066 21.9438 10.1085 21.9764C10.2264 22.009 10.3539 22.0078 10.4709 21.9731C10.5878 21.9384 10.6875 21.8721 10.7539 21.7848L19.4214 8.93499C19.4717 8.86511 19.4988 8.78539 19.5 8.70396C19.5011 8.62252 19.4763 8.54229 19.4281 8.47144Z"
        fill="currentColor"
      />
    </svg>
  );
}
