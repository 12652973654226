/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import api from 'api';
import {useFeature} from 'hooks/useFeature';

/**
 * true if the user is in ohm perks
 */
export function useIsOhmPerks() {
  const isOhmPerks = useFeature(api.Features.Tree.Onboarding.Welcome.OhmPerksCopy);
  return isOhmPerks;
}
