/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */

import React from 'react';

export default function Stopwatch({className}) {
  return (
    <svg
      {...{className}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3332 8.49056C13.3332 11.4361 10.9454 13.8239 7.99984 13.8239C5.05432 13.8239 2.6665 11.4361 2.6665 8.49056C2.6665 5.54504 5.05432 3.15723 7.99984 3.15723C10.9454 3.15723 13.3332 5.54504 13.3332 8.49056ZM10.5758 6.48014C10.841 6.16193 10.798 5.68901 10.4798 5.42383C10.1616 5.15866 9.68868 5.20165 9.42351 5.51986L7.75684 7.51986C7.49167 7.83807 7.53466 8.31099 7.85287 8.57617C8.17108 8.84134 8.644 8.79835 8.90917 8.48014L10.5758 6.48014Z"
        fill="currentColor"
      />
      <path
        d="M10.4798 5.42383L10.3198 5.61589V5.61589L10.4798 5.42383ZM10.5758 6.48014L10.7679 6.64018V6.64018L10.5758 6.48014ZM9.42351 5.51986L9.61556 5.67991L9.42351 5.51986ZM7.75684 7.51986L7.56479 7.35982L7.75684 7.51986ZM8.90917 8.48014L9.10123 8.64018L8.90917 8.48014ZM7.99984 14.0739C11.0834 14.0739 13.5832 11.5742 13.5832 8.49056H13.0832C13.0832 11.298 10.8073 13.5739 7.99984 13.5739V14.0739ZM2.4165 8.49056C2.4165 11.5742 4.91625 14.0739 7.99984 14.0739V13.5739C5.19239 13.5739 2.9165 11.298 2.9165 8.49056H2.4165ZM7.99984 2.90723C4.91625 2.90723 2.4165 5.40697 2.4165 8.49056H2.9165C2.9165 5.68311 5.19239 3.40723 7.99984 3.40723V2.90723ZM13.5832 8.49056C13.5832 5.40697 11.0834 2.90723 7.99984 2.90723V3.40723C10.8073 3.40723 13.0832 5.68311 13.0832 8.49056H13.5832ZM10.3198 5.61589C10.5319 5.79267 10.5606 6.10795 10.3838 6.32009L10.7679 6.64018C11.1215 6.21591 11.0641 5.58534 10.6399 5.23178L10.3198 5.61589ZM9.61556 5.67991C9.79235 5.46777 10.1076 5.43911 10.3198 5.61589L10.6399 5.23178C10.2156 4.87821 9.58502 4.93554 9.23145 5.35982L9.61556 5.67991ZM7.9489 7.67991L9.61556 5.67991L9.23145 5.35982L7.56479 7.35982L7.9489 7.67991ZM8.01292 8.38411C7.80078 8.20733 7.77212 7.89205 7.9489 7.67991L7.56479 7.35982C7.21122 7.78409 7.26855 8.41466 7.69282 8.76822L8.01292 8.38411ZM8.71712 8.32009C8.54034 8.53223 8.22505 8.56089 8.01292 8.38411L7.69282 8.76822C8.1171 9.12179 8.74766 9.06446 9.10123 8.64018L8.71712 8.32009ZM10.3838 6.32009L8.71712 8.32009L9.10123 8.64018L10.7679 6.64018L10.3838 6.32009Z"
        fill="currentColor"
      />
      <path
        d="M6.25 1.5094H9.75"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
