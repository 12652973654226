/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Return({className}: {className: string}) {
  return (
    <svg
      role="img"
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="prompt_suggestion_FILL0_wght400_GRAD0_opsz24 1" clipPath="url(#clip0_1082_3000)">
        <path
          id="Ellipse 1"
          d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C16.2566 1 19.9502 3.41746 21.7797 6.95897C22.5593 8.46802 23 10.1811 23 12Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          id="Vector 287"
          d="M20.5 4C20.5 3.44772 20.0523 3 19.5 3C18.9477 3 18.5 3.44772 18.5 4H20.5ZM18.5 4V7.5H20.5V4H18.5ZM13.5 12.5H7V14.5H13.5V12.5ZM18.5 7.5C18.5 10.2614 16.2614 12.5 13.5 12.5V14.5C17.366 14.5 20.5 11.366 20.5 7.5H18.5Z"
          fill="currentColor"
        />
        <path
          id="Vector 288"
          d="M9 10.5L6 13.5L9 16.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1082_3000">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
