/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {useGlobalState} from 'store';
import {get} from 'lodash';

export function useExperimentVariant() {
  const globalState = useGlobalState();
  return useCallback(experiment => get(globalState.experiments, `${experiment}`), [
    globalState.experiments,
  ]);
}

export function useExperimentExposed() {
  const globalState = useGlobalState();
  return useCallback(experiment => get(globalState.experiments, `${experiment}`) === 'exposed', [
    globalState.experiments,
  ]);
}
