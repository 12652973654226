/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function CheckboxChecked({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3572 5H23.6428C24.7266 4.99999 25.6007 4.99998 26.3086 5.05782C27.0375 5.11737 27.6777 5.24318 28.27 5.54497C29.2108 6.02433 29.9757 6.78924 30.455 7.73005C30.7568 8.32234 30.8826 8.96253 30.9422 9.69138C31 10.3992 31 11.2734 31 12.3572V23.6429C31 24.7267 31 25.6008 30.9422 26.3086C30.8826 27.0375 30.7568 27.6777 30.455 28.27C29.9757 29.2108 29.2108 29.9757 28.27 30.455C27.6777 30.7568 27.0375 30.8826 26.3086 30.9422C25.6008 31 24.7266 31 23.6428 31H12.3571C11.2733 31 10.3992 31 9.69138 30.9422C8.96253 30.8826 8.32234 30.7568 7.73005 30.455C6.78924 29.9757 6.02433 29.2108 5.54497 28.27C5.24318 27.6777 5.11737 27.0375 5.05782 26.3086C4.99998 25.6007 4.99999 24.7266 5 23.6428V12.3572C4.99999 11.2734 4.99998 10.3993 5.05782 9.69138C5.11737 8.96253 5.24318 8.32234 5.54497 7.73005C6.02433 6.78924 6.78924 6.02433 7.73005 5.54497C8.32234 5.24318 8.96253 5.11737 9.69138 5.05782C10.3993 4.99998 11.2734 4.99999 12.3572 5ZM16.3369 25.8842L26.6 12.2L23.4 9.8L15.6631 20.1158L11.2804 16.4636L8.71964 19.5364L16.3369 25.8842Z"
        fill="currentColor"
      />
    </svg>
  );
}
