import React, {useState} from 'react';
import axios from 'axios';
import {get} from 'lodash';
import {useGlobalDispatch, ActionType} from 'store';
import {useNavigate} from 'react-router-dom';

import {captureException} from 'utils';
import {CAN_NAVIGATOR_SHARE} from 'config/globals';
import LoadingSpinner from 'components/shared/svgs/LoadingSpinner';
import useMountEffect from 'hooks/useMountEffect';
import useQuery from 'hooks/useQuery';
import {Button, ButtonVariation} from 'components/shared/Button';
import {FloatingPageLayout} from '../shared/layouts';

export default function DisenrollmentRequest() {
  const query = useQuery();
  const globalDispatch = useGlobalDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useMountEffect(() => {
    async function disenroll() {
      try {
        const accessToken = query.get('access_token');
        if (!accessToken) return navigate('/', {replace: true});

        const response = await axios.get(`/disenroll?access_token=${accessToken}`);
        if (get(response.data, 'user')) {
          globalDispatch({
            type: ActionType.SET_USER,
            payload: response.data.user,
          });
        }

        if (get(response.data, 'url')) {
          window.location = response.data.url;
          return;
        }

        if (CAN_NAVIGATOR_SHARE && response.data) {
          setData(response.data);
          setLoading(false);
          return;
        } else {
          return navigate('/', {replace: true});
        }
      } catch (error) {
        captureException(error);
      }
      return navigate('/', {replace: true});
    }

    disenroll();
  });

  return loading ? (
    <LoadingSpinner id="DisenrollmentRequest" />
  ) : (
    <FloatingPageLayout>
      Click the button below and we'll compose an email requesting {get(data, 'utility_short_name')}{' '}
      remove you from {get(data, 'drp_name')}.
      <br />
      <br />
      All you need to do is add {get(data, 'drp_email')} into the TO line and hit send.
      <br />
      <br />
      <Button
        variation={ButtonVariation.PRIMARY}
        onClick={async event => {
          if (!data) return navigate('/', {replace: true});
          try {
            await navigator.share(data);
          } catch (error) {}
        }}
      >
        Send Disenrollment Email
      </Button>
    </FloatingPageLayout>
  );
}
