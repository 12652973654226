export enum Country {
  AU = 'AU',
  US = 'US',
  CA = 'CA',
}

export interface State {
  name: string;
  abbreviation: string;
}
