/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function MoonOutline({className}: {className: string}) {
  return (
    <svg
      role="img"
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1035 3.69408C14.6835 5.14808 14.8235 6.74008 14.5135 8.27408C14.2035 9.80808 13.4435 11.2161 12.3335 12.3241C11.2235 13.4311 9.81348 14.1891 8.28348 14.5041C6.75348 14.8181 5.15353 14.6761 3.70353 14.0941C3.52353 14.0181 3.32345 13.9981 3.12345 14.0361C2.93345 14.0741 2.75344 14.1691 2.61344 14.3081C2.47344 14.4471 2.38354 14.6241 2.34354 14.8171C2.30354 15.0101 2.32347 15.2111 2.39347 15.3941C3.00347 16.9301 3.99346 18.2901 5.26346 19.3511C6.53346 20.4121 8.04349 21.1411 9.66349 21.4741C11.2935 21.8061 12.9635 21.7311 14.5535 21.2561C16.1335 20.7801 17.5734 19.9191 18.7434 18.7491C19.9134 17.5791 20.7835 16.1371 21.2535 14.5521C21.7335 12.9671 21.8035 11.2891 21.4735 9.66808C21.1435 8.04708 20.4135 6.53408 19.3535 5.26508C18.2936 3.99508 16.9335 3.00908 15.3935 2.39408C15.2135 2.32108 15.0135 2.30308 14.8235 2.34308C14.6335 2.38208 14.4535 2.47707 14.3135 2.61507C14.1735 2.75407 14.0835 2.93008 14.0435 3.12108C14.0035 3.31308 14.0235 3.51208 14.0935 3.69408H14.1035ZM16.5735 5.35407C17.4835 6.04507 18.2235 6.92207 18.7635 7.92607C19.3035 8.93007 19.6235 10.0371 19.7035 11.1741C19.7835 12.3111 19.6135 13.4521 19.2135 14.5191C18.8135 15.5861 18.1935 16.5561 17.3835 17.3631C16.5835 18.1701 15.6135 18.7951 14.5435 19.1981C13.4835 19.6001 12.3435 19.7701 11.2035 19.6971C10.0635 19.6231 8.95353 19.3071 7.95353 18.7701C6.94353 18.2331 6.06345 17.4881 5.37345 16.5841C6.89345 16.7851 8.44347 16.6321 9.89347 16.1391C11.3535 15.6451 12.6735 14.8231 13.7535 13.7381C14.8435 12.6521 15.6635 11.3311 16.1535 9.87708C16.6435 8.42308 16.7935 6.87507 16.5935 5.35407H16.5735Z"
        fill="currentColor"
      />
    </svg>
  );
}
