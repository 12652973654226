/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Disconnected({className}) {
  return (
    <svg
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M9.933 12.761L8.08 14.614c-1.848 1.848-4.846 1.848-6.694 0-1.848-1.849-1.848-4.846 0-6.694l1.852-1.853 1.414 1.414L2.8 9.334c-1.066 1.065-1.066 2.8 0 3.865 1.066 1.066 2.8 1.066 3.866 0l1.852-1.852 1.415 1.414zM7.92 1.386L6.067 3.239l1.414 1.414L9.334 2.8c1.066-1.065 2.8-1.065 3.864 0 1.068 1.066 1.068 2.8.002 3.865l-1.852 1.853 1.414 1.414 1.852-1.852c1.848-1.849 1.848-4.846 0-6.694-1.849-1.848-4.846-1.848-6.694 0zM3.956.856l.96-.136.292 2.055-.958.137L3.956.856zM.72 4.916l.137-.96 2.056.293-.137.96L.72 4.916zm3.195-1.68l-.685.686-1.47-1.469.686-.686 1.47 1.468zm8.127 11.906l-.96.136-.292-2.055.96-.137.292 2.056zm3.238-4.06l-.138.96-2.056-.292.138-.96 2.056.292zm-3.196 1.68l.685-.686 1.47 1.47-.686.685-1.469-1.469z"
      />
    </svg>
  );
}
