/* Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved */
import {UserSubscriptions} from './subscriptions.types';

export const ORG_SUBSCRIPTIONS: Partial<UserSubscriptions> = {
  device: {
    device_activation: {label: 'Device Activation Help', email: 'device_activation'},
    device_connection: {
      label: 'Device Connection Issues',
      email: 'device_connection',
      sms: 'device_connection_sms',
    },
    device_ohmhour_issues: {
      label: 'Event Device Issues',
      email: 'device_ohmhour_issues',
    },
  },
  onboarding: {
    onboarding_help: {
      label: 'Onboarding Help',
      email: 'onboarding_help',
      sms: 'onboarding_help_sms',
    },
  },
  promos: {
    promos_offers: {label: 'Promotions & Offers', email: 'promos_offers', sms: 'promos_offers_sms'},
    promos_surveys: {label: 'User Surveys', email: 'promos_surveys'},
    promos_weekly_summary: {
      label: 'Weekly Energy Snapshot',
      email: 'promos_weekly_summary',
    },
  },
};
