/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import Alert from './icons/Alert';
import AlertCircle from './icons/AlertCircle';
import Announce from './icons/Announce';
import ArchiveLine from './icons/ArchiveLine';
import ArrowRight from './icons/ArrowRight';
import ArrowUpRight from './icons/ArrowUpRight';
import Bolt from './icons/Bolt';
import BoltLine from './icons/BoltLine';
import BoltCircle from './icons/BoltCircle';
import Calendar from './icons/Calendar';
import CalendarOutline from './icons/CalendarOutline';
import CalendarEmpty from './icons/CalendarEmpty';
import CaratLeft from './icons/CaratLeft';
import CaratLight from './icons/CaratLight';
import CaratRight from './icons/CaratRight';
import CheckboxChecked from './icons/CheckboxChecked';
import CheckCircle from './icons/CheckCircle';
import Checkmark from './icons/Checkmark';
import CheckboxOutline from './icons/CheckboxOutline';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import Clock from './icons/Clock';
import Close from './icons/Close';
import CloseCircle from './icons/CloseCircle';
import CloudCheck from './icons/CloudCheck';
import CloudOff from './icons/CloudOff';
import CloudOutline from './icons/CloudOutline';
import DataLine from './icons/DataLine';
import Devices from './icons/Devices';
import Disconnected from './icons/Disconnected';
import Discount from './icons/Discount';
import Download from './icons/Download';
import EcoLeaf from './icons/EcoLeaf';
import ExCircle from './icons/ExCircle';
import Exclude from './icons/Exclude';
import ExternalLink from './icons/ExternalLink';
import FacebookSolid from './icons/FacebookSolid';
import Gear from './icons/Gear';
import GearOutline from './icons/GearOutline';
import Home from './icons/Home';
import HomeBolt from './icons/HomeBolt';
import House from './icons/House';
import Heart from './icons/Heart';
import InfoCircle from './icons/InfoCircle';
import InfoCircleArrow from './icons/InfoCircleArrow';
import Information from './icons/Information';
import InstagramOutline from './icons/InstagramOutline';
import LeafPlugOutline from './icons/LeafPlugOutline';
import LinkLine from './icons/LinkLine';
import List from './icons/List';
import Lock from './icons/Lock';
import LogoutLine from './icons/LogoutLine';
import MailLine from './icons/MailLine';
import MegaphoneLine from './icons/MegaphoneLine';
import MoneyLine from './icons/MoneyLine';
import MoonOutline from './icons/MoonOutline';
import More from './icons/More';
import NopeCircle from './icons/NopeCircle';
import OhmIcon from './icons/OhmIcon';
import OhmLogo from './icons/OhmLogo';
import Order from './icons/Order';
import PayPalLine from './icons/PayPalLine';
import PeopleGroup from './icons/PeopleGroup';
import PersonCircle from './icons/PersonCircle';
import PersonOutline from './icons/PersonOutline';
import PhoneLine from './icons/PhoneLine';
import PhoneOutline from './icons/PhoneOutline';
import Power from './icons/Power';
import QRCode from './icons/QRCode';
import QuestionCircle from './icons/QuestionCircle';
import QuestionCircleOutline from './icons/QuestionCircleOutline';
import RealTime from './icons/RealTime';
import ReferralMadness from './icons/ReferralMadness';
import Refresh from './icons/Refresh';
import Return from './icons/Return';
import Search from './icons/Search';
import ShippingBoxHalfFill from './icons/ShippingBoxHalfFill';
import ShippingBoxOutline from './icons/ShippingBoxOutline';
import ShippingBoxOutlineTransparent from './icons/ShippingBoxOutlineTransparent';
import SortAscending from './icons/SortAscending';
import SortDescending from './icons/SortDescending';
import SortNone from './icons/SortNone';
import StarLine from './icons/StarLine';
import Stopwatch from './icons/Stopwatch';
import StopwatchOutline from './icons/StopwatchOutline';
import StreakFlame from './icons/StreakFlame';
import StreakShield from './icons/StreakShield';
import SupportLine from './icons/SupportLine';
import Timer from './icons/Timer';
import Tools from './icons/Tools';
import Transfer from './icons/Transfer.js';
import Unsupported from './icons/Unsupported';
import Verified from './icons/Verified';
import TrendLineDown from './icons/TrendLineDown';
import TrendLineUp from './icons/TrendLineUp';
import SuccessCircle from './icons/SuccessCircle';
import {PlusCircle} from './icons/PlusCircle';
import {Plus} from './icons/Plus';
import {BoxWithUpArrow} from './icons/BoxWithUpArrow';

const iconTypesMapping: Record<string, (props: {className: string}) => JSX.Element> = {
  ALERT: Alert,
  ALERT_CIRCLE: AlertCircle,
  ANNOUNCE: Announce,
  ARCHIVE_LINE: ArchiveLine,
  ARROW_RIGHT: ArrowRight,
  ARROW_UP_RIGHT: ArrowUpRight,
  BOLT: Bolt,
  BOLT_LINE: BoltLine,
  BOLT_CIRCLE: BoltCircle,
  BOX_WITH_UP_ARROW: BoxWithUpArrow,
  CALENDAR: Calendar,
  CALENDAR_OUTLINE: CalendarOutline,
  CALENDAR_EMPTY: CalendarEmpty,
  CARAT_LEFT: CaratLeft,
  CARAT_LIGHT: CaratLight,
  CARAT_RIGHT: CaratRight,
  CHECK_CIRCLE: CheckCircle,
  CHECKBOX_CHECKED: CheckboxChecked,
  CHECKBOX_OUTLINE: CheckboxOutline,
  CHECKMARK: Checkmark,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  CLOCK: Clock,
  CLOSE: Close,
  CLOSE_CIRCLE: CloseCircle,
  CLOUD_CHECK: CloudCheck,
  CLOUD_OFF: CloudOff,
  CLOUD_OUTLINE: CloudOutline,
  DATA_LINE: DataLine,
  DEVICES: Devices,
  DISCONNECTED: Disconnected,
  DISCOUNT: Discount,
  DOWNLOAD: Download,
  ECO_LEAF: EcoLeaf,
  EX_CIRCLE: ExCircle,
  EXCLUDE: Exclude,
  EXTERNAL_LINK: ExternalLink,
  FACEBOOK_SOLID: FacebookSolid,
  GEAR: Gear,
  GEAR_OUTLINE: GearOutline,
  HOME: Home,
  HOME_BOLT: HomeBolt,
  HOUSE: House,
  HEART: Heart,
  INFO_CIRCLE: InfoCircle,
  INFO_CIRCLE_ARROW: InfoCircleArrow,
  INFORMATION: Information,
  INSTAGRAM_OUTLINE: InstagramOutline,
  LEAF_PLUG_OUTLINE: LeafPlugOutline,
  LINK_LINE: LinkLine,
  LIST: List,
  LOCK: Lock,
  LOGOUT_LINE: LogoutLine,
  MAIL_LINE: MailLine,
  MEGAPHONE_LINE: MegaphoneLine,
  MONEY_LINE: MoneyLine,
  MOON_OUTLINE: MoonOutline,
  MORE: More,
  NOPE_CIRCLE: NopeCircle,
  OHM_ICON: OhmIcon,
  OHM_LOGO: OhmLogo,
  ORDER: Order,
  PAYPAL_LINE: PayPalLine,
  PEOPLE_GROUP: PeopleGroup,
  PERSON_OUTLINE: PersonOutline,
  PERSON_CIRCLE: PersonCircle,
  PHONE_LINE: PhoneLine,
  PHONE_OUTLINE: PhoneOutline,
  PLUS: Plus,
  PLUS_CIRCLE: PlusCircle,
  POWER: Power,
  QR_CODE: QRCode,
  QUESTION_CIRCLE: QuestionCircle,
  QUESTION_CIRCLE_OUTLINE: QuestionCircleOutline,
  REAL_TIME: RealTime,
  REFERRAL_MADNESS: ReferralMadness,
  REFRESH: Refresh,
  RETURN: Return,
  SEARCH: Search,
  SHIPPING_BOX_HALF_FILL: ShippingBoxHalfFill,
  SHIPPING_BOX_OUTLINE: ShippingBoxOutline,
  SHIPPING_BOX_OUTLINE_TRANSPARENT: ShippingBoxOutlineTransparent,
  SORT_ASCENDING: SortAscending,
  SORT_DESCENDING: SortDescending,
  SORT_NONE: SortNone,
  STAR_LINE: StarLine,
  STOPWATCH: Stopwatch,
  STOPWATCH_OUTLINE: StopwatchOutline,
  STREAK_FLAME: StreakFlame,
  STREAK_SHIELD: StreakShield,
  SUPPORT_LINE: SupportLine,
  SUCCESS_CIRCLE: SuccessCircle,
  TIMER: Timer,
  TOOLS: Tools,
  TRANSFER: Transfer,
  UNSUPPORTED: Unsupported,
  VERIFIED: Verified,
  TREND_LINE_DOWN: TrendLineDown,
  TREND_LINE_UP: TrendLineUp,
};

export default iconTypesMapping;
