/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {get} from 'lodash';

import {useFetchSettings} from 'store/actions';
import {useGlobalState} from 'store';
import styled from 'styled-components';

const StyledProfilePicture = styled.img<{size: number}>`
  border-radius: 50%;
  height: ${({size}) => `${size}px`};
  margin: 0 auto 16px;
  overflow: hidden;
  width: ${({size}) => `${size}px`};
`;

export default function ProfilePictureDisplay({
  className,
  size = 80,
}: {
  className?: string;
  size?: number;
}) {
  useFetchSettings();
  const globalState = useGlobalState();
  let pictureUrl = get(globalState, 'userSettings.user_picture_url');
  pictureUrl = pictureUrl && pictureUrl.replace('original', 'large');

  return <StyledProfilePicture src={pictureUrl} {...{className, size}} />;
}
