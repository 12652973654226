/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */

interface KustomerLoginParams {
  jwtToken: string;
}

// There is more to Kustomer API, but right now we only care about login/logout
interface Kustomer {
  login: (options: KustomerLoginParams) => void;
  logout: () => void;
}

type CustomWindow = Window &
  typeof globalThis & {
    Kustomer?: Kustomer;
  };

export function logoutKustomer() {
  if (typeof (window as CustomWindow)?.Kustomer?.logout === 'function') {
    (window as CustomWindow)?.Kustomer?.logout();
  }
}

/**
 * Logs in to Kustomer with the provided identity token.
 * @param {string | undefined} kustomerIdentityToken - The identity token for Kustomer login.
 */
export function loginToKustomer(kustomerIdentityToken: string | undefined) {
  if (kustomerIdentityToken && typeof (window as CustomWindow)?.Kustomer?.login === 'function') {
    (window as CustomWindow)?.Kustomer?.login({jwtToken: kustomerIdentityToken});
  }
}
