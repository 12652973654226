/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function CaratLeft({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5379 3.63536L16.4621 6.36463L10.7358 12.5L16.4621 18.6354L13.5379 21.3646L5.26422 12.5L13.5379 3.63536Z"
        fill="currentColor"
      />
    </svg>
  );
}
