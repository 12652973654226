import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

export namespace NextBestAction {
  /**
   * Get the user's dashboard next-best-action
   * @param config Optional request config
   * @returns Promise of NextBestAction
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getNextBestActionDashboard<TResponse = NextBestAction, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/nba/dashboard`, config);
  }

  /**
   * Get the user's signup next-best-action
   * @param config Optional request config
   * @returns Promise of NextBestAction
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getNextBestActionSignup<TResponse = NextBestAction, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/nba/signup`, config);
  }

  /**********
   * Types
   **********/

  /* eslint-disable camelcase */
  export interface NextBestAction {
    display: boolean;
    content_module: string | null;
    content_name: string | null;
    pro_id: number | null;
    header: string | null;
    intro: string | null;
    fine_print: string | null;
    testimonials_name: string | null;
    footer_title: string | null;
    footer_cta_label: string | null;
    footer_skip_label: string | null;
    image_url: string | null;
    primary_cta_label: string | null;
    primary_cta_url: string | null;
    device_type: string | null;
    requires_rhr_terms_accept: string | null;
    cash_reward_value: string | null;
    devices_reward_value: string | null;
    devices_discount_dollars: string | null;
    option_total_value: string | null;
    max_clawback_amt: number | null;
    body: string | null;
    terms_and_conditions_mini: string | null;
    terms_and_conditions_url: string | null;
  }

  export enum Placement {
    Dashboard = 'Dashboard',
    Signup = 'Signup',
  }
}
