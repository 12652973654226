/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function QuestionCircle({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M25 0C11.194 0 0 11.194 0 25s11.194 25 25 25 25-11.194 25-25S38.806 0 25 0zm0 38.02c-1.438 0-2.604-1.166-2.604-2.603 0-1.438 1.166-2.605 2.604-2.605 1.44 0 2.604 1.167 2.604 2.605 0 1.437-1.164 2.604-2.604 2.604zm4.085-12.35c-1.883 2.032-1.973 3.155-1.948 4.538H22.96c-.014-3.073.042-4.427 2.982-7.225 1.193-1.133 2.135-2.03 2.004-3.793-.121-1.678-1.521-2.555-2.844-2.555-1.477 0-3.204 1.098-3.204 4.194H17.71c0-5 2.936-8.229 7.48-8.229 2.158 0 4.045.706 5.312 1.99 1.188 1.204 1.802 2.858 1.78 4.787-.034 2.881-1.786 4.773-3.197 6.294z"
      />
    </svg>
  );
}
