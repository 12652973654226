/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';
import {FlashedMessages} from './api.types';

export namespace Signup {
  /**********
   * Interactions
   **********/

  /**
   * Get signup step data
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getStep<TResponse = StepData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup`, config);
  }

  /**
   * Begin full signup process, with data from Redis
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function beginFromCache<TResponse = BeginData, TConfigData = BeginConfig>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    // data here is populated on the backend from redis, so we can't pass anything in here
    return await axios.post(`/api/v2/signup/signup_data`, undefined, config);
  }

  /**
   * Begin full signup process
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function begin<TResponse = BeginData, TConfigData = BeginConfig>(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/signup/full`, data, config);
  }

  /**
   * Update signup zip code
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function updateZip<TResponse = ZipData, TConfigData = ZipConfig>(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/signup/zip_code`, data, config);
  }

  /**
   * Check zip elegibility
   * @param zip Zip code to check fro eligibility
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function checkZipEligibility<TResponse = ZipEligibilityData, TConfigData = never>(
    zip: string,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup/check_zip_eligibility`, {...config, params: {zip}});
  }

  /**
   * Update signup password
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function updatePassword<TResponse = PasswordData, TConfigData = PasswordConfig>(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/signup/password`, data, config);
  }

  /**
   * Get whether utility is auth done
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getIsUtilityAuthDone<
    TResponse = IsUtilityAuthDoneData,
    TConfigData = never
  >(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup/is_utility_auth_done`, config);
  }

  /**
   * Get utility lists
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUtilityLists<TResponse = UtilityListsData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup/utility_lists`, config);
  }

  // ON-9365: We are now using selectUtility instead of calling this on every selection.
  /**
   * Update the user's utility service when they manually input a utility
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function updateUtility<
    TResponse = UpdateUtilityData,
    TConfigData = UpdateUtilityConfig
  >(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/signup/update_utility`, data, config);
  }

  /**
   * Select utility when user freely enters their own
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function selectUtility<
    TResponse = SelectUtilityData,
    TConfigData = SelectUtilityConfig
  >(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/signup/select_utility`, data, config);
  }

  /**
   * Gets data about a utility outage
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUtilityOutage<TResponse = UtilityOutageData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup/utility_outage`, config);
  }

  /**
   * Skip a signup step
   * @param step Step to skip
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getSkipStep<TResponse = SkipStepData, TConfigData = never>(
    step: Step,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/signup/skip/${step}`, config);
  }

  /**
   * Get conversion and confirmation info for Facebook conversion events
   * @param event Facebook event
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getFacebookConversionEvent<
    TResponse = FacebookConversionEventData,
    TConfigData = never
  >(
    event: string,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    /**
     * Step is not currently used on the BE and so could be any string.
     * Providing `handback` here as a placeholder as that's what was done in the past
     */
    const step = 'handback';
    return await axios.get(`/api/v2/signup/server_side_pixel_ping/${step}/${event}`, config);
  }

  /**********
   * Types
   **********/
  /**  */
  export interface StepData {
    /** Current step in the signup flow */
    step: Step;
    messages: FlashedMessages;
    /** TODO: type this */
    trackers: unknown;
    fb_conversion_id: string;
  }

  /**  */
  export interface BeginConfig {
    zip_code: string;
    first_name: string;
    email: string;
    password: string;
    phone?: string;
    recaptcha?: RecaptchaConfig;
  }
  /**  */
  export interface BeginData {
    signup_success: boolean;
    /** TODO: type this, same as user_settings */
    settings?: {system_email: string};
    /** TODO: type this */
    trackers: unknown;
    fb_conversion_id?: string;
  }
  /**  */
  interface RecaptchaConfig {
    label?: string;
    location: string;
    validators: unknown;
    always_present?: boolean;
  }

  /**  */
  export interface ZipConfig {
    zip_code: string;
    recaptcha?: RecaptchaConfig;
  }
  /**  */
  export interface ZipData {
    step: Step;
  }

  /** */
  export interface ZipEligibilityData {
    eligibility: ZipEligibility;
    approximateMembers: number;
    region: string;
    signupUrl: string;
  }
  /** */
  export enum ZipEligibility {
    OHM_PERKS_ELIGIBLE = 0,
    OHM_UTILITY_ELIGIBLE = 1,
    OHM_PARTIALLY_INELIGIBLE = 2,
  }

  /**  */
  export interface PasswordConfig {
    new_password: string;
    new_password_again: string;
  }
  /**  */
  export interface PasswordData {
    //
  }

  /** */
  export interface IsUtilityAuthDoneData {
    is_connected: boolean;
  }

  /** */
  export interface UtilityListsData {
    full_utility_list: UtilityListItem[];
    utility_choices: UtilityChoiceItem[];
  }
  /** */
  export interface UtilityListItem {
    order: number;
    value: string;
    displayLabel: string;
    displayName: string;
    acronym: string;
  }
  /** */
  export interface UtilityChoiceItem {
    value: string;
    displayLabel: string;
    displayName: string;
    acronym: string;
    cca_primary_utility_id?: string;
    primary_utility_acronym: string;
    primary_utility_short_name: string;
  }

  /**  */
  export interface UpdateUtilityConfig {
    utility_name: string;
  }
  /**  */
  export interface UpdateUtilityData {
    msg: string;
    acronym: string;
    utility_name: string;
  }

  /**  */
  export interface SelectUtilityConfig {
    zip_code: string;
    utility: string;
    utility_free_text: string;
  }
  /**  */
  export interface SelectUtilityData {
    msg: string;
    /** TODO: type this, same as user_settings */
    settings: {};
  }

  /**  */
  export interface UtilityOutageData {
    outage_found: boolean;
    planned_outage: unknown;
    utility_display_name: string;
    end_date?: string;
  }

  /**  */
  export interface SkipStepData {
    step: Step;
    messages: FlashedMessages;
  }

  /**  */
  export interface FacebookConversionEventData {
    conversionId: string;
    confirmationId: string | number;
  }

  /**
   * Possible steps in the signup flow
   * Format: `key = key`, so the key/value match for easy usage
   */
  export enum Step {
    'dashboard' = 'dashboard',
    'legacy' = 'legacy',
    'full' = 'full',
    'zip_code' = 'zip_code',
    'password' = 'password',
    'check_if_moved' = 'check_if_moved',
    'oauth' = 'oauth',
    'select_utility' = 'select_utility',
    'connect_utility' = 'connect_utility',
    'connect_utility_oauth' = 'connect_utility_oauth',
    'check_eligible' = 'check_eligible',
    'utility_outage' = 'utility_outage',
    'connect_phone' = 'connect_phone',
    'confirm_phone' = 'confirm_phone',
    'appliances' = 'appliances',
    'select_tplink' = 'select_tplink',
  }
}
