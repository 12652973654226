/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import {useEffect} from 'react';

/**
 * @deprecated if an empty effect dependency array is intended and necessary,
 * disable the lint rule and comment about the intention in a useEffect hook
 */
export default function useMountEffect(fun) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(fun, []);
}
