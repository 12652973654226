/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

interface StyledScrollContainerProps {
  ref?: React.MutableRefObject<HTMLDivElement | null>;
}
const StyledScrollContainer = styled.div<StyledScrollContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ScrollContainer: React.FC<React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>> = ({children, ...props}) => {
  const location = useLocation();
  const {pathname} = location;

  const scrollParentRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    // restore scroll to top on navigation
    if (scrollParentRef?.current) {
      scrollParentRef.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <StyledScrollContainer ref={scrollParentRef} {...props}>
      {children}
    </StyledScrollContainer>
  );
};
