import {Oem, NodeEnv} from '.';
import {ENV} from 'config/globals';

/**
 * Checks if the environment variable matches criteria with optional environment level
 *  (production, development, etc.)
 */
export function environmentEquals(oem: Oem, environment?: NodeEnv): boolean {
  const matchesOem = ENV.oem === oem;
  const matchesEnvironment = !environment || (environment && ENV.nodeEnv === environment);

  return matchesOem && matchesEnvironment;
}
