/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import {NodeEnv} from 'config/environments';
import {ENV} from 'config/globals';

/** Args for `trackStructuredEvent` */
export interface TrackStructuredEventArgs {
  category: string;
  action: string;
  label?: string;
  property?: string;
  value?: number;
}

/**
 * Track a structured event.
 * For more info about these fields, see https://github.com/snowplow/snowplow/wiki/
 *    2-Specific-event-tracking-with-the-Javascript-tracker#381-trackstructevent
 * @param props track structured event props
 */
export function trackStructuredEvent({
  category,
  action,
  label,
  property,
  value,
}: TrackStructuredEventArgs): void {
  if (!category || !action)
    // eslint-disable-next-line no-console
    return console.error('trackStructuredEvent: `category` and `action` are required');

  // explicitly pass through `undefined` but force evaluation of other falsey values:
  const newValue =
    typeof value !== 'undefined' ? (typeof value === 'string' ? parseFloat(value) : value) : value;
  if (typeof newValue !== 'undefined' && isNaN(newValue))
    // eslint-disable-next-line no-console
    return console.error('trackStructuredEvent: `value` must be numeric/float');

  const newAction = `${action}`.toLowerCase().substring(0, 255);
  const newCategory = `${category}`.toLowerCase().substring(0, 255);
  const newLabel = label && `${label}`.toLowerCase().substring(0, 255);
  const newProperty = property && `${property}`.toLowerCase().substring(0, 255);

  if (window.snowplowActive) {
    window.snowplow('trackStructEvent', newCategory, newAction, newLabel, newProperty, newValue);
  } else if (ENV.nodeEnv === NodeEnv.DEVELOPMENT) {
    // eslint-disable-next-line no-console
    console.debug('track snowplow structured event', {
      category: newCategory,
      action: newAction,
      label: newLabel,
      property: newProperty,
      value: newValue,
    });
  }
}
