/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import axios from 'axios';
import {FetchMethod, FetchResponse, FetchRequestConfig} from './interaction.types';

/**
 * Function to wrap fetch calls of various methods for strict type safety
 * @param args Fetch args
 * @template R (inferred) Type of response data
 * @template D (inferred) Type of config data passed in
 * @returns Promise of a response
 */
export function apiFetch<R, D>(
  method: FetchMethod,
  url: string,
  data?: D,
  config?: FetchRequestConfig<R, D>,
): Promise<FetchResponse<R, D>> {
  switch (method) {
    case FetchMethod.GET:
      return axios.get<R, FetchResponse<R, D>, D>(url, config);

    case FetchMethod.POST:
      return axios.post<R, FetchResponse<R, D>, D>(url, data, config);

    default:
      throw new Error('Invalid or unsupported api fetch method provided.');
  }
}
