/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Search({className}) {
  return (
    <svg
      role="img"
      {...{className}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.24998 12.5C10.1495 12.5 12.5 10.1495 12.5 7.24998C12.5 4.3505 10.1495 2 7.24998 2C4.3505 2 2 4.3505 2 7.24998C2 10.1495 4.3505 12.5 7.24998 12.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.962 10.9629L13.9995 14.0004"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
