/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function InfoCircleArrow({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 15L14 12L11 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="4.75"
        y="4.75"
        width="14.5"
        height="14.5"
        rx="7.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
