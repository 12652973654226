/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

/**
 * Set the browser theme color, to style the header and address bar on supported browsers.
 * By default, the theme is set to match the background-color of the Body element. Change the property
 * argument value to color based on a different property selector.
 *
 * Returns: a ref that can be used to pass to any other element, rather than the document body.
 *
 * Modified from an example in this article: https://css-tricks.com/meta-theme-color-and-trickery/
 */
export function useSetBrowserTheme<T extends HTMLElement>(property = 'background-color') {
  const elRef = React.useRef<T>(document.body as T);
  const [currentColor, setCurrentColor] = React.useState<string | null>(
    elRef.current?.style.getPropertyValue(property) ?? null,
  );

  function setBrowserThemeColor(color: string): void {
    const meta = document.querySelector('meta[name="theme-color"]');
    if (meta) {
      meta.setAttribute('content', color);
      setCurrentColor(color);
    } else {
      setCurrentColor(null);
    }
  }

  React.useEffect(() => {
    const cachedElRef = elRef.current;
    if (!window.IntersectionObserver) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.target) {
          const color = window.getComputedStyle(e.target).getPropertyValue(property);
          setBrowserThemeColor(color);
        }
      },
      {
        rootMargin: '1px 0px -100% 0px',
        threshold: 0.1,
      },
    );
    if (cachedElRef) {
      observer.observe(cachedElRef);
      return () => observer.unobserve(cachedElRef);
    } else return;
  }, [elRef, property]);
  return {ref: elRef, currentColor};
}
