/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {FetchInstance} from './interaction.types';
import {ActionType, useGlobalDispatch} from '../../store';

export const useSetFetchInstance = () => {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    (instance: FetchInstance) => {
      globalDispatch({
        type: ActionType.SET_FETCH_INSTANCE,
        payload: instance,
      });
    },
    [globalDispatch],
  );
};
export const useCompleteFetchInstance = () => {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    (instanceId: string) => {
      globalDispatch({
        type: ActionType.COMPLETE_FETCH_INSTANCE,
        payload: instanceId,
      });
    },
    [globalDispatch],
  );
};
export const useRemoveFetchInstances = () => {
  const globalDispatch = useGlobalDispatch();
  return useCallback(
    (instanceIds: string[]) => {
      globalDispatch({
        type: ActionType.REMOVE_FETCH_INSTANCES,
        payload: instanceIds,
      });
    },
    [globalDispatch],
  );
};
