/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import Icon from 'components/shared/Icon';

const StyledSnackbar = styled.div`
  ${({theme}) => css`
    ${({type}) => {
      if (type === 'error') {
        return css`
          background: ${theme.color.error300};
          color: ${theme.text.color.primaryContrast};
        `;
      } else if (type === 'success') {
        return css`
          background: ${theme.color.success300};
          color: ${theme.text.color.primaryContrast};
        `;
      } else {
        return css`
          background: ${theme.color.secondary};
          color: ${theme.color.secondaryContrast};
        `;
      }
    }}
    align-items: center;
    border-radius: 10px;
    box-shadow: ${theme.standard.boxShadow};
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    margin: 8px;
    padding: 22px 20px;

    @media (min-width: 576px) {
      margin: 8px auto;
      max-width: 576px;
      width: max-content;
    }
  `}
`;

const DismissButton = styled.button`
  ${({theme}) => css`
    align-items: center;
    appearance: none;
    background: none;
    border: none;
    color: unset;
    cursor: pointer;
    display: flex;
    flex: 0 0 40px;
    height: 40px;
    justify-content: space-around;
    margin: -7px -10px -7px 10px;
    padding: 0;
    width: 40px;
  `}
`;

const DismissIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;

export default function Snackbar({text, type = 'info', onDismiss, className}) {
  return (
    <StyledSnackbar {...{type, className}}>
      <div>{text}</div>
      {onDismiss && (
        <DismissButton onClick={onDismiss}>
          <DismissIcon variation={Icon.variations.CLOSE} />
        </DismissButton>
      )}
    </StyledSnackbar>
  );
}

Snackbar.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'success']),
  onDismiss: PropTypes.func,
};
