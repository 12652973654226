/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import axios from 'axios';

export namespace OhmPrivacy {
  /**
   * Update user privacy & data sharing consent flag
   * with active consent groups from OneTrust integration
   * @returns Promise of response
   */
  export async function updatePrivacy() {
    const data = {
      oneTrustActiveGroups: window?.OnetrustActiveGroups,
    };
    return await axios.post('/api/v2/update_privacy', data);
  }
}
