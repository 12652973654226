/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Unsupported({className}) {
  return (
    <svg
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M13.156 5.937C8.9 8.47 2.556 14.28 0 20h2.45c.682-1.837 2.1-4.124 3.208-5.366C12.647 18.041 20 12.112 20 4.323c0-1.396-.252-2.851-.767-4.323-1.956 2.835-4.706.956-9.042 1.477-6.381.768-9.302 6.18-7.304 10.491 2.922-3.104 7.936-5.8 10.269-6.03z"
      />
    </svg>
  );
}
