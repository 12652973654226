/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function StreakFlame({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0036 4.03261C12.0576 2.52606 10.8395 1.23418 9.77266 0.169255C9.70713 0.103803 9.62684 0.0550354 9.53855 0.0270501C9.45026 -0.000935217 9.35654 -0.00732099 9.26526 0.00842938C9.17399 0.0241798 9.08783 0.0616062 9.01402 0.117564C8.94022 0.173522 8.88092 0.246377 8.84111 0.330007L6.60888 5.01769L4.27352 3.46076C4.21018 3.41852 4.13913 3.3892 4.06444 3.37447C3.98975 3.35974 3.91289 3.35988 3.83826 3.3749C3.76362 3.38992 3.69269 3.41951 3.62951 3.46199C3.56633 3.50446 3.51215 3.55898 3.47008 3.62243C2.05998 5.7481 1.345 7.67289 1.345 9.34323C1.345 11.1087 2.04634 12.8019 3.29472 14.0503C4.54311 15.2987 6.23629 16 8.00177 16C9.76726 16 11.4604 15.2987 12.7088 14.0503C13.9572 12.8019 14.6585 11.1087 14.6585 9.34323C14.6585 7.54316 14.1172 5.80604 13.0036 4.03261ZM12.2921 10.0104C12.1539 10.9219 11.7277 11.7653 11.0758 12.4172C10.4239 13.0691 9.58053 13.4954 8.66902 13.6336C8.63954 13.6381 8.60977 13.6404 8.57995 13.6404C8.43416 13.6402 8.29381 13.5851 8.18694 13.4859C8.08007 13.3867 8.01456 13.2509 8.00349 13.1055C7.99242 12.9601 8.03661 12.8159 8.12722 12.7017C8.21784 12.5875 8.34821 12.5117 8.49229 12.4895C9.15794 12.3822 9.77285 12.0678 10.2496 11.5911C10.7264 11.1143 11.0407 10.4994 11.148 9.83375C11.1596 9.75863 11.1858 9.68653 11.2253 9.62156C11.2648 9.55659 11.3166 9.50003 11.378 9.45511C11.4393 9.41019 11.5089 9.37779 11.5827 9.35976C11.6565 9.34173 11.7332 9.33841 11.8083 9.35001C11.8835 9.36161 11.9556 9.38789 12.0205 9.42736C12.0855 9.46682 12.1421 9.5187 12.187 9.58002C12.2319 9.64135 12.2643 9.71092 12.2823 9.78476C12.3004 9.85861 12.3037 9.93528 12.2921 10.0104Z"
        fill="currentColor"
      />
    </svg>
  );
}
