/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Timer({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C14 4 15.848 4.65302 17.343 5.75702L17.293 5.70697C16.902 5.31697 16.902 4.68303 17.293 4.29303C17.653 3.93203 18.221 3.90502 18.613 4.21002L18.707 4.29303L20.707 6.29303C21.098 6.68303 21.098 7.31697 20.707 7.70697C20.347 8.06797 19.779 8.09498 19.387 7.78998L19.293 7.70697L19.243 7.65698C20.347 9.15198 21 11 21 13C21 17.971 16.971 22 12 22C7.029 22 3 17.971 3 13C3 8.029 7.029 4 12 4ZM12 6C8.134 6 5 9.134 5 13C5 16.866 8.134 20 12 20C15.866 20 19 16.866 19 13C19 9.134 15.866 6 12 6ZM12 8C12.552 8 13 8.448 13 9V13C13 13.552 12.552 14 12 14C11.448 14 11 13.552 11 13V9C11 8.448 11.448 8 12 8ZM15 2C15.552 2 16 2.448 16 3C16 3.552 15.552 4 15 4H9C8.448 4 8 3.552 8 3C8 2.448 8.448 2 9 2H15Z"
        fill="currentColor"
      />
    </svg>
  );
}
