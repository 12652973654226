/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Lock({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 5H11.25V3.25C11.25 2.52065 10.9603 1.82118 10.4445 1.30546C9.92882 0.789731 9.22935 0.5 8.5 0.5C7.77065 0.5 7.07118 0.789731 6.55546 1.30546C6.03973 1.82118 5.75 2.52065 5.75 3.25V5H3.5C3.23488 5.0003 2.9807 5.10576 2.79323 5.29323C2.60576 5.4807 2.5003 5.73488 2.5 6V13C2.5003 13.2651 2.60576 13.5193 2.79323 13.7068C2.9807 13.8942 3.23488 13.9997 3.5 14H13.5C13.7651 13.9997 14.0193 13.8942 14.2068 13.7068C14.3942 13.5193 14.4997 13.2651 14.5 13V6C14.4997 5.73488 14.3942 5.4807 14.2068 5.29323C14.0193 5.10576 13.7651 5.0003 13.5 5V5ZM6.75 3.25C6.75 2.78587 6.93437 2.34075 7.26256 2.01256C7.59075 1.68437 8.03587 1.5 8.5 1.5C8.96413 1.5 9.40925 1.68437 9.73744 2.01256C10.0656 2.34075 10.25 2.78587 10.25 3.25V5H6.75V3.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
