/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const NRG_BETA_ENV: EnvConfig = {
  ...(require('./nrg.production.env').NRG_PRODUCTION_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'NRG Beta',
  backendBaseUrl: 'https://beta.ohmconnect.com',
  //
  oceEnrollmentUrl: 'https://enroll.tx.ohmconnect.com',
  // Note: Key below is copied from backend config.
  googleRecaptchaSiteKey: '6LeaRuQUAAAAAKExB1eMoQqQaOpPJD0v77xXUmSL',
  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '2c7cc792-bcb3-42f2-8bf6-88c2102d1c6f',
  gmailClientId: '4363662406-njm489qj7j2q4j8n2oo2cs36qm1dsucb.apps.googleusercontent.com',
};
