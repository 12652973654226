/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Order({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0473 3.47128C18.2658 2.84291 19.1544 2.84291 19.3729 3.47128L20.0731 5.48579C20.1695 5.76299 20.4281 5.95094 20.7216 5.95692L22.8539 6.00037C23.519 6.01393 23.7936 6.8591 23.2635 7.26101L21.5639 8.54949C21.3301 8.72679 21.2313 9.03091 21.3162 9.3118L21.9338 11.3532C22.1265 11.9899 21.4075 12.5123 20.8615 12.1323L19.1109 10.9141C18.87 10.7465 18.5502 10.7465 18.3093 10.9141L16.5587 12.1323C16.0127 12.5123 15.2937 11.9899 15.4864 11.3532L16.104 9.3118C16.1889 9.03091 16.0901 8.72679 15.8563 8.54949L14.1567 7.26101C13.6266 6.8591 13.9012 6.01393 14.5663 6.00037L16.6986 5.95692C16.9921 5.95094 17.2507 5.76299 17.3471 5.48579L18.0473 3.47128Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1983 8.55615L45.1973 8.55498C45.1946 8.55185 45.1919 8.54872 45.189 8.54572L45.1831 8.54016L45.1809 8.53815L45.1737 8.53156C44.9192 8.27807 44.6044 8.09623 44.2607 8.00421L32.4027 4.84057C32.0636 4.75112 31.7083 4.7516 31.3711 4.84195C31.0339 4.93229 30.726 5.10948 30.477 5.35648L21.7895 14.0255C21.5335 14.2812 21.3499 14.6023 21.2577 14.9557L21.2561 14.961L21.2544 14.9668C21.2535 14.9704 21.2529 14.974 21.2522 14.9776L21.2505 14.9868C21.1662 15.328 21.1698 15.6863 21.2608 16.0274L24.4216 27.8236C24.4823 28.0494 24.5799 28.2625 24.7095 28.4544H22.8418L22.0038 24.2672C21.8825 23.6616 21.3508 23.2257 20.7332 23.2257H16.5502C15.8346 23.2257 15.2544 23.8058 15.2544 24.5215C15.2544 25.2371 15.8346 25.8173 16.5502 25.8173H19.671L22.2648 38.7763C22.4198 39.5557 22.8441 40.2567 23.4631 40.755C24.0787 41.2506 24.8482 41.5152 25.6382 41.5033H35.7808C36.5707 41.5152 37.3402 41.2506 37.9559 40.755C38.5751 40.2565 38.9993 39.5561 39.1542 38.7763L40.6443 30.9628L48.3517 23.2718C48.6065 23.017 48.7897 22.6975 48.8825 22.3459C48.9754 21.9942 48.9747 21.6229 48.8804 21.2698L45.7197 9.47369C45.6276 9.13161 45.4507 8.81858 45.2058 8.56475L45.1983 8.55615ZM40.12 28.5833L46.9237 21.7941L44.0675 11.1347L41.295 13.9293L42.7427 19.3323C42.8135 19.5966 42.7784 19.8778 42.645 20.1139C42.5116 20.3501 42.2909 20.5218 42.0315 20.5913C41.772 20.6608 41.495 20.6225 41.2614 20.4847C41.0279 20.3469 40.8568 20.1209 40.786 19.8566L39.6437 15.5936L36.3288 18.9349L38.7765 28.4544H39.5564C39.754 28.4544 39.9463 28.4995 40.12 28.5833ZM31.9049 6.83422L42.6073 9.68962L39.8493 12.4695L29.0799 9.65323L31.9049 6.83422ZM34.888 17.4702L24.0789 14.6435L27.3935 11.336L38.1762 14.1558L34.888 17.4702ZM23.8936 17.1168L26.9171 28.347H36.4197L34.26 20.1403L23.8936 17.1168Z"
        fill="currentColor"
      />
      <path
        d="M22.5746 45.4348C22.5746 44.1417 23.6229 43.0933 24.9161 43.0933C26.2093 43.0933 27.2576 44.1417 27.2576 45.4348C27.2576 46.728 26.2093 47.7764 24.9161 47.7764C23.6229 47.7764 22.5746 46.728 22.5746 45.4348Z"
        fill="currentColor"
      />
      <path
        d="M36.4185 43.0933C35.1253 43.0933 34.077 44.1417 34.077 45.4348C34.077 46.728 35.1253 47.7764 36.4185 47.7764C37.7117 47.7764 38.7601 46.728 38.7601 45.4348C38.7601 44.1417 37.7117 43.0933 36.4185 43.0933Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91094 24.8894C11.7277 24.8894 14.8219 21.7952 14.8219 17.9784C14.8219 14.1616 11.7277 11.0675 7.91094 11.0675C4.09413 11.0675 1 14.1616 1 17.9784C1 21.7952 4.09413 24.8894 7.91094 24.8894ZM7.04687 15.822C7.04687 16.5376 6.46672 17.1178 5.75107 17.1178C5.03542 17.1178 4.45527 16.5376 4.45527 15.822C4.45527 15.1063 5.03542 14.5261 5.75107 14.5261C6.46672 14.5261 7.04687 15.1063 7.04687 15.822ZM11.3661 15.822C11.3661 16.5376 10.786 17.1178 10.0703 17.1178C9.35467 17.1178 8.77452 16.5376 8.77452 15.822C8.77452 15.1063 9.35467 14.5261 10.0703 14.5261C10.786 14.5261 11.3661 15.1063 11.3661 15.822ZM11.4731 18.0416C11.9339 18.1651 12.2074 18.6388 12.0839 19.0996C11.8378 20.018 11.2956 20.8296 10.5412 21.4084C9.78687 21.9873 8.8626 22.301 7.91177 22.301C6.96094 22.301 6.03667 21.9873 5.28232 21.4084C4.52798 20.8296 3.9857 20.018 3.73961 19.0996C3.61613 18.6388 3.88961 18.1651 4.35046 18.0416C4.8113 17.9181 5.28499 18.1916 5.40847 18.6524C5.55613 19.2035 5.88149 19.6904 6.3341 20.0377C6.78671 20.385 7.34127 20.5733 7.91177 20.5733C8.48227 20.5733 9.03683 20.385 9.48944 20.0377C9.94205 19.6904 10.2674 19.2035 10.4151 18.6524C10.5385 18.1916 11.0122 17.9181 11.4731 18.0416Z"
        fill="currentColor"
      />
    </svg>
  );
}
