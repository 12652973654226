/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function PeopleGroup({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2761 6.17769C15.2933 6.81548 16.0066 7.89475 16.1364 9.14754C16.5513 9.34137 17.0117 9.45284 17.4999 9.45284C19.282 9.45284 20.7264 8.00841 20.7264 6.22656C20.7264 4.44443 19.282 3 17.4999 3C15.7348 3.00055 14.303 4.41917 14.2761 6.17769ZM12.1623 12.784C13.9444 12.784 15.3888 11.3393 15.3888 9.55744C15.3888 7.77559 13.9441 6.33116 12.1623 6.33116C10.3804 6.33116 8.93516 7.77586 8.93516 9.55772C8.93516 11.3396 10.3804 12.784 12.1623 12.784ZM13.5309 13.0039H10.7931C8.51509 13.0039 6.66185 14.8574 6.66185 17.1354V20.4836L6.67036 20.536L6.90099 20.6082C9.0749 21.2875 10.9636 21.514 12.5181 21.514C15.5544 21.514 17.3143 20.6483 17.4227 20.5931L17.6382 20.4841H17.6613V17.1354C17.6621 14.8574 15.8089 13.0039 13.5309 13.0039ZM18.8691 9.67303H16.1524C16.123 10.76 15.659 11.7388 14.9251 12.4427C16.9499 13.0448 18.4314 14.9225 18.4314 17.1403V18.1721C21.1138 18.0738 22.6596 17.3136 22.7614 17.2625L22.9769 17.1533H23V13.804C23 11.5263 21.1468 9.67303 18.8691 9.67303ZM6.50069 9.45339C7.13189 9.45339 7.71916 9.26916 8.21665 8.95535C8.37479 7.92385 8.92774 7.02249 9.71764 6.40859C9.72093 6.34818 9.7267 6.28833 9.7267 6.22738C9.7267 4.44525 8.28199 3.00082 6.50069 3.00082C4.71828 3.00082 3.27413 4.44525 3.27413 6.22738C3.27413 8.00869 4.71828 9.45339 6.50069 9.45339ZM9.39833 12.4427C8.66802 11.7423 8.20567 10.7688 8.1719 9.68841C8.07113 9.68099 7.97147 9.67303 7.86879 9.67303H5.13121C2.85324 9.67303 1 11.5263 1 13.804V17.1527L1.00851 17.2043L1.23914 17.2771C2.9831 17.8215 4.53955 18.0725 5.89145 18.1515V17.1403C5.892 14.9225 7.37294 13.0454 9.39833 12.4427Z"
        fill="currentColor"
      />
    </svg>
  );
}
