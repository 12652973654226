/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function FacebookSolid({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 7.33333H5V11H7.75V22H12.3333V11H15.67L16 7.33333H12.3333V5.8025C12.3333 4.88583 12.5075 4.58333 13.36 4.58333H16V0H12.5075C9.21667 0 7.75 1.44833 7.75 4.235V7.33333Z"
        fill="currentColor"
      />
    </svg>
  );
}
