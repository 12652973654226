/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {AppTopBrandBar} from '../AppTopBrandBar';
import styled from 'styled-components';
import {FloatingPageLayout, FloatingPageLayoutProps} from './FloatingPageLayout';
import {TopBrandBarBackButton} from '../TopBrandBarBackButton';

const StyledAppTopBrandBar = styled(AppTopBrandBar)`
  height: 60px;
`;

export const FloatingPageLayoutWithBrandBar: React.FC<FloatingPageLayoutProps> = ({
  topContent,
  ...props
}) => {
  return (
    <FloatingPageLayout
      {...props}
      topContent={
        <>
          <StyledAppTopBrandBar isInverted leftContent={<TopBrandBarBackButton />} />
          {topContent}
        </>
      }
    />
  );
};
