/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import {CurrencyType} from './currency.types';

/** */
export const currencyLocaleMap: Record<CurrencyType, string> = {
  [CurrencyType.USD]: 'en-US',
  [CurrencyType.USC]: 'en-US',
  [CurrencyType.AUD]: 'en-AU',
  [CurrencyType.AUC]: 'en-AU',
  [CurrencyType.CAD]: 'en-CA',
  [CurrencyType.CAC]: 'en-CA',
};

/**  */
export interface CurrencyFormatConfig
  extends Pick<Intl.NumberFormatOptions, 'notation' | 'currencyDisplay'> {
  // extend to provide narrow options in type
  notation: 'standard' | 'compact';
  currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name';
}

/**
 * Helper to format a value in an internationalized currency format
 *
 * Uses `Intl.NumberFormat` Documentation here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/
 *    Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * @returns formatted currency value
 */
export function formatForCurrency(
  value: number,
  currencyType: CurrencyType = CurrencyType.USD,
  options?: Partial<CurrencyFormatConfig>,
): string {
  const locale = currencyLocaleMap[currencyType];
  const numberFormatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyType,
    notation: 'compact',
    currencyDisplay: 'symbol',
    ...options,
  };

  switch (currencyType) {
    case CurrencyType.USC:
      // transform cents to dollars
      return new Intl.NumberFormat(locale, {
        ...numberFormatOptions,
        currency: CurrencyType.USD,
      }).format(value / 100);

    case CurrencyType.AUC:
      // transform cents to dollars
      return new Intl.NumberFormat(locale, {
        ...numberFormatOptions,
        currency: CurrencyType.AUD,
      }).format(value / 100);

    case CurrencyType.CAC:
      // transform cents to dollars
      return new Intl.NumberFormat(locale, {
        ...numberFormatOptions,
        currency: CurrencyType.CAD,
      }).format(value / 100);

    default:
      return new Intl.NumberFormat(locale, numberFormatOptions).format(value);
  }
}
