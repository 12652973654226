/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */

import React from 'react';

export default function CalendarOutline({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2222 4.76025H5.77778C4.79594 4.76025 4 5.54824 4 6.52026V18.8403C4 19.8123 4.79594 20.6003 5.77778 20.6003H18.2222C19.2041 20.6003 20 19.8123 20 18.8403V6.52026C20 5.54824 19.2041 4.76025 18.2222 4.76025Z"
        fill="currentColor"
        fillOpacity="0.05"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.556 3V6.52002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44458 3V6.52002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10.0398H20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
