/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import * as Sentry from '@sentry/react';
import moment from 'moment';
import pluralize from 'pluralize';
import jsSHA from 'jssha';
import axios from 'axios';
import {capitalize, get, orderBy, reduce} from 'lodash';
import {trackStructuredEvent} from './trackStructuredEvent';

import {NodeEnv} from 'config/environments/env.types';
import {ENV, INTERVAL_POLLING_EXPIRY} from 'config/globals';

export class Error403 extends Error {
  constructor(message) {
    super(message);
    this.name = 'Error403';
  }
}

export class Error404 extends Error {
  constructor(message) {
    super(message);
    this.name = 'Error404';
  }
}

export class RetryError extends Error {
  constructor(message) {
    super(message);
    this.name = 'RetryError';
  }
}

export function keyMirror(keys) {
  keys = Array.isArray(keys) ? keys : Object.keys(keys);
  var mirror = {};
  keys.forEach(v => (mirror[v] = v));
  return mirror;
}

export function titleCase(str) {
  return str
    .split(' ')
    .map(s => capitalize(s))
    .join(' ');
}

// 'points' currently equal 'cents'
export function formatPointsAsCurrency(points) {
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(points / 100);
}

export function formatPoints(points, label = false) {
  return `${new Intl.NumberFormat('en-US').format(points)}${
    label ? ` ${pluralize('points', points)}` : ''
  }`;
}

export function hashProperties(object, hashEmpty = false) {
  return reduce(
    object,
    (hashes, value, key) => {
      if (!value && !hashEmpty) {
        hashes[key] = '';
        return hashes;
      }

      // Second parameter of jsSha is the input type https://github.com/Caligatio/jsSHA/wiki#overview
      const sha = new jsSHA('SHA-256', 'TEXT', {encoding: 'UTF8'});
      sha.update(value.toString());

      hashes[key] = sha.getHash('HEX');
      return hashes;
    },
    {},
  );
}

// Check if an event is "pending" (will start in the next advanceMinutes)
export function isPendingEvent(event, advanceMinutes = 5) {
  const now = moment();
  return (
    moment(event.start_dttm).diff(now, 'minutes') >= 0 &&
    moment(event.start_dttm).diff(now, 'minutes') <= advanceMinutes
  );
}

// Check if an event is currently in progress
export function isInProgressEvent(event) {
  const now = moment();
  return now >= moment(event.start_dttm) && now <= moment(event.end_dttm);
}

export function formatEventDuration(startDttm, endDttm) {
  const eventDuration = moment.duration(moment(endDttm).diff(moment(startDttm)));
  const minutes = eventDuration.minutes();
  const hours = eventDuration.hours();
  return hours < 1 ? `${minutes} mins` : `${hours} hour${hours > 1 ? 's' : ''}`;
}

export function formatEventDateTime(dttm) {
  return moment(dttm).format('h:mmA MMM D');
}

// a better retry strategy for lazy loading compoment code
// and avoiding chunk load errors for out of date code
// based on examples from
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// and
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export function lazyRetry(componentImport, name = 'chunk') {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`lazy-${name}-retry-refreshed`) || 'false',
    );
    // try to dynamically import the component
    componentImport()
      .then(component => {
        // successful load, so reset the refresh flag
        window.sessionStorage.setItem(`lazy-${name}-retry-refreshed`, 'false');
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet, so set the flag: we're about to refresh
          window.sessionStorage.setItem(`lazy-${name}-retry-refreshed`, 'true');
          // refresh the page
          return window.location.reload();
        }
        // allow error to be thrown if we've already tried to refresh once
        reject(error);
      });
  });
}

// Register an event listener and call the passed in callback when the page visibility changes.
// Code taken from https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
export function listenForVisibilityChange(handleVisibilityChange) {
  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  // named handler functions, so they can be canceled/removed
  function documentVisibilityChangeHandler() {
    // @param isHidden
    handleVisibilityChange(document[hidden]);
  }
  function windowFocusHandler() {
    // @param isHidden
    handleVisibilityChange(false);
  }
  function windowBlurHandler() {
    // @param isHidden
    handleVisibilityChange(true);
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    // eslint-disable-next-line no-console
    console.log(
      'Your browser does not support the Page Visibility API. Please use an up to date version' +
        ' of a modern browser such as Google Chrome or Firefox',
    );
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, documentVisibilityChangeHandler, false);
  }
  // handle window blur and focus also
  window.addEventListener('focus', windowFocusHandler, false);
  window.addEventListener('blur', windowBlurHandler, false);

  // clean up function to unsubscribe event listeners
  return () => {
    document.removeEventListener(visibilityChange, documentVisibilityChangeHandler, false);
    window.removeEventListener('focus', windowFocusHandler, false);
    window.removeEventListener('blur', windowBlurHandler, false);
  };
}

// @todo Do we need to add another argument here like "description" that includes a description
// of the error to make it easily searchable? It could be a key. Let's assess how captured
// errors are showing up in sentry and decide if we need that extra data with captured exceptions.

// * `error` can be an exception or a string. If it's a string, we'll convert it to an exception.
// * `level` can be fatal, error, warning, info, or debug
//   cf. https://docs.sentry.io/platforms/javascript/#setting-the-level
// * If `extra` is not present, send `error.response.data`, if it exists, as `extra`.
//   (For ajax errors, the response data is valuable but not included by default.)
export function captureException(error, extra, level) {
  const exception = typeof error === 'string' ? new Error(error) : error;
  const extraScope = extra || get(error, 'response.data', {});

  Sentry.withScope(scope => {
    if (level) scope.setLevel(level);
    Object.keys(extraScope).forEach(k => scope.setExtra(k, extraScope[k]));
    Sentry.captureException(exception);
  });

  if (ENV.nodeEnv === NodeEnv.DEVELOPMENT) {
    // eslint-disable-next-line no-console
    console.debug({error, extra, level});
  }
}

export function trackSnowplowPageview(path) {
  if (window.snowplowActive) {
    window.snowplow('trackPageView');
  } else if (ENV.nodeEnv === NodeEnv.DEVELOPMENT) {
    // eslint-disable-next-line no-console
    console.debug('track snowplow pageview event', path);
  }
}

export function trackLinkClick(targetUrl, clickTrackId, elementContent) {
  // If clickTrackId is "true" the prop was empty e.g. <Icon data-clicktrackid variation=...>
  const newClickTrackId =
    !clickTrackId || clickTrackId === 'true' ? 'generic_link_click' : clickTrackId;
  trackStructuredEvent({
    category: 'link_click',
    action: newClickTrackId,
    label: targetUrl,
    property: elementContent,
  });
}

export function getMsUntilNextFetchUpcomingEventsInterval() {
  // Answers the question: how many milliseconds are there until the next
  // 10-minutes-before-the-AO-boundary mark i.e. xx:50, xx:05, xx:20, or xx:35.
  // The reason for this is that 10 minutes before an AO is when we'll know forsure if it was
  // extended or is ending, so that's when we want to fetch the upcoming events endpoint (which is
  // what tells us if the AO was extended or is ending).
  const currentDate = new Date();
  const potentialTargetDates = [5, 20, 35, 50].map(minuteValue => {
    let targetMinuteDate = new Date();
    targetMinuteDate.setMinutes(minuteValue);
    targetMinuteDate.setSeconds(0);
    targetMinuteDate.setMilliseconds(0);
    // If the time is in the past, bump it to the next hour but leave the minute alone
    // Thankfully we don't need to worry about midnight/day boundaries since events don't happen then
    if (targetMinuteDate < currentDate) targetMinuteDate.setHours(targetMinuteDate.getHours() + 1);
    return targetMinuteDate;
  });

  // Find the target date that is <= 15 mins in the future
  const nextTargetDate = potentialTargetDates.find(td => td - currentDate <= 1000 * 60 * 15);
  return nextTargetDate - currentDate;
}

export function isOhmHour(type) {
  return type === 'PARTICIPATION_REDUCTION';
}

export function isAutoOhm(type) {
  return type === 'PARTICIPATION_REDUCTION_RT';
}

export function getEventTypeDisplay(ohmhourType) {
  return isOhmHour(ohmhourType) ? ENV.reductionEvent : ENV.realtimeEvent;
}

export function sortDevices(devices, deviceConfigs) {
  const deviceLoadRank = {
    battery: 100,
    ev: 90,
    waterheater: 80,
    thermostat: 70,
    plug: 60,
    light: 50,
    assistant: 30,
    monitor: 10,
  };
  return orderBy(
    devices,
    [
      device => deviceLoadRank[get(deviceConfigs, `${device.deviceConfigId}.deviceType`)],
      'displayName',
    ],
    ['desc', 'asc'],
  );
}

export function trackPopoverClick(type, extra = {}) {
  trackStructuredEvent({
    category: 'info_popup',
    action: type,
    ...extra,
  });
}

// Get the name of the *current* main JS bundle.
export function getCurrentMainBundleName() {
  const scripts = document.querySelectorAll('script');
  for (let script of scripts) {
    let src = script.getAttribute('src');
    if (src && src.match(/^\/static\/js\/main..*\.js$/)) return src.split('/').slice(-1)[0];
  }
}

/**
 * Determine whether the current user is in substate `NoIntervalData`
 * and has been there for more than 7 days. The inverse of `isShortTermNoIntervalData`
 * @returns {boolean} whether a user has been `NoIntervalData` for an extended time
 */
export function isLongTermNoIntervalData(enrollSubstate, daysSinceLastEnrollState) {
  return enrollSubstate === 'NoIntervalData' && daysSinceLastEnrollState > 7;
}

/**
 * Determine whether the current user is in substate `NoIntervalData`
 * and has not been there for a long time. The inverse of `isLongTermNoIntervalData`
 * @returns {boolean} whether a user has been `NoIntervalData` for an extended time
 */
export function isShortTermNoIntervalData(enrollSubstate, daysSinceLastEnrollState) {
  return (
    enrollSubstate === 'NoIntervalData' &&
    !isLongTermNoIntervalData(enrollSubstate, daysSinceLastEnrollState)
  );
}

// setInterval but with expiry to clearInterval after the expiryTimeout (default = 10 minutes)
export function setIntervalWithTimeout(
  setTimer,
  callback,
  ms,
  expiryTimeout = INTERVAL_POLLING_EXPIRY,
) {
  const startTime = new Date().getTime();
  const timer = setInterval(() => {
    const currentTime = new Date().getTime();
    if (currentTime - startTime > expiryTimeout) {
      clearInterval(timer);
      return;
    }
    callback();
  }, ms);
  setTimer(timer);
}

/**
 * Creates a File object to be used in functions such as navigator.share
 * @param {string | undefined} path - local path to the file
 * @returns File | null
 */
export async function createFileObjFromPath(path) {
  if (!path) {
    return null;
  }

  const url = `${window.location.protocol}//${window.location.host}${path}`;
  try {
    const response = await axios.get(url, {responseType: 'blob'});
    const filePaths = path.split('/');
    return new File([response.data], filePaths[filePaths.length - 1]);
  } catch (error) {
    return null;
  }
}
