/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import styled, {useTheme} from 'styled-components';

const StyledSvg = styled.svg`
  vertical-align: text-bottom;
`;

export default function WattCoin({className}: {className?: string}) {
  const theme = useTheme();

  return (
    <StyledSvg
      {...{className}}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" />
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="9"
        fill={theme.color.currency}
        stroke={theme.color.currency}
        strokeWidth="2"
      />
      <path
        d="M15.0028 9.72304C15.0926 9.72304 15.1713 9.78277 15.1952 9.86927C15.3139 10.2988 15.4414 10.7786 15.5775 11.3087C15.7402 11.8883 15.9083 12.4908 16.0818 13.1159C16.2661 13.7411 16.4505 14.3662 16.6348 14.9914C16.8191 15.6165 16.9981 16.1962 17.1716 16.7304C17.3125 16.1507 17.4535 15.5199 17.5945 14.8379C17.7463 14.1559 17.8873 13.4512 18.0174 12.7238C18.1475 11.9963 18.2722 11.2746 18.3915 10.5585C18.4947 9.98158 18.5911 9.42255 18.6806 8.8814C18.7207 8.63936 18.9296 8.46136 19.175 8.46136H20.7793C21.0932 8.46136 21.3296 8.74728 21.2688 9.05527C20.9164 10.8405 20.5158 12.6487 20.0669 14.4799C19.5913 16.4626 19.0746 18.3482 18.5167 20.1367C18.4906 20.2201 18.4134 20.2767 18.326 20.2767H16.289C16.2039 20.2767 16.1281 20.2229 16.1003 20.1424C15.7371 19.0919 15.384 17.9944 15.0408 16.8498C14.6829 15.6449 14.3305 14.4401 13.9835 13.2353C13.6365 14.4401 13.2895 15.6449 12.9425 16.8498C12.6097 17.9944 12.2619 19.0919 11.8992 20.1424C11.8715 20.2229 11.7956 20.2767 11.7105 20.2767H9.67352C9.58615 20.2767 9.50887 20.2201 9.48284 20.1367C8.92465 18.3482 8.4025 16.4626 7.9164 14.4799C7.4772 12.6487 7.08193 10.8405 6.73058 9.05531C6.66996 8.74728 6.90628 8.46136 7.22022 8.46136H8.9384C9.18373 8.46136 9.39271 8.63936 9.43276 8.8814C9.52232 9.42255 9.61869 9.98158 9.72189 10.5585C9.85201 11.2746 9.98756 11.9963 10.1285 12.7238C10.2695 13.4512 10.4105 14.1559 10.5514 14.8379C10.7032 15.5199 10.8551 16.1507 11.0069 16.7304C11.2021 16.1735 11.3918 15.5824 11.5762 14.9573C11.7605 14.3321 11.9394 13.707 12.1129 13.0818C12.2864 12.4567 12.4437 11.8542 12.5846 11.2746C12.7201 10.7573 12.8426 10.2899 12.9521 9.87224C12.9752 9.7843 13.0547 9.72304 13.1456 9.72304H15.0028Z"
        fill={theme.color.currencyContrast}
      />
    </StyledSvg>
  );
}
