/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function PayPalLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6621 6.88991C18.895 5.42535 18.6722 4.49002 17.9038 3.62817C17.0901 2.71568 15.5433 2.25 13.3421 2.25H7.31625C7.08798 2.25013 6.86726 2.33053 6.69386 2.47671C6.52045 2.62289 6.40575 2.82524 6.37043 3.04731L3.75076 19.4404C3.74943 19.4477 3.74974 19.4552 3.75166 19.4623C3.75358 19.4695 3.75707 19.4761 3.76187 19.4818C3.76667 19.4875 3.77268 19.492 3.77947 19.4952C3.78626 19.4983 3.79367 19.5 3.80117 19.5H7.08451C7.24825 19.5 7.38774 19.3811 7.41365 19.2194L7.83757 16.5739L8.32056 13.6497C8.50206 13.0659 9.0506 12.6548 9.68586 12.6548H11.5856C15.4848 12.6548 17.8585 10.9937 18.5996 7.24736C18.6218 7.12821 18.643 7.01204 18.6621 6.88991Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M20.9856 11.3964C21.0664 10.9901 20.8026 10.5953 20.3964 10.5144C19.9901 10.4336 19.5953 10.6974 19.5144 11.1036L20.9856 11.3964ZM13.3117 16.1998V15.4498L13.3107 15.4498L13.3117 16.1998ZM12.5263 16.8563L13.2664 16.9775L13.2666 16.9763L12.5263 16.8563ZM11.8319 21.0936L11.0917 20.9723L11.0914 20.9743L11.8319 21.0936ZM11.0464 21.75V22.5H11.0466L11.0464 21.75ZM8.25 21C7.83579 21 7.5 21.3358 7.5 21.75C7.5 22.1642 7.83579 22.5 8.25 22.5V21ZM19.5144 11.1036C19.1792 12.788 18.4589 13.8407 17.5268 14.4885C16.5769 15.1486 15.318 15.4498 13.8063 15.4498V16.9498C15.4976 16.9498 17.0946 16.6154 18.3828 15.7202C19.6887 14.8127 20.5887 13.3905 20.9856 11.3964L19.5144 11.1036ZM13.8063 15.4498H13.3117V16.9498H13.8063V15.4498ZM13.3107 15.4498C12.9477 15.4503 12.5943 15.5771 12.3143 15.8111L13.2762 16.9621C13.2841 16.9555 13.2969 16.9498 13.3127 16.9498L13.3107 15.4498ZM12.3143 15.8111C12.0339 16.0455 11.8449 16.3728 11.7859 16.7362L13.2666 16.9763C13.267 16.974 13.2687 16.9683 13.2762 16.9621L12.3143 15.8111ZM11.7861 16.735L11.0917 20.9723L12.572 21.2149L13.2664 16.9775L11.7861 16.735ZM11.0914 20.9743C11.0911 20.9764 11.0895 20.9818 11.0821 20.988L12.0441 22.1389C12.3247 21.9044 12.5137 21.5766 12.5723 21.2128L11.0914 20.9743ZM11.0821 20.988C11.0744 20.9944 11.0618 21 11.0462 21L11.0466 22.5C11.41 22.4999 11.7638 22.3732 12.0441 22.1389L11.0821 20.988ZM11.0464 21H8.25V22.5H11.0464V21Z"
        fill="currentColor"
      />
    </svg>
  );
}
