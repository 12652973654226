/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig, Oem, LoginMethod, SnowPlowUrl} from '../env.types';
import {OHM_SUBSCRIPTIONS} from 'config/subscriptions';
import {Country} from 'config/regions';

export const OHM_PRODUCTION_ENV: EnvConfig = {
  oem: Oem.OHMCONNECT,
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Ohm Production',
  backendBaseUrl: 'https://login.ohmconnect.com',
  baseDocumentTitle: 'OhmConnect',
  country: Country.US,

  // sso and regular hard logins are the same for Ohm
  defaultLoginMethod: LoginMethod.PASSWORD,
  loginUrl: '/login',
  ssoLoginUrl: '/login',

  // Used in the webpage to configure events/program names
  oemName: 'OhmConnect',
  reductionEvent: 'OhmHour',
  realtimeEvent: 'AutoOhm',

  //
  oceEnrollmentUrl: 'https://enroll.tx.ohmconnect.com',
  // Note: Key below is copied from backend config.
  googleRecaptchaSiteKey: '6LeaRuQUAAAAAKExB1eMoQqQaOpPJD0v77xXUmSL',
  // Link to go "back" to an external utility account dashboard from our dashboard
  dashboardAccountLink: '',
  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '2c7cc792-bcb3-42f2-8bf6-88c2102d1c6f',
  facebookAppId: '514842256377849',
  gmailClientId: '145025664631-3t6etib0eu2jef8j699oclm9s4vs1glg.apps.googleusercontent.com',
  //
  facebookPageUrl: 'https://www.facebook.com/ohmconnect',
  instagramPageUrl: 'https://www.instagram.com/ohmconnect',
  twitterPageUrl: 'https://twitter.com/ohmconnect',
  //
  fullstoryOrganizationId: '11NDHN',
  // TODO: Fill in with the *real* merchant ID when we have Production access
  friendBuyMerchantId: '798f99c3-c37e-48d7-8ccc-a4f52e1277e0',
  //
  askShortUrl: 'http://ohm.co/ask',
  helpShortUrl: 'https://ohm.co/help',
  helpFormUrl: 'https://ohmconnectsurveys.typeform.com/to/gv5J6R',
  helpEmail: 'help@ohmconnect.com',
  privacyPolicyUrl: 'https://www.ohmconnect.com/privacy-policy',
  termsUrl: 'https://www.ohmconnect.com/terms-of-service',
  prizeRulesUrl: 'https://www.ohmconnect.com/prize-program-rules',
  shopifyBundlesVariantIds: [39385980829758, 39474549948478, 39810710765630],
  //
  cookieTargetingAllowedId: 'C0004',
  // cookie domain needs a `.` infront so it works for all subdomains
  cookieDomain: window.location.host.endsWith('.ohmconnect.com') ? '.ohmconnect.com' : undefined,
  //
  googleRhrEnrollUrl:
    'https://home.nest.com/login/' +
    '?next=%2Fenergy-partner-enrollment%3Fid%3Df106ac68-e64f-4ccf-a6ab-2e5f4f14826d',
  subscriptionList: OHM_SUBSCRIPTIONS,
  snowplowCollectorUrl: SnowPlowUrl.OHM,
  wattConversionRate: 100,

  emsAppUrl: 'https://ems.ohmconnect.com',
};
