/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */

import React from 'react';

export default function Calendar({className}) {
  return (
    <svg
      {...{className}}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3634 1.25686C5.36496 0.954828 5.12138 0.708722 4.81936 0.707161C4.51733 0.705599 4.27122 0.949175 4.26966 1.2512L5.3634 1.25686ZM4.2671 1.74713L4.26427 2.294L5.35801 2.29965L5.36083 1.75278L4.2671 1.74713ZM4.26966 1.2512L4.2671 1.74713L5.36083 1.75278L5.3634 1.25686L4.26966 1.2512Z"
        fill="currentColor"
      />
      <path
        d="M9.73584 1.25403C9.73584 0.951998 9.491 0.707153 9.18896 0.707153C8.88693 0.707153 8.64209 0.951998 8.64209 1.25403H9.73584ZM8.64209 1.74995V2.29683H9.73584V1.74995H8.64209ZM8.64209 1.25403V1.74995H9.73584V1.25403H8.64209Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03026 2.24585H8.64072L8.64209 2.62696L8.64441 3.27093C8.64549 3.57296 8.89121 3.81692 9.19324 3.81584C9.49527 3.81475 9.73923 3.56903 9.73815 3.267L9.73584 2.62304L9.73448 2.24585H10.9701C11.5965 2.24585 12.1043 2.73917 12.1043 3.3477V4.84888H1.896V3.3477C1.896 2.73917 2.40382 2.24585 3.03026 2.24585ZM1.896 5.94263V11.0607C1.896 11.6692 2.40382 12.1625 3.03026 12.1625H10.9701C11.5965 12.1625 12.1043 11.6692 12.1043 11.0607V5.94263H1.896ZM5.36084 2.83154V2.28467H4.26709V2.83154V3.26904C4.26709 3.57107 4.51193 3.81592 4.81396 3.81592C5.116 3.81592 5.36084 3.57107 5.36084 3.26904V2.83154Z"
        fill="currentColor"
      />
    </svg>
  );
}
