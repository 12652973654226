/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function House({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 19.1016H20V11.3965C19.9998 11.2107 19.9609 11.0271 19.8858 10.8572C19.8107 10.6873 19.7009 10.535 19.5636 10.4099L12.8964 4.34862C12.6515 4.12437 12.3315 3.99998 11.9995 4C11.6674 4.00002 11.3474 4.12443 11.1025 4.3487L4.43636 10.4099C4.29905 10.535 4.18933 10.6873 4.1142 10.8572C4.03906 11.027 4.00017 11.2107 4 11.3964V19.1016H2.66667C2.48986 19.1016 2.32029 19.1718 2.19526 19.2968C2.07024 19.4218 2 19.5914 2 19.7682C2 19.945 2.07024 20.1146 2.19526 20.2396C2.32029 20.3646 2.48986 20.4349 2.66667 20.4349H21.3333C21.5101 20.4349 21.6797 20.3646 21.8047 20.2396C21.9298 20.1146 22 19.945 22 19.7682C22 19.5914 21.9298 19.4218 21.8047 19.2968C21.6797 19.1718 21.5101 19.1016 21.3333 19.1016H21.3333ZM13.9995 19.1009H13.3328V19.1016H10.6662V19.1009H9.99951V15.1009C9.99952 14.924 10.0698 14.7545 10.1948 14.6295C10.3198 14.5044 10.4894 14.4342 10.6662 14.4342H13.3328C13.5097 14.4342 13.6792 14.5044 13.8043 14.6295C13.9293 14.7545 13.9995 14.924 13.9995 15.1009V19.1009Z"
        fill="currentColor"
      />
    </svg>
  );
}
