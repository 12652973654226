/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const TELUS_ALPHA_ENV: EnvConfig = {
  ...(require('./telus.production.env').TELUS_PRODUCTION_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Telus Alpha',
  backendBaseUrl: 'https://api.alpha.telus.ohmconnect.com',
};
