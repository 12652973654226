/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import styled, {css} from 'styled-components';
import {WithChildren} from 'types';
import Icon from '../Icon';

const ContentWrapper = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

const ContentIcon = styled(Icon)`
  ${({theme}) => css`
    width: 34px;
    height: 34px;
  `}
`;

const CaratIcon = styled(ContentIcon)`
  ${({theme}) => css`
    margin-right: -12px;
  `}
`;

export function ButtonContentWithIcon({
  iconVariation,
  label,
  showCarat = true,
  className,
  children,
}: WithChildren<{
  iconVariation?: string;
  label?: string;
  showCarat?: boolean;
  className?: string;
}>) {
  return (
    <ContentWrapper {...{className}}>
      {!!iconVariation ? <ContentIcon variation={iconVariation} /> : null}
      {!!label ? <span>{label}</span> : null}
      {children}
      {showCarat ? <CaratIcon variation={Icon.variations.CARAT_LIGHT} /> : null}
    </ContentWrapper>
  );
}
