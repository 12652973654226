/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function SortNone({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.269,3H6.728L6.409,3.25h3.175L9.269,3z M7.372,5H4.191L3.872,5.25h3.184L7.372,5z M8.634,2.5H7.366L7.047,2.75H8.95 L8.634,2.5z M10.541,4H5.459L5.141,4.25h5.712L10.541,4z M9.903,3.5H6.097L5.778,3.75h4.444L9.903,3.5z M8,2L7.681,2.25h0.634L8,2z M11.491,4.75L11.172,4.5H8l0.312,0.25H11.491z M8,4.5H4.825L4.506,4.75h3.181L8,4.5z M9.569,5.75h3.094l0.044-0.044L12.444,5.5 H9.256L9.569,5.75z M8.941,5.25h3.184L11.806,5H8.628L8.941,5.25z M11.916,6.5h-1.403l0.312,0.25h0.837L11.916,6.5z M11.141,7 l0.153,0.122L11.416,7H11.141z M6.431,5.75L6.744,5.5H3.556L3.294,5.706L3.338,5.75H6.431z M11.294,8.878L11.141,9h0.275 L11.294,8.878z M12.166,9.75H10.2L9.887,10h2.531L12.166,9.75z M11.666,9.25h-0.837L10.516,9.5h1.403L11.666,9.25z M12.416,6H9.884 l0.312,0.25h1.966L12.416,6z M8.628,11h3.181l0.319-0.25H8.941L8.628,11z M8,14l0.319-0.25H7.684L8,14z M6.731,13h2.541l0.319-0.25 H6.412L6.731,13z M6.097,12.5h3.809l0.319-0.25H5.778L6.097,12.5z M7.366,13.5h1.269l0.319-0.25H7.05L7.366,13.5z M5.459,12h5.078 l0.319-0.25H5.144L5.459,12z M4.509,11.25l0.319,0.25H8l-0.312-0.25H4.509z M9.569,10.25L9.256,10.5h3.188l0.262-0.206l-0.044-0.044 H9.569z M8,11.5h3.175l0.319-0.25H8.312L8,11.5z M4.706,8.878L4.584,9h0.275L4.706,8.878z M5.175,9.25H4.338L4.088,9.5h1.403 L5.175,9.25z M5.488,6.5H4.084l0.25,0.25h0.838L5.488,6.5z M4.584,7l0.122,0.122L4.859,7H4.584z M7.059,10.75H3.872L4.191,11h3.181 L7.059,10.75z M6.116,6H3.584l0.25,0.25H5.8L6.116,6z M6.431,10.25H3.338l-0.044,0.044L3.556,10.5h3.188L6.431,10.25z M5.803,9.75 H3.837L3.587,10h2.531L5.803,9.75z"></path>
    </svg>
  );
}
