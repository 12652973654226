import React from 'react';

const TrendLineDown = ({className}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.9729 8.97298C19.9729 8.70798 20.0779 8.45298 20.2659 8.26598C20.4529 8.07798 20.7079 7.97298 20.9729 7.97298C21.2379 7.97298 21.4928 8.07798 21.6798 8.26598C21.8678 8.45298 21.9729 8.70798 21.9729 8.97298V16.973C21.9729 17.238 21.8678 17.493 21.6798 17.68C21.4928 17.868 21.2379 17.973 20.9729 17.973H12.9729C12.7079 17.973 12.4529 17.868 12.2659 17.68C12.0779 17.493 11.9729 17.238 11.9729 16.973C11.9729 16.708 12.0779 16.453 12.2659 16.266C12.4529 16.078 12.7079 15.973 12.9729 15.973H18.5629L12.9729 10.383L9.67286 13.683C9.48587 13.866 9.23485 13.969 8.97285 13.969C8.71085 13.969 8.45984 13.866 8.27284 13.683L2.27284 7.68297C2.10784 7.49297 2.01985 7.24699 2.02785 6.99599C2.03585 6.74399 2.13887 6.50397 2.31587 6.32497C2.49187 6.14497 2.73082 6.03898 2.98182 6.02798C3.23382 6.01698 3.48086 6.09999 3.67286 6.26299L8.97285 11.573L12.2728 8.27297C12.4598 8.08997 12.7109 7.98699 12.9729 7.98699C13.2349 7.98699 13.4859 8.08997 13.6729 8.27297L19.9729 14.573V8.97298Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrendLineDown;
