/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import {FetchHook, FetchMethod, useFetch} from 'api/interaction';
import {OhmFeatures} from './featureTree.generated';

export namespace Features {
  /**********
   * Interactions
   **********/

  /**
   * Get feature keys granted to the user
   */
  export const useGetAllFeatureKeys: FetchHook<
    /** Response data */ string[],
    /** Config data ***/ never,
    /** Variables *****/ {}
  > = options => useFetch(FetchMethod.GET, variables => `/api/v2/features`, options);

  /** Features tree */
  export const Tree = OhmFeatures;

  /** Unique set of feature keys available to the user */
  export type FeatureKeysData = string[];
}
