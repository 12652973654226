/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {useCookies} from 'react-cookie';
import {get} from 'lodash';

export default function useIsLGWebView() {
  const [cookies] = useCookies(['lg_web_view']);
  return useCallback(() => {
    return get(cookies, 'lg_web_view') === 'true';
  }, [cookies]);
}
