/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function OhmLogo({className}) {
  return (
    <svg
      {...{className}}
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.63118 22.0166L17.2067 22.0166L15.5955 33.8319C7.34457 32.6645 0.998405 25.5735 0.998408 17.0002C0.998411 7.62201 8.59206 0.0174845 17.966 5.53093e-06L5.90822 20.7606C5.58448 21.318 5.98661 22.0166 6.63118 22.0166Z"
        fill="currentColor"
      />
      <path
        d="M20.4199 0.171131L18.7901 12.1231L29.3656 12.1231C30.0102 12.1231 30.4123 12.8217 30.0886 13.3791L18.1119 34C27.4485 33.9389 34.9984 26.3513 34.9984 17.0002C34.9984 8.43335 28.6618 1.34653 20.4199 0.171131Z"
        fill="currentColor"
      />
    </svg>
  );
}
