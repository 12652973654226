/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function AlertCircle({className}) {
  return (
    <svg
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="currentColor"
        d="M25 0C11.194 0 0 11.194 0 25s11.194 25 25 25 25-11.194 25-25S38.806 0 25 0zm-2.815 13.527c-.166-1.669 1.146-3.11 2.815-3.11s2.981 1.441 2.815 3.11l-1.67 16.688c-.06.587-.553 1.035-1.145 1.035s-1.085-.448-1.146-1.038l-1.669-16.685zM25 40.104c-1.438 0-2.604-1.166-2.604-2.604 0-1.438 1.166-2.604 2.604-2.604 1.438 0 2.604 1.166 2.604 2.604 0 1.438-1.166 2.604-2.604 2.604z"
      />
    </svg>
  );
}
