/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function CheckboxOutline({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3572 5H23.6428C24.7266 4.99999 25.6007 4.99998 26.3086 5.05782C27.0375 5.11737 27.6777 5.24318 28.27 5.54497C29.2108 6.02433 29.9757 6.78924 30.455 7.73005C30.7568 8.32234 30.8826 8.96253 30.9422 9.69138C31 10.3993 31 11.2734 31 12.3572V23.6428C31 24.7266 31 25.6008 30.9422 26.3086C30.8826 27.0375 30.7568 27.6777 30.455 28.27C29.9757 29.2108 29.2108 29.9757 28.27 30.455C27.6777 30.7568 27.0375 30.8826 26.3086 30.9422C25.6007 31 24.7266 31 23.6428 31H12.3572C11.2734 31 10.3993 31 9.69138 30.9422C8.96253 30.8826 8.32234 30.7568 7.73005 30.455C6.78924 29.9757 6.02433 29.2108 5.54497 28.27C5.24318 27.6777 5.11737 27.0375 5.05782 26.3086C4.99998 25.6007 4.99999 24.7266 5 23.6428V12.3572C4.99999 11.2734 4.99998 10.3993 5.05782 9.69138C5.11737 8.96253 5.24318 8.32234 5.54497 7.73005C6.02433 6.78924 6.78924 6.02433 7.73005 5.54497C8.32234 5.24318 8.96253 5.11737 9.69138 5.05782C10.3993 4.99998 11.2734 4.99999 12.3572 5ZM9.85424 7.05118C9.24907 7.10062 8.90138 7.19279 8.63803 7.32698C8.07354 7.6146 7.6146 8.07354 7.32698 8.63803C7.19279 8.90138 7.10062 9.24907 7.05118 9.85424C7.00078 10.4711 7 11.2634 7 12.4V23.6C7 24.7366 7.00078 25.5289 7.05118 26.1458C7.10062 26.7509 7.19279 27.0986 7.32698 27.362C7.6146 27.9265 8.07354 28.3854 8.63803 28.673C8.90138 28.8072 9.24907 28.8994 9.85424 28.9488C10.4711 28.9992 11.2634 29 12.4 29H23.6C24.7366 29 25.5289 28.9992 26.1458 28.9488C26.7509 28.8994 27.0986 28.8072 27.362 28.673C27.9265 28.3854 28.3854 27.9265 28.673 27.362C28.8072 27.0986 28.8994 26.7509 28.9488 26.1458C28.9992 25.5289 29 24.7366 29 23.6V12.4C29 11.2634 28.9992 10.4711 28.9488 9.85424C28.8994 9.24907 28.8072 8.90138 28.673 8.63803C28.3854 8.07354 27.9265 7.6146 27.362 7.32698C27.0986 7.19279 26.7509 7.10062 26.1458 7.05118C25.5289 7.00078 24.7366 7 23.6 7H12.4C11.2634 7 10.4711 7.00078 9.85424 7.05118Z"
        fill="currentColor"
      />
    </svg>
  );
}
