/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const HomeFill: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.8621C5 12.2258 5.3028 11.6274 5.81553 11.2506L14.4078 4.93529C14.7601 4.67631 15.2399 4.67631 15.5922 4.93529L24.1845 11.2506C24.6972 11.6274 25 12.2258 25 12.8621V24.5C25 25.0523 24.5523 25.5 24 25.5H19C18.4477 25.5 18 25.0523 18 24.5V17.4773C18 16.3727 17.1046 15.4773 16 15.4773H14C12.8954 15.4773 12 16.3727 12 17.4773V24.5C12 25.0523 11.5523 25.5 11 25.5H6C5.44772 25.5 5 25.0523 5 24.5V12.8621Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
