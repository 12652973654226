/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Devices({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6019 4C39.0899 4 39.4855 4.40892 39.4855 4.91335V5.66387C40.2809 5.7827 41.0597 6.04381 41.7092 6.46052C42.6078 7.03703 43.3275 7.96952 43.3275 9.24423C43.3275 9.74865 42.9319 10.1576 42.4438 10.1576C41.9558 10.1576 41.5602 9.74865 41.5602 9.24423C41.5602 8.77701 41.3194 8.36036 40.7772 8.01254C40.4261 7.78727 39.9791 7.61629 39.4855 7.51613V11.8733C40.2574 12.1556 41.0721 12.4869 41.7326 12.941C42.6143 13.5472 43.3275 14.4478 43.3275 15.8021C43.3275 17.0848 42.5989 18.0003 41.6893 18.5501C41.0422 18.9414 40.2706 19.1751 39.4855 19.2735V20.7976C39.4855 21.302 39.0899 21.711 38.6019 21.711C38.1138 21.711 37.7182 21.302 37.7182 20.7976V19.2735C36.9331 19.1751 36.1616 18.9414 35.5144 18.5501C34.6048 18.0003 33.8762 17.0848 33.8762 15.8021C33.8762 15.2976 34.2719 14.8887 34.7599 14.8887C35.2479 14.8887 35.6436 15.2976 35.6436 15.8021C35.6436 16.2613 35.8755 16.6522 36.4066 16.9733C36.7599 17.1869 37.214 17.3439 37.7182 17.4289V13.173C36.9464 12.8907 36.1317 12.5594 35.4711 12.1053C34.5894 11.4991 33.8762 10.5985 33.8762 9.24423C33.8762 7.91138 34.5689 6.94455 35.5023 6.35949C36.1547 5.95063 36.9309 5.71823 37.7182 5.62995V4.91335C37.7182 4.40892 38.1138 4 38.6019 4ZM36.4187 7.92145C36.7667 7.70336 37.2162 7.54847 37.7182 7.47118V11.2253C37.2281 11.0285 36.8 10.825 36.4499 10.5843C35.8909 10.2 35.6436 9.80262 35.6436 9.24423C35.6436 8.66437 35.9114 8.23937 36.4187 7.92145ZM39.4855 17.4289V13.821C39.9757 14.0178 40.4038 14.2213 40.7538 14.462C41.3129 14.8463 41.5602 15.2437 41.5602 15.8021C41.5602 16.2613 41.3283 16.6522 40.7971 16.9733C40.4438 17.1869 39.9898 17.3439 39.4855 17.4289Z"
        fill="currentColor"
      />
      <path
        d="M7.85812 38.1377C8.68842 38.2512 9.64272 38.3141 10.7655 38.3489C10.7936 39.0245 10.8802 39.6847 11.0201 40.3242C7.05949 40.22 4.72713 39.8081 3.13323 38.1789C1 35.9985 1 32.466 1 25.401C1 18.336 1 14.8035 3.13323 12.6231C5.26646 10.4426 8.72242 10.4426 15.6344 10.4426C22.5463 10.4426 26.0022 10.4426 28.1355 12.6231C30.2687 14.8035 30.2687 18.336 30.2687 25.401C30.2687 25.8286 30.2687 26.2432 30.2682 26.6454H28.3009C28.3014 26.2457 28.3015 25.8312 28.3015 25.401C28.3015 21.8143 28.2975 19.317 28.0514 17.4355C27.812 15.6043 27.3753 14.6591 26.7293 13.9988C26.0892 13.3445 25.1819 12.9063 23.4106 12.6643C21.5801 12.4141 19.1469 12.4098 15.6344 12.4098C12.1218 12.4098 9.68862 12.4141 7.85812 12.6643C6.08684 12.9063 5.17952 13.3445 4.53942 13.9988C3.89337 14.6591 3.45675 15.6043 3.21726 17.4355C2.97118 19.317 2.96722 21.8143 2.96722 25.401C2.96722 28.9877 2.97118 31.485 3.21726 33.3665C3.45675 35.1977 3.89337 36.1429 4.53942 36.8032C5.17952 37.4575 6.08684 37.8957 7.85812 38.1377Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3528 25.1462C15.3528 27.263 16.3229 28.6297 17.9515 28.6297C19.5801 28.6297 20.5753 27.2476 20.5753 25.1462V24.8174C20.5753 22.6955 19.6102 21.334 17.9716 21.334C16.338 21.334 15.3528 22.7109 15.3528 24.8174V25.1462ZM16.7099 24.8225C16.7099 23.3429 17.1824 22.4643 17.9615 22.4643C18.7407 22.4643 19.2131 23.348 19.2131 24.8225V25.136C19.2131 26.6156 18.7407 27.4993 17.9615 27.4993C17.1824 27.4993 16.7099 26.6156 16.7099 25.136V24.8225Z"
        fill="currentColor"
      />
      <path
        d="M10.8189 27.9412C10.8189 28.3111 11.0903 28.5629 11.4472 28.5629C11.7287 28.5629 11.9096 28.4087 12.0554 28.121L14.4229 23.1065C14.6139 22.7212 14.6994 22.4283 14.6994 22.1612C14.6994 21.7399 14.4028 21.4676 14.0007 21.4676H10.4821C10.0599 21.4676 9.82365 21.6988 9.82365 22.0379C9.82365 22.3718 10.0599 22.5979 10.4821 22.5979H13.297V22.639L10.9395 27.5199C10.8641 27.6689 10.8189 27.8025 10.8189 27.9412Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6063 22.6236C23.6063 23.3326 23.0634 23.8875 22.3748 23.8875C21.6812 23.8875 21.1383 23.3326 21.1383 22.6236C21.1383 21.9197 21.6761 21.3648 22.3748 21.3648C23.0634 21.3648 23.6063 21.9197 23.6063 22.6236ZM21.8319 22.6236C21.8319 22.9267 22.0782 23.1836 22.3748 23.1836C22.6814 23.1836 22.9126 22.9267 22.9126 22.6236C22.9126 22.3102 22.6814 22.0687 22.3748 22.0687C22.0833 22.0687 21.8319 22.3102 21.8319 22.6236Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0242 28.8779H21.7313C16.8763 28.8779 12.9405 32.9008 12.9405 37.8634C12.9405 42.8259 16.8763 46.8488 21.7313 46.8488H40.0242C44.8792 46.8488 48.815 42.8259 48.815 37.8634C48.815 32.9008 44.8792 28.8779 40.0242 28.8779ZM27.5237 31.1322H25.2879V37.8634H27.5237V31.1322ZM36.4667 31.1322H34.2309V37.8634H36.4667V31.1322ZM28.6421 42.3937C28.6421 43.6095 29.6432 44.5952 30.878 44.5952C32.1127 44.5952 33.1138 43.6095 33.1138 42.3937V40.1076H28.6421V42.3937Z"
        fill="currentColor"
      />
    </svg>
  );
}
