/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function Discount({className}) {
  return (
    <svg
      role="img"
      className={className}
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1579 9.16667C12.0633 5.74521 7.41125 2.29167 0 2.29167V0C9.19188 0 14.701 4.83542 16.7613 9.16667H30.8756L55 33.5156L33.5156 55L9.16667 30.7519V16.4473L8.52042 16.154C6.13021 15.0631 3.41917 13.8279 0 13.8279V11.5363C3.76063 11.5363 6.74438 12.8288 9.16667 13.9288V9.16667H14.1579ZM39.2379 30.0415C38.2869 29.0904 37.2556 28.6275 36.08 28.6275C34.7554 28.6275 33.2681 29.1798 31.1048 30.47C29.5419 31.4096 28.5427 31.8083 27.7681 31.8083C26.9408 31.8083 25.2863 30.8733 25.5658 29.0171C25.685 28.2265 26.1388 27.4198 26.8767 26.6796C28.3708 25.1877 30.415 24.7363 32.0925 26.4367L33.7975 24.7317C32.7067 23.6408 31.2858 22.9167 29.7619 22.9167C28.4648 22.9167 27.1838 23.4369 25.9508 24.4681L24.5163 23.0313L22.9167 24.6331L24.4131 26.1273C23.1092 28.0202 22.4835 30.7588 24.5873 32.8602C26.9042 35.1771 29.6015 34.1 32.4088 32.3285C33.7838 31.46 34.8906 31.0383 35.789 31.0383C37.1387 31.0383 38.4931 32.1796 38.2594 33.9212C38.1402 34.8058 37.6635 35.6698 36.8363 36.4925C35.8027 37.526 34.7646 38.0485 33.7494 38.0485C32.7915 38.0485 32.0375 37.6085 31.3454 36.9898L29.6519 38.6833C30.7129 39.7329 32.1315 40.409 33.6279 40.409C34.8746 40.409 36.119 39.9483 37.3221 39.0408L38.5596 40.2738L40.1569 38.6765L38.9652 37.4825C40.6679 35.4292 41.6167 32.4202 39.2379 30.0415ZM17.6848 13.75C17.5679 14.7698 17.1921 15.6521 16.5619 16.3029C15.675 17.215 14.7354 17.6092 13.75 17.6756C13.7683 19.8115 15.5054 21.5371 17.6435 21.5371C19.7931 21.5371 21.5371 19.7931 21.5371 17.6435C21.5371 15.5077 19.816 13.7706 17.6848 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
