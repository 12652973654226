/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import styled, {css} from 'styled-components';

import WattCoin from 'components/shared/svgs/WattCoin';

export const EventWattCoin = styled(WattCoin)`
  height: 18px;
  margin: 0 0 2px 4px;
  width: 18px;
`;

export const EventAwards = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const EventAwardsLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  max-width: fit-content;
  opacity: 0.4;

  & > span {
    white-space: nowrap;
  }
`;

export const EventWatts = styled.div`
  display: inline-block;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-left: 0.5em;
  text-align: right;
`;

export const NoEventsMessage = styled.div`
  ${({theme, showSeparator}) => css`
    background: ${theme.content.background};
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    margin: 0;
    padding: 8px 0;
    text-align: center;

    & > span {
      opacity: 0.4;
    }

    ${() => {
      if (showSeparator) {
        return css`
          border-bottom: ${theme.standard.border};
          border-bottom-width: 2px;
          padding: 16px 0;
        `;
      }
    }};
  `}
`;

export const EventIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventIconTitle = styled.span`
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;
