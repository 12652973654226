/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {ActionType, useGlobalDispatch} from 'store';
import {
  DeduplicationMode,
  DoFetchAsyncFn,
  DoFetchFn,
  DoFetchOptions,
  FetchCleanupCallback,
  FetchInstance,
  FetchMethod,
  FetchRequestConfig,
  FetchResponse,
  UseFetchOptions,
  UseFetchReturn,
} from './interaction.types';
import {apiFetch} from './interaction.helpers';
import {
  useCompleteFetchInstance,
  useRemoveFetchInstances,
  useSetFetchInstance,
} from './interaction.actions';
import {useFetchState} from './useFetchState.hook';
import {useGetIsFetchLoading} from './useIsFetchLoading.hook';
import {v4} from 'uuid';

/**
 * Hook to make a fetch and optionally update the GlobalStore by dispatching the provided action.
 *
 * The returned callback executes the fetch, and returns a cleanup callback. There is also an
 * async callback if you prefer, for working with the promise.
 *
 * Loading state is handled internally, and persists to the globalStore. If a fetch is already in
 * progress for the provided fetch url, subsequent requests will be blocked until the original
 * resolves. An abort controller is built in, and the fetch will be automatically aborted when executing
 * the callback cleanup function and when the component is destroyed.
 *
 * @param method The method of fetch to execute, such as `GET` or `POST`
 * @param urlResolver A resolver to return the fetch url
 * @param options (optional) Optional set of fetch options
 * @template R (inferred) Type of response data
 * @template D (inferred) Type of config data passed in
 * @template V (optional) Type of variables
 * @template T (inferred) Type of ActionType to use to update the store
 * @returns Response/state data and callbacks to do the fetch
 */
export function useFetch<R, D, V extends {}, T extends ActionType = ActionType>(
  /** Fetch method */
  method: FetchMethod,
  /** Fetch URL */
  urlResolver: (variables: V) => string,
  /** Fetch options */
  options?: UseFetchOptions,
): UseFetchReturn<R, D, V, T> {
  /**
   ********************************
   * Global state
   ********************************
   */
  /** Global dispatch */
  const globalDispatch = useGlobalDispatch();
  /** Action to add a new fetch instance to the global state */
  const createGlobalInstance = useSetFetchInstance();
  /** Action to mark complete a fetch instance in the global state */
  const completeGlobalInstance = useCompleteFetchInstance();
  /** Action to remove an array of fetch instances from the global state */
  const removeGlobalInstances = useRemoveFetchInstances();

  /** A callback to get whether a particular fetch is in progress */
  const getIsLoading = useGetIsFetchLoading();

  /**
   ********************************
   * Refs
   ********************************
   */
  /** Ref to track whether or not the component is unmounted, so we don't execute no-op
   * promise `then` statements after the component has been destroyed */
  const isMountedRef = React.useRef<boolean>(true);
  /** The id of the current fetch (unique by fetch URL) */
  const fetchIdRef = React.useRef<string | undefined>();
  /** The fetch state for the loaded fetch id */
  const fetchState = useFetchState(fetchIdRef.current);
  /** Response state */
  const responseRef = React.useRef<FetchResponse<R, D> | undefined>(undefined);
  /** Promise of a response */
  const responsePromiseRef = React.useRef<Promise<FetchResponse<R, D>> | undefined>(undefined);
  /** Url resolver ref */
  const urlResolverRef = React.useRef(urlResolver);
  React.useEffect(() => {
    urlResolverRef.current = urlResolver;
  }, [urlResolver]);
  /** A ref to the array of fetch instances which are associated with this instance of the hook */
  const localFetchInstanceIdsRef = React.useRef<string[]>([]);
  /** A ref the the fetch state for the loaded fetch id */
  const fetchStateRef = React.useRef(fetchState);
  React.useEffect(() => {
    fetchStateRef.current = fetchState;
  }, [fetchState]);
  /** A ref to a function to get whether a particular fetch is in progress */
  const getIsLoadingRef = React.useRef(getIsLoading);
  React.useEffect(() => {
    getIsLoadingRef.current = getIsLoading;
  }, [getIsLoading]);
  /** State to trigger a state update when a fetch is started and completed */
  const [, setIsInProgress] = React.useState<boolean>(false);

  /**
   ********************************
   * Initialize
   ********************************
   */
  /** Create a new instance when a fetch request begins */
  const setInstance = React.useCallback(
    (instance: FetchInstance) => {
      // Add this instance id to the list of ids managed by this hook instance
      localFetchInstanceIdsRef.current = [...localFetchInstanceIdsRef.current, instance.id];
      // Add this instance to the global state
      createGlobalInstance(instance);
    },
    [createGlobalInstance],
  );

  /**
   ********************************
   * Helpers
   ********************************
   */
  /** Helper to log to the browser (in Dev only, never logs on production) */
  const logDebug = React.useCallback(
    (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV === 'development' && options?.debugLogging)
        // eslint-disable-next-line no-console
        console.log(message, ...optionalParams);
    },
    [options?.debugLogging],
  );

  /**
   ********************************
   * Cleanup
   ********************************
   */
  /** Helper function to handle cleanup when fetch is complete */
  const cleanup: FetchCleanupCallback = React.useCallback(() => {
    removeGlobalInstances(localFetchInstanceIdsRef.current);
    logDebug('Cleaning up fetch instances', localFetchInstanceIdsRef.current);
  }, [logDebug, removeGlobalInstances]);

  /** Cleanup automatically on unmount */
  React.useEffect(
    () => () => {
      /**
       * NOTE: For now, don't clean up the global instances or abort in-flight fetches
       * in case other components which were deduplicated are relying on the fetch to return.
       * A future improvement could be to maintain a list of dependencies and only clean up if the
       * host component is destroyed AND there are no downstream dependencies.
       */
      // cleanup();
      isMountedRef.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   ********************************
   * Asynchronous callback to execute the fetch, and return a promise
   * @param doFetchOptions Options related to doing the fetch
   ********************************
   */
  const doFetchAsync: DoFetchAsyncFn<R, D, V, T> = React.useCallback(
    async (doFetchOptions?: DoFetchOptions<R, D, V, T>) => {
      setIsInProgress(true);
      ////
      // Get ready
      const deduplicationMode =
        doFetchOptions?.deduplicationMode || DeduplicationMode.IgnoreSubsequent;
      const url = urlResolverRef.current(doFetchOptions?.variables || ({} as V));
      logDebug('FETCH START', `(${deduplicationMode})`, url);
      const fetchId = `useFetch:${url}`;
      fetchIdRef.current = fetchId;

      ////
      // Deduplicate
      // if already loading and concurrent fetching is not allowed
      if (
        deduplicationMode !== DeduplicationMode.RunConcurrently &&
        getIsLoadingRef.current(fetchId)
      ) {
        if (deduplicationMode === DeduplicationMode.IgnoreSubsequent) {
          // If replace is not permitted, ignore this subsequent request
          logDebug('XX NO-OP - DEDUPLICATING XX (already running)', url);
          return undefined;
        } else if (
          deduplicationMode === DeduplicationMode.ReplaceExisting &&
          fetchStateRef.current.instances.length
        ) {
          // If replace is permitted, abort all previous instances of this fetch
          logDebug(
            '(aborting others)',
            url,
            fetchStateRef.current.instances.filter(i => !i.completed),
          );
          fetchStateRef.current.instances
            .filter(i => !i.completed)
            .forEach(d => d.abortController.abort());
        }
      }

      ////
      // Setup the instance
      const fetchStart = new Date();
      const instanceId = `${fetchId}::${v4()}`;
      const abortController = new AbortController();
      setInstance({
        id: instanceId,
        fetchId,
        initiated: fetchStart,
        abortController,
      });
      // Pass the signal into the config, if one isn't already there
      const config = configWithFallbackAbortSignal(doFetchOptions?.config, abortController.signal);

      ////
      // Fetch it and await
      try {
        const promise = apiFetch(method, url, doFetchOptions?.data, config).then(async r => {
          if (isMountedRef.current) {
            const action = doFetchOptions?.actionResolver && doFetchOptions.actionResolver(r);
            if (!!action) {
              globalDispatch<T>(action);
            }
            responseRef.current = r;
            logDebug('FETCH SUCCESS', url);
            return r;
          } else {
            // Component is no longer mounted, so any activity in this `then` statement would be a no-op
            const e = {message: 'destroyed'} as ErrorEvent;
            throw e;
          }
        });
        responsePromiseRef.current = promise;
        return await promise;
      } catch (e) {
        ////
        // Handle exceptions
        if ((e as ErrorEvent)?.message === 'canceled') {
          // Abort controller aborted
          logDebug('FETCH ABORTED', url);
          return undefined;
        } else if ((e as ErrorEvent)?.message === 'destroyed') {
          // Promise abandoned due to component destruction
          logDebug('COMPONENT DESTROYED', url);
          return undefined;
        } else {
          logDebug('FETCH ERROR', url, e);
          throw e;
        }
      } finally {
        completeGlobalInstance(instanceId);
        if (isMountedRef.current) setIsInProgress(false);
      }
    },

    [setInstance, setIsInProgress, method, globalDispatch, completeGlobalInstance, logDebug],
  );

  /**
   ********************************
   * Synchronous callback to execute the fetch, and return a cleanup function
   * @param doFetchOptions Options related to doing the fetch
   ********************************
   */
  const doFetch: DoFetchFn<R, D, V, T> = React.useCallback(
    (doFetchOptions?: DoFetchOptions<R, D, V, T>) => {
      /** Do the async fetch */
      doFetchAsync(doFetchOptions as DoFetchOptions<R, D, V, T>);
      /** Return a callback to cleanup (uncalled function) */
      return cleanup;
    },
    [cleanup, doFetchAsync],
  );

  /**
   ********************************
   * Return response, data, and loading state, along with callback functions
   ********************************
   */
  return {
    response: responseRef.current,
    responsePromise: responsePromiseRef.current,
    data: responseRef.current?.data,
    doFetch,
    doFetchAsync,
    cleanup,
    localInstances: fetchState.instances.filter(i =>
      localFetchInstanceIdsRef.current.includes(i.id),
    ),
    ...fetchState,
  } as const;
}

/**
 * If a supplied config doesn't already have a abort signal, use the provided
 * fallback abort signal.
 * @param config Fetch request config
 * @param fallbackAbortSignal Signal to fallback to
 * @template R (inferred) Type of response data
 * @template D (inferred) Type of config data passed in
 * @returns Fetch request config
 */
function configWithFallbackAbortSignal<R, D>(
  config?: FetchRequestConfig<R, D>,
  fallbackAbortSignal?: AbortSignal,
): FetchRequestConfig<R, D> {
  return {...config, signal: config?.signal ?? fallbackAbortSignal};
}
