/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function ChevronLeft({className}: {className: string}) {
  return (
    <svg
      role="img"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7429 15.2996C14.8869 15.4926 14.9579 15.7307 14.9409 15.9707C14.9229 16.2107 14.8199 16.4367 14.6499 16.6067C14.4799 16.7767 14.2539 16.8797 14.0139 16.8977C13.7739 16.9147 13.5359 16.8437 13.3429 16.6997L9.34286 12.6997C9.15986 12.5127 9.05685 12.2617 9.05685 11.9997C9.05685 11.7377 9.15986 11.4866 9.34286 11.2996L13.3429 7.29965C13.5359 7.15565 13.7739 7.08466 14.0139 7.10266C14.2539 7.11966 14.4799 7.22266 14.6499 7.39266C14.8199 7.56266 14.9229 7.78865 14.9409 8.02865C14.9579 8.26865 14.8869 8.50667 14.7429 8.69967L11.4528 11.9997L14.7529 15.2996H14.7429Z"
        fill="currentColor"
      />
    </svg>
  );
}
