/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export const DevicesLine: React.FC<{className?: string}> = ({className}) => {
  return (
    <svg
      {...{className}}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2732 8.60009C15.4739 5.49473 12.655 3.2002 9.3001 3.2002C5.32365 3.2002 2.1001 6.42375 2.1001 10.4002C2.1001 11.6675 2.42751 12.8583 3.00237 13.8927"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <rect
        x="5.1"
        y="10.9999"
        width="18"
        height="10"
        rx="5"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path d="M12.0999 13H10.8999V15.88H12.0999V13Z" fill="currentColor" />
      <path d="M17.3001 13H16.1001V15.88H17.3001V13Z" fill="currentColor" />
      <path
        d="M12.8999 18.0228C12.8999 18.6731 13.4372 19.2002 14.0999 19.2002C14.7626 19.2002 15.2999 18.6731 15.2999 18.0228L15.2999 16.8002L12.8999 16.8002L12.8999 18.0228Z"
        fill="currentColor"
      />
    </svg>
  );
};
