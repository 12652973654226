/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable max-len */
import React from 'react';

export function PlusCircle({className}: {className?: string}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C7.348 20 4.80402 18.946 2.92902 17.071C1.05402 15.196 0 12.652 0 10C0 7.348 1.05402 4.80402 2.92902 2.92902C4.80402 1.05402 7.348 0 10 0C12.652 0 15.196 1.05402 17.071 2.92902C18.946 4.80402 20 7.348 20 10C20 12.652 18.946 15.196 17.071 17.071C15.196 18.946 12.652 20 10 20ZM10 18C12.122 18 14.157 17.157 15.657 15.657C17.157 14.157 18 12.122 18 10C18 7.878 17.157 5.84302 15.657 4.34302C14.157 2.84302 12.122 2 10 2C7.878 2 5.84302 2.84302 4.34302 4.34302C2.84302 5.84302 2 7.878 2 10C2 12.122 2.84302 14.157 4.34302 15.657C5.84302 17.157 7.878 18 10 18ZM11 9H13C13.265 9 13.52 9.10503 13.707 9.29303C13.895 9.48003 14 9.735 14 10C14 10.265 13.895 10.52 13.707 10.707C13.52 10.895 13.265 11 13 11H11V13C11 13.265 10.895 13.52 10.707 13.707C10.52 13.895 10.265 14 10 14C9.735 14 9.48003 13.895 9.29303 13.707C9.10503 13.52 9 13.265 9 13V11H7C6.735 11 6.48003 10.895 6.29303 10.707C6.10503 10.52 6 10.265 6 10C6 9.735 6.10503 9.48003 6.29303 9.29303C6.48003 9.10503 6.735 9 7 9H9V7C9 6.735 9.10503 6.48003 9.29303 6.29303C9.48003 6.10503 9.735 6 10 6C10.265 6 10.52 6.10503 10.707 6.29303C10.895 6.48003 11 6.735 11 7V9Z"
        fill="currentColor"
      />
    </svg>
  );
}
