/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';

export default function MoneyLine({className}) {
  return (
    <svg
      {...{className}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.167 5.33325H9.91699C9.14299 5.33325 8.401 5.68435 7.854 6.30955C7.307 6.93465 7 7.78245 7 8.66655C7 9.55055 7.307 10.3985 7.854 11.0236C8.401 11.6487 9.14299 11.9999 9.91699 11.9999H14.083C14.857 11.9999 15.599 12.3511 16.146 12.9762C16.693 13.6014 17 14.4492 17 15.3333C17 16.2173 16.693 17.0652 16.146 17.6903C15.599 18.3154 14.857 18.6666 14.083 18.6666H7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
